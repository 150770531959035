import React, {useEffect, useRef, useState} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton, AppBar, Toolbar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ContentPaste, EditNote } from '@mui/icons-material';
import EachResultEditable from './EachResultEditable';

function ResultEditable({ editResult, defineSplitlens, DefineDigit, CheckWithDigit,
    setEditResult, editOpen, handleEditClose, numarray, limitAmount,
    LimitCheckOver, digitMadeArr, hotNum, userType, realTimeUser,
    Transition, digitStatus, setResult}) {
    const textFieldRef = useRef(null);
    useEffect(() => {
    if (textFieldRef.current) {
        textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
    }
    }, [editResult]); 
    const handlePaste = async() => {
        try {
          const pastedText = await navigator.clipboard.readText();
          var removeBurmese = pastedText?.replace(/[^0-9A-Za-z,.=\n\s]/g, '')
          var textArr = removeBurmese?.split('\n')
          var newArrText = []
          textArr?.forEach((text) => {
            var spaceSplit = (text?.includes(' ') && !text?.includes('=')) ? text?.split(' ') : text?.includes('=') ? text?.split('=') : [text?.replace(/[^0-9A-Za-z,]/g, '')]
            var newDigit = spaceSplit?.length > 1 && spaceSplit?.[0]?.length > 2 ?
            'E'+spaceSplit?.[0]?.replace(/[^0-9]/g, '')?.match(/\d{2}/g)?.join(',')+'E'+spaceSplit?.slice(1)?.join('') : spaceSplit?.join('')
            newArrText?.push(newDigit)
          })
          setEditResult(newArrText?.join('\n'))
          // Now you can use the pasted text in your React app
        } catch (error) {
          //console.error('Error reading clipboard:', error);
        }
    }
    function removeByIndex(arr, index) {
        return arr.slice(0, index).concat(arr.slice(index + 1));
    }
    const [eachEditOpen, setEachEditOpen] = useState(false)
    const handleeachEditClose = () => {
        setEachEditOpen(false)
    }
    const handleeachEditOpen = () => {
        setEachEditOpen(true)
    }
    const [eachEditResult, setEachEditResult] = useState('')
    const [index, setIndex] = useState(0)
     return (
        <>
        <EachResultEditable
        eachEditOpen={eachEditOpen}
        handleeachEditClose={handleeachEditClose}
        Transition={Transition}
        digitStatus={digitStatus}
        editResult = {editResult}
        setEditResult = {setEditResult}
        eachEditResult = {eachEditResult}
        setEachEditResult = {setEachEditResult}
        index={index}
        />
        <Dialog
        fullScreen
        open={editOpen}
        onClose={handleEditClose}
        TransitionComponent={Transition}
      >
        <AppBar
        sx={{ position: 'relative', backgroundColor:'#6798c6', boxShadow:'0 0 0 0px' }}
        >
          <Toolbar>
            <IconButton
            edge="start"
            color="inherit"
            onClick={handleEditClose}
            aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, fontFamily:'mmfont' }} variant="h6" component="div">
              ရိုက်ကွက်များရိုက်ရန်
            </Typography>
            <Button style={{fontFamily:'mmfont'}} autoFocus color="inherit" 
            onClick={() => {
                setResult(editResult?.toUpperCase()?.split('\n')?.filter((element) => element?.length > 0).join('\n'))
                handleEditClose()
            }
            }>
              သိမ်းမည်
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent
        sx={{
          padding:'5px',
          overflowX:'hidden'
        }}
        >
        <div
        ref={textFieldRef}
        style={{width:'100%', height:'200px', border:'1px solid #6798c6', padding:10, overflow:'auto'}}
        >
            {editResult === ''?
            <div style={{display:'flex', alignItems:'center'}}>
                <Button
                startIcon={<ContentPaste
                style={{fontSize:14}}
                />}
                variant='contained'
                disableElevation
                size='small'
                style={{fontFamily:'mmfont', fontSize:15, backgroundColor:'#6798c6'}}
                onClick={handlePaste}
                >
                    ကူးယူမှုကိုနေရာချပါ
                </Button>
                <p style={{fontFamily:'mmfont', fontSize:18, marginLeft:5, opacity:0.7}}> / ရိုက်ကွက်များရိုက်ထည့်ပါ</p>
            </div>:
            <table>
                {
                    editResult?.toUpperCase()?.split('\n')?.map((ress, index) => {
                    var substringBetweenEs = ress?.split('E')?.[1]
                    var chunks = substringBetweenEs?.match(/\d{2}/g);
                    var formattedSubstring = chunks?.join(',');
                    var wantstring = ress.replace(substringBetweenEs, formattedSubstring);
                    var res = ress?.split('E')?.length === 3 && formattedSubstring !== undefined? wantstring:ress
                    var splitLen = defineSplitlens(res);
                    var newDigit = res?.split('R')
                    var firstSplit = newDigit?.[0]
                    var digitArrForResult = DefineDigit(res?.slice(0,splitLen), res, numarray, res?.slice(defineSplitlens(res)), res?.includes('R') && res?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):res?.slice(splitLen), res?.includes('R')? newDigit?.[1]:'0');
                    var checkLimitCorrect = digitArrForResult?.filter((digit) => {
                        return (parseInt(digit?.amount) || 0) < 1 || digit?.digit?.replace(/[^0-9]/g, '')?.length !== 2 || /[a-zA-Z]/.test(digit?.amount)
                    })
                    var checkLimitOver = digitArrForResult?.filter((digit) => {
                        return (
                        (parseInt(CheckWithDigit(digit?.digit)?.[0]?.amount === undefined ? '0':CheckWithDigit(digit?.digit)?.[0]?.amount)) + parseInt(digit?.amount===undefined?'0':digit?.amount)) > parseInt(limitAmount)
                    })
                    var limitCheckOver = LimitCheckOver(digitArrForResult, digitMadeArr, hotNum, userType)
                    var checkDigitAmountOver = digitArrForResult?.some((element) => {
                        return realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)
                    }
                    )
                    var Rlength = (res?.match(/R/g) || []).length
                    return (<tr>
                        <td style={{border:'1px solid rgba(0,0,0,0.08)'}}>
                            <p
                            style={{fontFamily:'textfont', padding:'3px 3px', fontSize:20, 
                                color: checkDigitAmountOver || limitCheckOver || 
                                checkLimitCorrect?.length > 0 || Rlength > 1 ||
                                checkLimitOver?.length > 0? 'red':'green'}}
                            >{res}</p></td>
                        <td
                        style={{border:'1px solid rgba(0,0,0,0.08)', lineHeight:0, minWidth:'40px', width:'40px', textAlign:'center'}}
                        >
                            <EditNote
                            onClick={
                                () => {
                                    setIndex(index)
                                    setEachEditResult(res)
                                    handleeachEditOpen()
                                }
                            }
                            />
                        </td>
                        <td
                        style={{border:'1px solid rgba(0,0,0,0.08)', lineHeight:0, minWidth:'40px', width:'40px', textAlign:'center'}}
                        >
                            <CloseIcon
                            onClick={
                                () => {
                                    var arrayEdit = editResult?.split('\n')
                                    var removeArr = removeByIndex(arrayEdit, index)
                                    setEditResult(removeArr?.join('\n'))
                                }
                            }
                            color='red'
                            style={{fontSize:20}}
                            sx={{
                                color:'red',
                            }}
                            />
                        </td>
                        {checkDigitAmountOver || limitCheckOver || Rlength > 1 ||
                        checkLimitCorrect?.length > 0 || checkLimitOver?.length > 0?
                        <td style={{border:'1px solid rgba(0,0,0,0.08)', lineHeight:0, width:'90px', textAlign:'center'}}>
                            <p style={{padding:'0px 3px', color:'red', width:'90px', cursor:'pointer'}}
                            >
                                {(checkLimitOver?.length > 0 || limitCheckOver)? 'Over': checkDigitAmountOver? 'Closed':'Incorrect!'}
                            </p>
                        </td>:null
                        }
                        {index+1 === editResult?.split('\n').length?
                        <td style={{border:'1px solid rgba(0,0,0,0.08)', lineHeight:0, minWidth:'70px', width:'70px', textAlign:'center'}}>
                            <p style={{padding:'0px 3px', color:'red', cursor:'pointer', textAlign:'center'}}
                            onClick={
                                () => setEditResult('')
                            }
                            >
                                Clear all
                            </p>
                        </td>:null
                        }
                    </tr>)
                    }
                    )
                }
            </table>
            }
        </div>
          
          <div style={{marginTop:5, marginLeft:-10, marginRight:-10}}>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={() => {
                setEditResult(editResult.slice(0,-1))
            }}
            value="C"
            style={{fontFamily: 'mmfont'}}
            >
            ဖျက်မည်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "P"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            အပါ
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "R"
            disabled={digitStatus==="Closed"}
            id="digitBox"
            >
            R
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "A"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            အပူး
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "7"
            disabled={digitStatus==="Closed"}
            >
            7
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "8"
            disabled={digitStatus==="Closed"}
            >
            8
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "9"
            disabled={digitStatus==="Closed"}
            >
            9
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "S"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            စုံ
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "4"
            disabled={digitStatus==="Closed"}
            >
            4
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "5"
            disabled={digitStatus==="Closed"}
            >
            5
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "6"
            disabled={digitStatus==="Closed"}
            >
            6
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "M"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            မ
            </button>
          </div>
          <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "1"
            disabled={digitStatus==="Closed"}
            >
            1
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'202%', marginTop:3}}
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "0"
            disabled={digitStatus==="Closed"}
            >
            0
            </button>
            </div>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "2"
            disabled={digitStatus==="Closed"}
            >
            2
            </button>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "3"
            disabled={digitStatus==="Closed"}
            >
            3
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%', marginTop:3}}
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "00"
            disabled={digitStatus==="Closed"}
            >
            00
            </button>
            </div>
            <button
            //variant="contained" disableElevation
            className='buttonBox fourthBox'
            //disableRipple
            style={{backgroundColor:'#6798c6 !important', fontFamily:'digit'}}
            onClick={() => setEditResult((prevVal) => prevVal + '\n')}
            //value = "Save"
            //disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
            >
            Ent
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "F"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ထိပ်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "L"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            နောက်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "E"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ငွေတူ
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "B"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ဘရိတ်
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "W"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ပါဝါ
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "N"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            နခတ်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "X"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ညီကို
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "Z"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ခွေ
            </button>
          </div>
          </div>
        </DialogContent>
      </Dialog>
      </>
    )
}

export default ResultEditable
import React, { useEffect, useState } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function ThreeHotInput() {
  const navigate = useNavigate();
  const {authTokens, userType} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [result, setResult] = useState('')
  const [takeLoad, setTakeLoad] = useState(0)
  const [errorMes, setErrorMes] = useState('')
  const [success, setSuccess] = useState(false)

  var inputDate = moment().tz('Asia/Yangon').format();

  const [users, setUsers] = useState([])
  const [username, setUsername] = useState('')
  const [dailyLimit, setDailyLimit] = useState([])
  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveDigitLimitAll&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                        setDailyLimit(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [takeLoad, userType?.id])

  const onClick = (button) => {
    if(button === "Save") {
      SaveDigit();
    }
    else if(button === "C") {
      setErrorMes('')
      reset();
    }
    else {
      if(button === 'A' ||
      button === 'H'
      ) {
        setResult(button)
      } else if (button === "F" ||
        button === "L" ||
        button === "M"
      ) {
        if(result?.length > 0) {
          if(result?.[0] === '0' ||
          result?.[0] === '1' ||
          result?.[0] === '2' ||
          result?.[0] === '3' ||
          result?.[0] === '4' ||
          result?.[0] === '5' ||
          result?.[0] === '6' ||
          result?.[0] === '7' ||
          result?.[0] === '8' ||
          result?.[0] === '9'
          ) {
            setResult(result?.[0]+button)
          } else {
            reset()
          }
        } else {
          reset()
        }
      } else if(button === 'R') {
        setResult(result+button)
      }
      else {
        setResult(result+button)
      }
    }
  }

  const reset = () => {
    setResult('')
  }
  
  const SaveDigit = async() => {
		let messageUrl = mainUrl+'api.php?op=threesaveDigitLimit';
    let updateUrl = mainUrl+'api.php?op=threesaveDigitLimitUpdate';
    var amount = result?.slice(3);
    if(amount > 1){ 
        setErrorMes('')
        if(username){
          setErrorMes('')
          var checkDailyLimit = dailyLimit?.length>0 || dailyLimit!==null? 
          dailyLimit?.filter((limit) => 
            limit?.digit === result?.slice(0,3) &&
            limit?.userid === username
          ) : []
          if(checkDailyLimit?.length > 0){
            await fetch(updateUrl, {
              method:'post',
              headers:{
                'Content-Type':'application/x-www-form-urlencoded'
              },
              body:"amount="+amount+"&id="+checkDailyLimit?.[0]?.id
            }).then(async() => {
              setErrorMes(result+' saved!')
              setSuccess(true)
              setResult('')
              setTakeLoad(takeLoad+1)
            })
          } else {
            await fetch(messageUrl, {
              method:'post',
              headers:{
                'Content-Type':'application/x-www-form-urlencoded'
              },
              body:"digit="+result?.slice(0,3)+"&userid="+username+"&date="+inputDate+'&amount='+amount+'&adminid='+userType?.id
            }).then(async() => {
              setErrorMes(result+' saved!')
              setSuccess(true)
              setResult('')
              setTakeLoad(takeLoad+1)
            })
          }
        } else {
          setErrorMes('Please select agent!')
          setSuccess(false)
        }
      } else {
        setErrorMes('ပမာဏ မမှန်ကန်ပါ!')
        setSuccess(false)
    }
	}

  //var digitStatus = DigitStatus()
  var digitStatus = 'Open'

  return (
    <div className='adminScreen'>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/threehotnumbers')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Back
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='adminHomeScreen'>
          
          <div className='digitscreen' style={{paddingBottom:10}}>
            <p className='text'>ဒိုင် Hot ဂဏန်းသတ်မှတ်ရန်</p>
          </div>
          <div className='digitscreen' style={{paddingBottom:10}}>
            <div className='topbar'>
              <p className='text'>Agents</p>
            </div>
            <div style={{marginLeft: 20}}>
              <Select
                size='small'
                sx={{
                  height:30,
                  width:150,
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                    border:'2px solid white'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                }}
                labelId="select-user"
                id="select-user"
                value={username}
                onChange={handleChange}
                >
                  {
                    users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                    <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                    )
                  }
              </Select>
            </div>
          </div>
          <div style={{padding:'3px 10px 0px 10px', display:'flex'}}>
            <p>Result: </p> <p style={{color: success?'#1bbc12':'#d8005d', lineHeight:'20px'}}>&nbsp; {errorMes}</p>
          </div>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: "95%"},
            }}
            className='White'
            style={{textAlign:'center'}}
            noValidate
            autoComplete="off"
          >
          <div>
            <TextField
              size="small"
              disabled
              value={result}
              id="outlined-disabled"
              placeholder='Press any digit'
              sx={{
                  "& .MuiInputBase-root.Mui-disabled": {
                      "& > fieldset": {
                          borderColor: "rgba(0,0,0,0.1)",
                          WebkitTextFillColor: "#000000",
                      },
                      WebkitTextFillColor: "#000000",
                      fontSize:27,
                  }
              }}
              InputProps={{
                sx: {
                  "&.MuiInputBase-root.Mui-disabled": { color: "unset" },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "unset",
                  },
                },
                autoComplete: 'off'
              }}
            />
          </div>
          </Box>
          <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value="C"
            style={{fontFamily:'mmfont'}}
            >
            ဖျက်မည်
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "A"
            id="digitBox"
            disabled
            >
            Tri
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "R"
            id="digitBox"
            disabled
            >
              R
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "F"
            disabled
            style={{fontFamily:'mmfont'}}
            >
            ထိပ်
            </Button>
          </div>
          <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "7"
            disabled={digitStatus==="Closed"}
            >
            7
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "8"
            disabled={digitStatus==="Closed"}
            >
            8
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "9"
            disabled={digitStatus==="Closed"}
            >
            9
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "M"
            disabled
            style={{fontFamily:'mmfont'}}
            >
            လယ်
            </Button>
          </div>
          <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "4"
            disabled={digitStatus==="Closed"}
            >
            4
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "5"
            disabled={digitStatus==="Closed"}
            >
            5
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "6"
            disabled={digitStatus==="Closed"}
            >
            6
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "L"
            disabled
            style={{fontFamily:'mmfont'}}
            >
            နောက်
            </Button>
          </div>
          <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "1"
            disabled={digitStatus==="Closed"}
            >
            1
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'202.5%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "0"
            disabled={digitStatus==="Closed"}
            >
            0
            </Button>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            style={{width:'100%'}}
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "2"
            disabled={digitStatus==="Closed"}
            >
            2
            </Button>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "3"
            disabled={digitStatus==="Closed"}
            >
            3
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            style={{width:'100%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "00"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              00
            </Button>
            </div>
            <Button
            variant="contained" disableElevation
            className='buttonBox fourthBox'
            disableRipple
            style={{backgroundColor:'#6798c6 !important', fontFamily:'mmfont'}}
            onClick={(e) => onClick(e.target.value)}
            value = "Save"
            disabled={digitStatus==="Closed" || result?.length < 4}
            >
            သိမ်းမည်
            </Button>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default ThreeHotInput

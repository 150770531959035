import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './SelectDateScreen.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { mainUrl } from './url';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ThreeSelectDateScreen() {
    const {setThreeDDate, userType} = useAuth()
    var inputDate = moment().tz('Asia/Yangon').format();
    const navigate = useNavigate();
    const [openDate, setOpenDate] = useState([])
    const [openAll, setOpenAll] = useState([])
    const month = parseInt(moment().format('D')) < 18 && parseInt(moment().format('D')) > 3? dayjs(moment().format('YYYY-MM')) : 
    parseInt(moment().format('D')) >= 18 && parseInt(moment().format('D')) <= 31? dayjs(moment().format('YYYY-MM')):
    parseInt(moment().format('D')) <=3?
    dayjs(moment().subtract(1,'months').format('YYYY-MM')) : dayjs(moment().format('YYYY-MM'));
    const dayPart = parseInt(moment().format('D')) < 18 && parseInt(moment().format('D')) > 3? 'first' : 
    parseInt(moment().format('D')) >= 18 && parseInt(moment().format('D')) <= 31? 'second':
    parseInt(moment().format('D')) <=3?
    'second' : 'first';
    const [start, setStart] = useState(dayjs(dayPart==='first'?month?.format('YYYY-MM')+'-04':month?.format('YYYY-MM')+'-18'))
    const [close, setClose] = useState(dayjs(dayPart==='first'?month?.format('YYYY-MM')+'-16':moment().add(1,'months').format('YYYY-MM')+'-01'))
    const [inputMonth, setInputMonth] = useState('')
    const [inputPart, setInputPart] = useState('')
    const [defStart, setDefStart] = useState(dayjs(dayPart==='first'?month?.format('YYYY-MM')+'-04':month?.format('YYYY-MM')+'-18'))
    const [defClose, setDefClose] = useState(dayjs(dayPart==='first'?month?.format('YYYY-MM')+'-16':moment().add(1,'months').format('YYYY-MM')+'-01'))
    const [loadData, setLoadData] = useState(0)
    const [updstart, setUpdStart] = useState(dayjs())
    const [updclose, setUpdClose] = useState(close)
    const SetDate = () => {
        setThreeDDate({dateFrom:start?.format('YYYY-MM-DD'), dateTo: close?.format('YYYY-MM-DD'), part: inputPart===undefined?'first':inputPart, month: inputMonth?.format('YYYY-MM')})
        navigate('/home')
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [oldopen, setOldOpen] = useState(false);
    const handleClickOldOpen = () => {
        setOldOpen(true);
    };
    const handleOldClose = () => {
        setOldOpen(false);
    };

    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getThreeOpen&adminid='+userType?.id+'&part='+dayPart+'&month='+month?.format('YYYY-MM')
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getThreeOpenAll&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setOpenDate(response?.[0]?.['data']['data']['result'])
                        var openDate = response?.[0]?.['data']['data']['result']
                        var openAll = response?.[1]?.['data']['data']['result']
                        setStart(openDate?.[0]?.date_from === undefined?dayjs(openAll?.[0].date_from === undefined?
                            dayPart==='first'?month?.format('YYYY-MM')+'-04':month?.format('YYYY-MM')+'-18' : openAll?.[0].date_from
                        )
                        : dayjs(openDate?.[0].date_from))
                        setClose(openDate?.[0]?.date_to === undefined?dayjs(openAll?.[0].date_to === undefined?
                            dayPart==='first'?month?.format('YYYY-MM')+'-16':moment().add(1,'months').format('YYYY-MM')+'-01' : openAll?.[0].date_to
                        ): dayjs(openDate?.[0].date_to))
                        setInputMonth(openDate?.[0]?.month === undefined?dayjs(openAll?.[0].month):dayjs(openDate?.[0]?.month))
                        setInputPart(openDate?.[0]?.part === undefined?openAll?.[0]?.part:openDate?.[0]?.part)
                        setOpenAll(response?.[1]?.['data']['data']['result'])
                        //setUpdStart(dayjs(openAll?.[0]?.date_from))
                        //setUpdClose(dayjs(openAll?.[0]?.date_to))
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [loadData, userType?.id, dayPart, month])

    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getThreeOpenAll&adminid='+userType?.id
                      })
                    ]).then(response => {
                        var openAll = response?.[0]?.['data']['data']['result']
                        setUpdStart(dayjs(openAll?.[0]?.date_from))
                        setUpdClose(dayjs(openAll?.[0]?.date_to))
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [loadData, userType?.id])
    //console.log(dayPart==='first'?month+'-04':month+'-18')
    const SaveOpenDate = async() => {
        let uploadUrl = mainUrl+'api.php?op=saveThreeOpen';
        let updUrl = mainUrl+'api.php?op=threeeditDate';
        if(openDate?.length > 0){
            if(openDate?.[0]?.date_from !== defStart?.format('YYYY-MM-DD') || 
            openDate?.[0]?.date_to !== defClose?.format('YYYY-MM-DD')
            ){
                await fetch(updUrl, {
                    method:'post',
                    headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body:"id="+openDate?.[0]?.id+"&dateFrom="+defStart?.format('YYYY-MM-DD')+"&dateTo="+defClose?.format('YYYY-MM-DD')
                }).then(() => {
                    setLoadData(loadData+1)
                    handleClose()
                })
            }else {
                //setLoadData(loadData+1)
                handleClose()
            }
        } else {
            await fetch(uploadUrl, {
                method:'post',
                headers:{
                'Content-Type':'application/x-www-form-urlencoded'
                },
                body:"part="+dayPart+"&month="+month?.format('YYYY-MM')+"&date="+inputDate+"&dateFrom="+defStart?.format('YYYY-MM-DD')+'&dateTo='+defClose?.format('YYYY-MM-DD')+'&adminid='+userType?.id
            }).then(() => {
                setLoadData(loadData+1)
                handleClose()
            })
        }
    }

    const UpdateOld = async() => {
        let updUrl = mainUrl+'api.php?op=threeeditDate';  
        if(openAll?.[0]?.date_from !== updstart?.format('YYYY-MM-DD') || 
        openAll?.[0]?.date_to !== updclose?.format('YYYY-MM-DD')
        ){
            await fetch(updUrl, {
                method:'post',
                headers:{
                'Content-Type':'application/x-www-form-urlencoded'
                },
                body:"id="+openAll?.[0]?.id+"&dateFrom="+updstart?.format('YYYY-MM-DD')+"&dateTo="+updclose?.format('YYYY-MM-DD')
            }).then(() => {
                setLoadData(loadData+1)
                handleOldClose()
            })
        }else {
            //setLoadData(loadData+1)
            handleOldClose()
        }
    }
    const ThreeDeleteOpen = async(id) => {
        if(id !== ''){
            var deleteUrl = mainUrl+'api.php?op=threeopendelete&id='+id;
            await axios({
            method: 'get',
            url: deleteUrl
            }).then(() => {
                setLoadData(loadData+1)
                handleOldClose()
            })
        }
    }
    
    return (
        <div style={{display:'flex', 
        justifyContent:'center', 
        height:'100vh',
        alignItems:'start'}}>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{fontSize:14, fontFamily:'mmfont'}}>
            စာရင်းဖွင့်မည့်ရက်ကို သတ်မှတ်ပါ
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                <LocalizationProvider
                dateAdapter={AdapterDayjs}>
                <DemoContainer
                components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    sx={{width:'100%'}}
                    format='YYYY-MM-DD'
                    label="စာရင်းစဖွင့်ရက်"
                    value={defStart}
                    onChange={(newValue) => setDefStart(newValue)}
                    />
                </DemoContainer>
                </LocalizationProvider>
                <br/>
                <LocalizationProvider
                dateAdapter={AdapterDayjs}>
                <DemoContainer
                components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    sx={{width:'100%'}}
                    format='YYYY-MM-DD'
                    label="စာရင်းပိတ်မည့်ရက်"
                    value={defClose}
                    onChange={(newValue) => setDefClose(newValue)}
                    />
                </DemoContainer>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
            <Button style={{fontFamily:'mmfont'}} autoFocus onClick={SaveOpenDate}>
                သိမ်းမည်
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            onClose={handleOldClose}
            aria-labelledby="customized-dialog-title"
            open={oldopen}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{fontSize:14, fontFamily:'mmfont'}}>
            စာရင်းဖွင့်မည့်ရက်ကို ပြင်ဆင်ပါ
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleOldClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                <LocalizationProvider
                dateAdapter={AdapterDayjs}>
                <DemoContainer
                components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    sx={{width:'100%'}}
                    format='YYYY-MM-DD'
                    label="စာရင်းစဖွင့်ရက်"
                    value={updstart}
                    onChange={(newValue) => setUpdStart(newValue)}
                    />
                </DemoContainer>
                </LocalizationProvider>
                <br/>
                <LocalizationProvider
                dateAdapter={AdapterDayjs}>
                <DemoContainer
                components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    sx={{width:'100%'}}
                    format='YYYY-MM-DD'
                    label="စာရင်းပိတ်မည့်ရက်"
                    value={updclose}
                    onChange={(newValue) => setUpdClose(newValue)}
                    />
                </DemoContainer>
                </LocalizationProvider>
                <div
                style={{width:'100%', display:'flex', justifyContent:'center'}}
                >
                    <Button
                    variant="outlined" disableElevation
                    style={{marginTop:15, fontFamily:'mmfont', fontWeight:'bold', marginBottom:10, color:'red', border:'1px solid red', width:'100%'}}
                    disabled={openAll?.length < 1}
                    onClick={() => ThreeDeleteOpen(openAll?.[0]?.id)}
                    >
                        စာရင်းပြန်ဖျက်မည်
                    </Button>
                </div>
            </DialogContent>
            <DialogActions>
            <Button style={{fontFamily:'mmfont'}} autoFocus onClick={UpdateOld}>
                သိမ်းမည်
            </Button>
            </DialogActions>
        </Dialog>
        <div className='calendar'>
            <h3 className='calendarTitle'>စာရင်းသွင်းလိုသည့် ရက်ကို ရွေးပါ</h3>
            <h3 style={{marginTop:10, padding:15, paddingBottom:5, fontFamily:'mmfont'}}>စာရင်းစဖွင့်ထားသည့်လ</h3>
            <div className='datebox' style={{display:'flex'}}>
            <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DemoContainer
            components={['DatePicker', 'DatePicker']}>
                <DatePicker
                sx={{width:'100%',
                marginTop:'-7.5px !important',
                '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                border:'1px solid rgba(0,0,0,0.2)',
                padding:0,
                },
                '.MuiOutlinedInput-input': {
                padding:'10px 10px',
                }
                }}
                size="small"
                readOnly
                format='YYYY-MM'
                //label="စာရင်းစဖွင့်လ"
                value={inputMonth}
                //onChange={(newValue) => setMonth(newValue)}
                />
            </DemoContainer>
            </LocalizationProvider>
            <Select
            
            size='small'
            sx={{
                height:43.5,
                width:150,
                color: "black",
                '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                border:'1px solid rgba(0,0,0,0.2)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '.MuiSvgIcon-root ': {
                fill: "rgba(0,0,0,0.2) !important",
                }
            }}
            labelId="select-user"
            id="select-user"
            style={{marginLeft:3}}
            readOnly
            value={inputPart===undefined?'first':inputPart}
            //label="Day Part"
            //onChange={handleChange}
            >
            <MenuItem value='first'>ပထမအကြိမ်</MenuItem>
            <MenuItem value='second'>ဒုတိယအကြိမ်</MenuItem>
            </Select>
            </div>
            <h4 style={{padding:15, paddingTop:5, paddingBottom:5}}>{openAll?.length < 1 || openAll===null? 'စာရင်းအသစ်မဖွင့်ရသေးပါ':`${start.format('YYYY-MM-DD')} မှ ${close.format('YYYY-MM-DD')}`}</h4>
            
            <div className='datebox'>
            <Box sx={{ minWidth: 200 }} style={{marginTop:0}}>
            <FormControl fullWidth>
                <Button variant="contained" disableElevation
                style={{backgroundColor:'#6798c6', marginTop:10}}
                onClick={() => SetDate()}
                disabled={openAll?.length < 1 || openAll===null}
                >
                    Next
                </Button>
            </FormControl>
            </Box>
            </div>
            <Box sx={{ minWidth: 200 }} style={{marginTop:5, paddingLeft:15, paddingRight:15, marginBottom:20}}>
            <FormControl fullWidth>
            <Button variant="outlined" disableElevation
            style={{fontWeight:'bold', fontFamily:'mmfont', marginBottom:10, color:'#6798c6', border:'2px solid #6798c6'}}
            onClick={handleClickOldOpen}
            disabled={openAll?.length < 1}
            >
                ဖွင့်ပြီးသားစာရင်းပြင်ရန်
            </Button>
            </FormControl><br/>
            <FormControl fullWidth>
            <Button variant="outlined" disableElevation
            style={{fontWeight:'bold', fontFamily:'mmfont', color:'#6798c6', border:'2px solid #6798c6'}}
            onClick={handleClickOpen}
            >
                စာရင်းအသစ်ဖွင့်ရန်
            </Button>
            </FormControl>
            </Box>
        </div>
        </div>
    )
}

export default ThreeSelectDateScreen
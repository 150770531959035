import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import { Box, MenuItem, Select, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AdminLimitInput from './AdminLimitInput';
import AdminDigitArr from './AdminDigitArr';
import DialogContentText from '@mui/material/DialogContentText';

function AdminHomeScreen() {
  const navigate = useNavigate();
  const {authTokens, selLotteryDate, userType, handleLogout} = useAuth()
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])
  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [historyAll, setHistoryAll] = useState([])
  const [lucky, setLucky] = useState('')
  const [getLucky, setGetLucky] = useState([])
  const [limit, setLimit] = useState('0')
  const [getLimit, setGetLimit] = useState([])
  const [allLucky, setAllLucky] = useState([])
  const [limitreload, setLimitReload] = useState(0)
  const [luckyreload, setLuckyReload] = useState(0)
  const [open, setOpen] = useState(false);
  const [removeopen, setRemoveOpen] = useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [currentDigit, setCurrentDigit] = useState('')
  const [username, setUsername] = useState('allagents')
  const [updateUser, setUpdateUser] = useState(0)
  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  const handleChangeChecked = async(data, digit, userId) => {
    let createUrl = mainUrl+'api.php?op=editUserLimit';
      var dataArr = data?.split(',')
      
      if(dataArr?.includes(digit)){
        dataArr = dataArr?.filter((item) => item !== digit)
      }else {
        dataArr?.push(digit)
      }
      const finalData = dataArr?.join()===''?'null':dataArr?.join()
      await fetch(createUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"closedDigit="+finalData+'&id='+userId
      }).then(() => {
        setUpdateUser(updateUser + 1)
      })     
  }

  const removeAgentLimit = async(username) => {
    let removeAllUrl = mainUrl+'api.php?op=removeUserLimit';
    let removeUrl = mainUrl+'api.php?op=editUserLimit';
    if(username === 'allagents'){
      await fetch(removeAllUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"closedDigit=null&adminid="+userType?.id
      }).then(() => {
        setUpdateUser(updateUser + 1)
        removehandleClickOpen()
      })
    } else {
      await fetch(removeUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"closedDigit=null&id="+username
      }).then(() => {
        setUpdateUser(updateUser + 1)
        removehandleClickOpen()
      })
    }
  }

  const handleClickOpen = (digit) => {
    setCurrentDigit(digit)
    setScroll('paper');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removehandleClickOpen = () => {
    setRemoveOpen(true);
  };
  const removehandleClose = () => {
    setRemoveOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  var inputDate = moment().tz('Asia/Yangon').format();

  const numarray = Array(100).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '0'+String(n+i)
    } else {
      return String(n + i)
    }
  })

  const [users, setUsers] = useState([])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimit&adminid='+userType?.id
                      })
                    ]).then(response => {
                        var data = response?.[0]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                        setLimit(newdata?.[0]?.limit === undefined?'0':newdata?.[0]?.limit)
                        setGetLimit(newdata)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [limitreload, userType?.id, selDate, selDayPart])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLucky&adminid='+userType?.id
                      })
                    ]).then(response => {
                      var data = response?.[0]?.['data']['data']['result']
                      var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                      setLucky(newdata?.[0]?.lucky)
                      setGetLucky(newdata)
                      setAllLucky(data)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [luckyreload, userType, selDate, selDayPart])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id, updateUser])
  
	useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                      })
                      
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                      })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [currentDate, userType?.id, selDate, selDayPart])

  // new design get data from history 

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 3
      } else if(data?.includes('A') ||
      data?.includes('N') ||
      data?.includes('W') ||
      data?.includes('X')
      ) {
        return 1
      } else if(data?.includes('Z')) {
        var splZ = data?.split('Z')
        var last = splZ?.[splZ?.length-1]?.length
        var finalLen = data?.length - last
        return finalLen
      } else if(data?.includes('S') ||
        data?.includes('M')) {
        var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
        return len
      } else {
        return 2
      }
    }
  }

  const DefineDigit = (digit, result, numarray, amount, first, second, uid) => {
      if(result.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,2)
      var reverseDigit = firstFirst?.split("").reverse().join("")
      return [{'digit':firstFirst, 'amount':first, 'userid':uid},{'digit':reverseDigit, 'amount':second, 'userid':uid}]
      } else if(digit.includes('P')){
      var newPDigit = digit?.replace('P','')
      var digitinclude = numarray.filter(num => num.includes(newPDigit))
      return digitinclude.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('A')){
      return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'S'){
      var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
      return digitSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'M'){
      var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
      return digitOdd.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'SS'){
      var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
      return digitSSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'SM'){
      var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
      return digitSMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'MM'){
      var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
      return digitMMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'MS'){
      var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
      return digitMSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('B')){
      var newBDigit = digit?.replace('B','')
      var digitBFirst = numarray.filter(num => 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
          (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
          )
      return digitBFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('W')){
      return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('N')){
      return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit?.includes('X')){
      return ['01','10','12','21','23','32','34','43','45','54',
      '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount, 'userid':uid}));
      } else if(digit?.includes('Z')){
      if(digit?.includes('ZZ')){
          var newZZDigit = digit?.replace('ZZ','')
          var newZZArr = newZZDigit?.split('')
          return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else {
          var newZDigit = digit?.replace('Z','')
          var newZArr = newZDigit?.split('')
          return combinations(newZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      }
      } else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
              if(digit === digit?.split("").reverse().join("")){
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              } else {
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
              }
          })
        } else {
          newEDigit?.forEach((digit) => {
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
          })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid}))
      }
      return [{'digit':digit, 'amount':amount, 'userid':uid}]
  }

  function removeDuplicates(arr) {
      return arr.filter((item,index) => arr.indexOf(item) === index);
  }
  const combinationsTwo = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
      }     
      } 
      return removeDuplicates(digit)
  }

  const combinations = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
      }     
      } 
      return digit
  }

  const useFilteredDetail = (detailAll,check) => {
  return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];

      return detailAll.filter((his) => {
      return his?.buy === check;
      });
  }, [detailAll, check]);
  };
  const newHistory = useFilteredDetail(historyAll, '1');
  const newBuyHistory = useFilteredDetail(historyAll, '2');

  const MakeDigitArr = (newHistory) => {
      var digits = []
      newHistory?.forEach((his) => {
          var result = his?.result
          var newDigit = result?.split('R')
          var firstSplit = newDigit?.[0]
          var splitLen = defineSplitlens(result);
          var digitFormula = result?.slice(0,splitLen)
          var oneAmount = result?.slice(splitLen)
          var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.userid);
          digits?.push(digitArr)
      })
      return digits?.flat()
  }
  const newDetail = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)

  const checkWithAgent = (uid) => {
    return users?.filter((user) => user?.id === uid)
  }

  const [luckerr, setLuckErr] = useState('')
  const SaveLucky = async() => {
    let messageUrl = mainUrl+'api.php?op=saveLucky';
    let updateUrl = mainUrl+'api.php?op=updateLucky';
    setLuckErr('')
    if(getLucky?.length > 0){
      if(lucky !== getLucky?.[0]?.lucky){
        await fetch(updateUrl, {
          method:'post',
          headers:{
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body:"lucky="+lucky+"&id="+getLucky?.[0]?.id
        }).then(() => {
          setLuckyReload(luckyreload+1)
        })
      }
    } else {
      await fetch(messageUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"lucky="+lucky+"&daypart="+selDayPart+"&date="+selDate+"&created="+inputDate+'&adminid='+userType?.id
      }).then(() => {
        setLuckyReload(luckyreload+1)
      })
    }
  }
  const SaveLimit = async() => {
    let messageUrl = mainUrl+'api.php?op=saveLimit';
    let updateUrl = mainUrl+'api.php?op=updateLimit';
    if(getLimit?.length > 0){
      if(limit !== getLimit?.[0]?.limit){
        await fetch(updateUrl, {
          method:'post',
          headers:{
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body:"limit="+limit+"&id="+getLimit?.[0]?.id
        }).then(() => {
          setLimitReload(limitreload+1)
        })
      }
    } else {
      await fetch(messageUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"limit="+limit+"&daypart="+selDayPart+"&date="+selDate+"&created="+inputDate+'&adminid='+userType?.id
      }).then(() => {
        setLimitReload(limitreload+1)
      })
    }
  }

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])
  var digitStatus = 'Open'
  var totalAmount = newDetail?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)

  const useDetailAmount = (newDetail) => {
    return useMemo(() => {
      if (!newDetail || newDetail.length === 0) return [];
  
      const acc = newDetail.reduce((acc, { amount, digit, ...r }) => {
        if (!acc[digit]) {
          acc[digit] = { digit, ...r, amount: 0 };
        }
        acc[digit].amount += (parseInt(amount, 10) || 0);
        return acc;
      }, {});
  
      return Object.values(acc);
    }, [newDetail]);
  };

  const detailAmount = useDetailAmount(newDetail);  
  
  const CheckWithAllDigit = (thisDigit) => {
    return useMemo(() => {
      return newDetail?.filter((digit) => 
        digit?.digit === thisDigit
      );
    }, [thisDigit])
  }
  const sumTotalAllAgent = (thisDigit) => {
    return newDetail?.reduce((total, { digit, amount }) => {
      return digit === thisDigit ? total + (parseInt(amount) || 0) : total;
    }, 0);
  }

  const checkwithAllAgent = (digit) => {
    const map = digit?.reduce((m, { userid, amount }) => {
      m.set(userid, (m.get(userid) || 0) + (parseInt(amount) || 0));
      return m;
    }, new Map());
  
    return Array.from(map, ([userid, amount]) => ({ userid, amount }));
  }

  const checkWithDigit = (digit) => {
    return detailAmount?.filter((detail) => detail?.digit === digit) || []
  }


  var currentMonday = moment(selDate).isoWeekday(1).format('YYYY-MM-DD')
  var currentTueday = moment(selDate).isoWeekday(2).format('YYYY-MM-DD')
  var currentWedday = moment(selDate).isoWeekday(3).format('YYYY-MM-DD')
  var currentThuday = moment(selDate).isoWeekday(4).format('YYYY-MM-DD')
  var currentFriday = moment(selDate).isoWeekday(5).format('YYYY-MM-DD')

  const filterLucky = (date, daypart) => {
    return allLucky?.filter((lucky) => lucky?.daypart === daypart && lucky?.date === date)
  }

  const buyDetailAmount = useMemo(() => {
    if (!newBuyDetail || newBuyDetail.length === 0) return [];
    
    const map = newBuyDetail.reduce((m, { digit, amount }) => {
      const parsedBuy = parseInt(amount, 10) || 0;
      if (!isNaN(parsedBuy)) {
        m.set(digit, (m.get(digit) || 0) + parsedBuy);
      }
      return m;
    }, new Map());
    return Array.from(map, ([digit, amount]) => ({ digit, amount }));
  }, [newBuyDetail]);

  const checkWithBuyDigit = (digit) => {
    return buyDetailAmount?.filter((detail) => detail?.digit === digit)
  }

  const totalBuyAmountAll = newBuyDetail?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
  
  return (
    <div className='adminScreen'>
      <Dialog
        open={removeopen}
        onClose={removehandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{fontFamily:'textfont'}}>
          {"‌Completed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontFamily:'mmfont'}}>
            ဂဏန်းအားလုံးကို ပြန်ဖွင့်ပီးပါပီ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{fontFamily:'mmfont'}} onClick={removehandleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleClose}
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{fontFamily:'mmfont'}} sx={{ m: 0, p: 2 }} id="scroll-dialog-title">
          ရောင်းပီးစာရင်း
        </DialogTitle>
        <DialogContent>
          <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          >
            <p style={{lineHeight:2, paddingBottom:10, fontFamily:'digit', textAlign:'center', fontSize:19, color:'black'}}>{currentDigit}</p>
            <div>
              <table>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120}}>Agent</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'mmfont',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120}}
                  >ပမာဏ</td>
                  <td
                  style={{padding:5, fontFamily:'mmfont',
                    border:'1px solid #6798c6',fontSize:15,
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:100}}
                  >ပိတ်မည်</td>
                </tr>
                {checkwithAllAgent(CheckWithAllDigit(currentDigit))?.map((agent) => {
                  var getAgent = checkWithAgent(agent?.userid)
                  return (
                  <tr className='number'>
                    <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:120}}>{getAgent?.[0]?.username}</td>
                    <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:120}}>{agent?.amount}</td>
                    <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:100}}>
                      <Switch size='small' checked={getAgent?.[0]?.closedDigit?.split(',')?.includes(currentDigit)} 
                      onChange={() => handleChangeChecked(getAgent?.[0]?.closedDigit, currentDigit, getAgent?.[0]?.id)} 
                      inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </td>
                  </tr>
                  )
                }) }
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', 
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120, fontFamily:'mmfont'}}>စုစုပေါင်း</td>
                  <td
                  style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:150}}
                  >{sumTotalAllAgent(currentDigit)}</td>
                </tr>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className='adminTop'>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white', 
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/adminbuy', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/profitloss', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/weekly', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/summary', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Summary
        </Button>
        <Button
        onClick={() => navigate('/account', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='adminHomeScreen'>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>အမည်</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {userType?.username} (ဒိုင်) | 2D</p>
            </div>
          </div>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>ရက်စွဲ</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {selDate} {selDayPart}</p>
            </div>
            <div style={{marginLeft: 20}}>
            <Button
            style={{padding:'0px 10px', backgroundColor:'white',
              textDecoration:'none !important', color:'black', textTransform:'lowercase'}}
            onClick={() => navigate('/')}
            >
              Select Date
            </Button>
            </div>
          </div>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>Status</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {digitStatus}</p>
            </div>
          </div>
          <AdminLimitInput/>
          <div style={{height:2, width:'95%', backgroundColor:'rgba(0,0,0,0.2)', marginLeft:'auto', marginRight:'auto', marginTop:10}}/>
          <div style={{padding:10, marginTop:10, justifyContent:'space-between', display:'flex'}}>
            <table>
              <tbody>
              <tr className='number'>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >အရောင်း စုစုပေါင်း</td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:15, fontWeight:'500',
                  margin:1, width:130, color:'black', padding:'2px 10px'}}
                >{totalAmount}</td>
              </tr>
              <tr className='number'>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >ပြန်ဝယ်ပီး စုစုပေါင်း</td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:15, fontWeight:'500',
                  margin:1, width:130, color:'black', padding:'2px 10px'}}
                >{totalBuyAmountAll}</td>
              </tr>
              <tr className='number'>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >အရောင်း - ပြန်ဝယ်</td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:15, fontWeight:'500',
                  margin:1, width:100, color:'black', padding:'2px 10px'}}
                >{totalAmount - totalBuyAmountAll}</td>
              </tr>
              <tr className='number'>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >ခေါက်ချိုးငွေ Limit</td>
                <td
                  style={{border:'1px solid #6798c6',
                  margin:1, width:100, color:'white', padding:'2px 2px'}}
                >
                  <Box
                  component="form"
                  sx={{
                      '& > :not(style)': { m: 0, width: '100%', padding:0},
                  }}
                  >
                  <TextField
                    InputProps={{
                      style: {
                        height:30,
                        padding: '0 0px !important',
                        fontSize:15,
                        fontFamily:'textfont'
                      },
                    }}
                    size='small'
                    id="limit-amount"
                    value={limit}
                    placeholder='Limit amount'
                    onChange={(e) => setLimit(e.target.value)}
                  />
                </Box>
                </td>
                <td
                  style={{borderRadius:5,
                  margin:1, color:'white'}}
                >
                  <Button
                  size='small'
                  style={{
                    textDecoration:'none',
                    textTransform:'capitalize',
                    backgroundColor:'#6798c6',
                    color:'white',
                    marginLeft:1,
                  }}
                  onClick={() => SaveLimit()}
                  >
                    Save
                  </Button>
                </td>
              </tr>
              <tr>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'textfont', fontSize:15,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >%</td>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'textfont', fontSize:15,
                  margin:1, width:100, color:'black', padding:'2px 10px'}}
                >{getLimit?.[0]?.limit === undefined || getLimit?.[0].limit === '0'?'0':
                Math.round((totalAmount - totalBuyAmountAll)/parseInt(getLimit?.[0]?.limit===undefined?'1': getLimit?.[0]?.limit === '0'? '1':getLimit?.[0]?.limit))}%</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div style={{height:2, width:'95%', backgroundColor:'rgba(0,0,0,0.2)', marginLeft:'auto', marginRight:'auto', marginTop:10}}/>
          <div className='secondBoxSecond'>
            <p style={{paddingBottom:luckerr!==''?20:0, color:'red'}}>{luckerr}</p>
            <div className='secondBoxfirst'>
              <p style={{fontFamily:'mmfont'}}>ပေါက်ကွက်</p>
              <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 0, width: 150, padding:0},
                }}
                style={{marginLeft:15}}
                >
                <TextField
                  InputProps={{
                    style: {
                      height:30,
                      padding: '0 5px',
                      fontFamily:'textfont'
                    },
                  }}
                  inputProps={{maxLength:2, inputMode: 'numeric'}}
                  size='small'
                  id="lucky" variant="outlined" 
                  value={lucky}
                  placeholder='Lucky number'
                  onChange={(e) => setLucky(e.target.value)}
                />
              </Box>
              <Button
              onClick={() => SaveLucky()}
              size='small'
              style={{
                textDecoration:'none',
                textTransform:'capitalize',
                backgroundColor:'#6798c6',
                color:'white',
                marginLeft:5,
              }}
              >
                Save
              </Button>
            </div>
            <div className='secondBoxSecondInner'>
              <h4 style={{marginTop:25, fontFamily:'mmfont', marginBottom:10}}>တစ်ပတ်စာ ပေါက်ကွက်များ</h4>
              <table
              >
                <thead>
                  <tr className='number'>
                    <td style={{padding:5, 
                      border:'1px solid #2b3944',
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      Day Part
                    </td>
                    <td style={{padding:5,
                      border:'1px solid #2b3944',
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      {currentMonday?.split('-')?.[2]}
                    </td>
                    <td style={{padding:5, 
                      border:'1px solid #2b3944',
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      {currentTueday?.split('-')?.[2]}
                    </td>
                    <td style={{padding:5, 
                      border:'1px solid #2b3944',
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      {currentWedday?.split('-')?.[2]}
                    </td>
                    <td style={{padding:5, 
                      border:'1px solid #2b3944', 
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      {currentThuday?.split('-')?.[2]}
                    </td>
                    <td style={{padding:5, 
                      border:'1px solid #2b3944',
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      {currentFriday?.split('-')?.[2]}
                    </td>
                  </tr>
                  <tr className='number'>
                    <td style={{padding:5, 
                      border:'1px solid #6798c6',
                      margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center'}}>
                      AM
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentMonday, 'AM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentTueday, 'AM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentWedday, 'AM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentThuday, 'AM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentFriday, 'AM')?.[0]?.lucky}
                    </td>
                  </tr>
                  <tr className='number'>
                    <td style={{padding:5, 
                      border:'1px solid #6798c6',
                      margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center'}}>
                      PM
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentMonday, 'PM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentTueday, 'PM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentWedday, 'PM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentThuday, 'PM')?.[0]?.lucky}
                    </td>
                    <td style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(currentFriday, 'PM')?.[0]?.lucky}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
            <div style={{height:2, width:'100%', backgroundColor:'rgba(0,0,0,0.2)', marginLeft:'auto', marginRight:'auto', marginTop:20}}/>
            <div>
              <Button
              onClick={() => navigate('/hotnumbers')} 
              size='small'
              style={{
                marginTop:20,
                fontSize:14,
                textDecoration:'none',
                textTransform:'capitalize',
                color:'#6798c6',
                border:'2px solid #6798c6',
                fontWeight:'bold',
                fontFamily:'mmfont'
              }}
              >
                Hot number ဟော့ဂဏန်း သတ်မှတ်ရန်
              </Button>
            </div>
            <div>
              <Button
              onClick={() => navigate('/adminbuy')} 
              size='small'
              style={{
                marginTop:10,
                fontSize:14,
                textDecoration:'none',
                textTransform:'capitalize',
                color:'#6798c6',
                border:'2px solid #6798c6',
                fontWeight:'bold',
                fontFamily:'mmfont'
              }}
              >
                Admin Buy ပြန်ဝယ်စာရင်း သွင်းရန် (ကာသီး)
              </Button>
            </div>
          </div>
        </div>
        <div className='secondBox'>
          <p> Server time - {currentDate}</p>
          <div style={{marginTop:20}}>
            <h4 style={{fontFamily:'mmfont'}}>Agent အလိုက် ပိတ်ဂဏန်းများ ပြန်ဖွင့်ရန်</h4>
            <div style={{display:'flex', marginTop:8}}>
            <Select
              size='small'
              sx={{
                height:30,
                width:140,
                color: "black",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                  border:'2px solid #6798c6'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6798c6',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6798c6',
                },
                '.MuiSvgIcon-root ': {
                  fill: "#6798c6 !important",
                }
              }}
              labelId="select-user"
              id="select-user"
              value={username}
              onChange={handleChange}
              > 
                <MenuItem value="allagents" selected>All agents</MenuItem>
                {
                  users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                  <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                  )
                }
            </Select>
            <Button
            size='small'
            style={{
              textDecoration:'none',
              textTransform:'capitalize',
              backgroundColor:'#6798c6',
              color:'white',
              marginLeft:5,
              fontFamily:'mmfont'
            }}
            onClick={() => removeAgentLimit(username)}
            >
              ပြန်ဖွင့်မည်
            </Button>
            </div>
          </div>
          <div className='tableWhole'>
            <AdminDigitArr
            numarray = {numarray}
            getLimit = {getLimit} 
            checkWithDigit = {checkWithDigit}
            checkWithBuyDigit = {checkWithBuyDigit}
            handleClickOpen = {handleClickOpen}
            firstDigit = {-1}
            lastDigit = {20}
            />
            <AdminDigitArr
            numarray = {numarray}
            getLimit = {getLimit} 
            checkWithDigit = {checkWithDigit}
            checkWithBuyDigit = {checkWithBuyDigit}
            handleClickOpen = {handleClickOpen}
            firstDigit = {20}
            lastDigit = {40}
            />
            <AdminDigitArr
            numarray = {numarray}
            getLimit = {getLimit} 
            checkWithDigit = {checkWithDigit}
            checkWithBuyDigit = {checkWithBuyDigit}
            handleClickOpen = {handleClickOpen}
            firstDigit = {40}
            lastDigit = {60}
            />
            <AdminDigitArr
            numarray = {numarray}
            getLimit = {getLimit} 
            checkWithDigit = {checkWithDigit}
            checkWithBuyDigit = {checkWithBuyDigit}
            handleClickOpen = {handleClickOpen}
            firstDigit = {60}
            lastDigit = {80}
            />
            <AdminDigitArr
            numarray = {numarray}
            getLimit = {getLimit} 
            checkWithDigit = {checkWithDigit}
            checkWithBuyDigit = {checkWithBuyDigit}
            handleClickOpen = {handleClickOpen}
            firstDigit = {80}
            lastDigit = {100}
            />
          </div> 
        </div>
      </div>
    </div>
  )
}

export default AdminHomeScreen

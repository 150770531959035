import React, { useEffect, useState } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Auth';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

function HotScreen() {
  const navigate = useNavigate();
  const {authTokens, userType} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [open, setOpen] = useState(false);
  const [digitLimit, setDigitLimit] = useState([])
  
  const [defineId, setDefineId] = useState('')

  const handleClickOpen = (id) => {
    setOpen(true);
    setDefineId(id)
  };
  const [delMes, setDelMes] = useState('')
  const [updateUser, setUpdateUser] = useState(0)
  const handleClose = () => {
    setOpen(false);
  };
  const DeleteUser = async(id) => {
    if(id !== ''){
      let delUserUrl = mainUrl+'api.php?op=deleteLimitDigit&id='+id
      await axios({
        method: 'get',
        url: delUserUrl
      }).then(() => {
        setDelMes('ဖျက်ပီးပါပီ')
        setUpdateUser(updateUser+1)
        setOpen(false)
      })
    }
  }

  const [users, setUsers] = useState([])
  
  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveDigitLimitAll&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setDigitLimit(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [updateUser, userType?.id])

  const checkWithAgent = (uid) => {
    return users?.filter((user) => user?.id === uid)
  }

  return (
    <div className='adminScreen'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{fontFamily:'mmfont'}}>
          {"Limit ဂဏန်းဖျက်မည်လား?"}
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button style={{fontFamily:'mmfont'}} onClick={handleClose}>မဖျက်ပါ</Button>
          <Button style={{fontFamily:'mmfont'}} onClick={() => DeleteUser(defineId)} autoFocus>
            ဖျက်မည်
          </Button>
        </DialogActions>
      </Dialog>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Back
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='thirdBox'>
          <h4 style={{fontFamily:'mmfont'}}>ဒိုင် Hot ဂဏန်းအသစ်သတ်မှတ်ရန်</h4>
          <Button
            size='small'
            style={{
            marginTop:5,
            fontSize:16,
            textDecoration:'none',
            textTransform:'capitalize',
            backgroundColor:'#6798c6',
            color:'white',
            fontFamily:'mmfont'
            }}
            onClick={() => navigate('/hotinput')} 
            >
            Hot ဂဏန်းသတ်မှတ်ရန်
          </Button>
          <div style={{height:0.5, width:'98%', backgroundColor:'rgba(0,0,0,0.09)', marginLeft:'auto', marginRight:'auto', marginTop:10, marginBottom:10}}/>
          <h4 style={{fontFamily:'mmfont'}}>Hot number ဟော့ဂဏန်းများ</h4>
          <p style={{fontSize:15, color:'green', fontFamily:'mmfont'}}>{delMes}</p>
          <table
            >
              <thead>
                <tr>
                  <td style={{padding:5, 
                    border:'1px solid #2b3944',
                    margin:1, backgroundColor:'#2b3944', color:'white', width:95, textAlign:'center'}}>
                    Agent
                  </td>
                  <td style={{padding:5, 
                    border:'1px solid #2b3944', width: 85,
                    margin:1, backgroundColor:'#2b3944', color:'white', textAlign:'center'}}>
                    Digit
                  </td>
                  <td style={{padding:5, 
                    border:'1px solid #2b3944', width: 95, textAlign:'center',
                    margin:1, backgroundColor:'#2b3944', color:'white'}}>
                    Amount
                  </td>
                  <td style={{padding:5, 
                    border:'1px solid #2b3944', width: 85, textAlign:'center',
                    margin:1, backgroundColor:'#2b3944', color:'white'}}>
                    Del
                  </td>
                </tr>
                {
                  digitLimit?.map((digit) => 
                  <tr>
                    <td style={{padding:2, fontWeight:'500', fontSize:15,
                        border:'1px solid #6798c6', fontFamily:'textfont',
                        margin:1, color:'black', textAlign:'center'}}>{checkWithAgent(digit?.userid)?.[0]?.username}</td>
                    <td style={{padding:3, fontWeight:'500', fontSize:15,
                        border:'1px solid #6798c6', fontFamily:'textfont',
                        margin:1, color:'black', textAlign:'center'}}>{digit?.digit}</td>
                    <td style={{padding:2, fontWeight:'500', fontSize:15,
                        border:'1px solid #6798c6', fontFamily:'textfont',
                        margin:1, color:'black', textAlign:'center'}}>{digit?.amount}</td>
                    <td style={{paddingTop:2, paddingBottom:0, fontWeight:'500', fontSize:15,
                        border:'1px solid #6798c6', 
                        margin:1, color:'black', textAlign:'center'}}><DeleteTwoToneIcon 
                        onClick={()=>handleClickOpen(digit?.id)}
                        /></td>
                  </tr>
                  )
                }
              </thead>
          </table>
        </div>
      </div>
    </div>
  )
}

export default HotScreen

import React, {useState, useEffect, useMemo} from 'react'
import './HistoryScreen.css';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment-timezone';
import { Button } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../Auth';
import { mainUrl } from './url';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ThreeHistoryScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const {userType, threeDate} = useAuth()
    const [history, setHistory] = useState(location?.state?.history === undefined?[]:location?.state?.history?.slice(0,15))
    const [updateHis, setUpdateHis] = useState(0)
    const [delMes, setDelMes] = useState('')
    const [open, setOpen] = React.useState(false);
    const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
    var currentDay = moment().tz('Asia/Yangon').format('YYYY-MM-DD')
    const [realTimeUser, setRealTimeUser] = useState([])
    const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Yangon').format('HH:mm:ss'))
    var closedTime = userType?.closeTime
    const [checked, setChecked] = React.useState([]);

    const handleClickOpen = (data) => {
        setOpen(true);
        //setDefineId({'id':id, 'digit':digit})
        setChecked(data)
    };
    
    const handleClose = () => {
        setOpen(false);
        setChecked([])
    };

    const handleChangeChecked = (e, result) => {
      const value = e.target.value
      if(checked?.some((item) => item?.id === value)){
        setChecked((prevItems) => prevItems.filter(item => item?.id !== value));
      } else {
        setChecked(preState => [...preState, {'id':value, 'result':result}])
      }
    };
    useEffect(() => {
        setInterval(() => setCurrentTime(moment().tz('Asia/Yangon').format('HH:mm:ss')),5000)
        setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
      },[])
    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+userType?.id+'&adminid='+userType?.adminid+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserdetail&username='+userType?.username
                      })
                    ]).then(response => {
                        //setHistory(response?.[0]?.['data']['data']['result'])
                        setRealTimeUser(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [updateHis, currentDate, userType?.username])

    useEffect(() => {
      let isSubscribed = true;
          if(isSubscribed){
              (async function(){
                  try{
                      await axios.all([
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+userType?.id+'&adminid='+userType?.adminid+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                        }),
                        /*
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threeuserdetail&username='+userType?.username
                        })
                        */
                      ]).then(response => {
                          setHistory(response?.[0]?.['data']['data']['result'])
                          //setRealTimeUser(response?.[1]?.['data']['data']['result'])
                      })
                  } catch (error) {
                      
                  }
              })()
          }
      return () => {isSubscribed = false}
    }, [updateHis, userType?.id, userType?.adminid, threeDate?.dateFrom, threeDate?.dateTo])

    const useNewHistory = (history) => {
        return useMemo(() => {
          if (!history || history.length === 0) return [];
      
          return history.filter((his) => {
            return his?.buy === '1';
          });
        }, [history]);
    };
    const newHistory = useNewHistory(history);
    
    function DigitStatus() {
      if(realTimeUser?.[0]?.status === 'Open'){
        if(currentDay >= threeDate?.dateFrom && currentDay <= threeDate?.dateTo) {
          if(currentDay === threeDate?.dateTo){
            if(currentTime <= closedTime){
              return 'Open'
            }
            else {
              return 'Closed'
            }
          }
          else {
            return 'Open'
          }
        } else {
          return 'Closed'
        }
      } else {
        return 'Closed'
      }
    }
    
    var digitStatus = DigitStatus()

    const deleteHistory = async(data) => {
        var count = 0
        if(data?.length > 0){
          data?.map(async(dd) => {
            count++
            let delUrl = mainUrl+'api.php?op=threedeleteHistory&id='+dd?.id
            await axios({
              method: 'get',
              url: delUrl
            })
          })
          if(data?.length === count) {
            setDelMes('ထိုးပီးဂဏန်း ဖျက်ပီးပါပီ')
            setUpdateHis(updateHis+1)
            setOpen(false)
            setChecked([])
          }
        }
    }
    return (
        <div
        style={{ marginTop:-10,
        justifyContent:'center', paddingLeft:5, paddingBottom:10, overflow:'scroll',
        alignItems:'start'}}
        >
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{fontFamily:'mmfont'}}>
            {"ထိုးဂဏန်း ဖျက်ရန်"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{fontFamily:'mmfont'}}>
                <p style={{color:'#00cc7d', marginBottom:10, fontFamily:'mmfont'}}>{
                checked?.map((item) => {
                  return item?.result+', '
                })
                }</p>
                {digitStatus==="Closed" ? "အချိန်ကျော်သွားပါပြီ":"ထိုးဂဏန်းများနှင့် ဂဏန်းပမာဏကို ဖျက်ရန် သေချာလား?"}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button style={{fontFamily:'mmfont'}} onClick={handleClose}>မဖျက်ပါ</Button>
            <Button style={{fontFamily:'mmfont'}} onClick={() => deleteHistory(checked)}
                disabled={digitStatus==="Closed"}
                >
                ဖျက်မည်
            </Button>
            </DialogActions>
        </Dialog>
        <div style={{width:'500px', paddingTop:5}}>
            <div className='historytitle'>
                <Button className='historylink' onClick= {() => navigate('/home')}>
                    <ArrowBackIosIcon color='black' 
                    style={{color:'black', 
                    }}/>
                </Button>
                <p style={{fontFamily:'mmfont'}}>ထိုးပြီး အကွက်များ</p>
            </div>
            <p style={{fontSize:15, color:'green', marginTop:delMes!==''?3:0, paddingLeft:8}}>{delMes}</p>
            <table className='tablehead' style={{backgroundColor:'white', width:'500px', overflow:'scroll'}}>
              <tbody>
                {
                  newHistory?.length === 0 && <tr>
                    <td style={{textAlign:'center', color:'white', width:'50px', fontFamily:'textfont', backgroundColor:'#6798c6'}}>SN</td>
                    <td style={{textAlign:'center', color:'white', width:'190px', lineHeight:2, fontFamily:'mmfont', backgroundColor:'#6798c6'}}>
                      <p style={{width:'190px', fontFamily:'mmfont'}}>ထိုးပီး ဂဏန်းများ
                      </p>
                      </td>
                    <td style={{textAlign:'center', color:'white', lineHeight:2, fontFamily:'mmfont', backgroundColor:'#6798c6'}}>
                      <p style={{fontFamily:'mmfont', width:'160px'}}>ထိုးခဲ့သော အချိန်
                      </p>
                      </td>
                    <td style={{textAlign:'center', color:'white', width:"100px", lineHeight:2, fontFamily:'mmfont', backgroundColor:'#6798c6'}}>
                    {
                      checked?.length < 1?
                      'ရွေးရန်' :
                      <Button
                      onClick={() => handleClickOpen(checked)}
                      size='small'
                      style={{padding:'2px 3px', fontFamily:'mmfont', backgroundColor:'#d10606',
                        textDecoration:'none !important', color:'white'}}
                      >
                        ဖျက်မည်
                      </Button>
                    }
                    </td>
                    <td style={{textAlign:'center', color:'white', width:"50px", lineHeight:2.5, fontFamily:'textfont', backgroundColor:'#6798c6', marginTop:-1}} className='tablebox'>
                      <p style={{fontFamily:'textfont', marginTop:-3}}>Del
                      </p>
                    </td>
                  </tr>
                }
                {
                  newHistory?.map((his, index) => (
                    <>
                    {index === 0 && <tr>
                      <td style={{textAlign:'center', color:'white', fontFamily:'textfont', backgroundColor:'#6798c6'}}>
                        <p style={{width:'50px', paddingTop:3}}>SN
                        </p>
                        </td>
                      <td style={{textAlign:'center', color:'white', lineHeight:2, fontFamily:'mmfont', backgroundColor:'#6798c6'}}>
                        <p style={{whiteSpace:'nowrap', fontFamily:'mmfont', minWidth:'118px'}}>ထိုးပီး ဂဏန်းများ
                        </p>
                        </td>
                      <td style={{textAlign:'center', color:'white', lineHeight:2, fontFamily:'mmfont', backgroundColor:'#6798c6', width:'170px', whiteSpace:'nowrap'}}>ထိုးခဲ့သော အချိန်</td>
                      <td style={{textAlign:'center', color:'white', lineHeight:2, fontFamily:'mmfont', backgroundColor:'#6798c6'}}>
                      <p style={{whiteSpace:'nowrap', width:'80px', fontFamily:'mmfont', textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', alignSelf:'center'}}>{
                        checked?.length < 1?
                        'ရွေးရန်' :
                        <Button
                        onClick={() => handleClickOpen(checked)}
                        size='small'
                        style={{padding:'2px 3px', fontFamily:'mmfont', backgroundColor:'#d10606',
                          textDecoration:'none !important', color:'white'}}
                        >
                          ဖျက်မည်
                        </Button>
                      }
                      </p>
                      </td>
                      <td style={{textAlign:'center', color:'white', width:"50px", lineHeight:2, fontFamily:'textfont', backgroundColor:'#6798c6'}} className='tablebox'>Del</td>
                    </tr>}
                    <tr style={{backgroundColor:'white'}}>
                        <td style={{fontSize:15, textAlign:'center', color:'black', border:'1px solid #6798c6'}}>
                          <p style={{fontFamily:'textfont', lineHeight:2, width:'50px', paddingTop:3}}>{index+1}
                          </p>
                          </td>
                        <td style={{fontSize:15, justifyContent:'left', alignItems:'left', textAlign:'left !important', paddingLeft:3, paddingRight:3, fontFamily:'textfont', border:'1px solid #6798c6'}}>
                          <p style={{whiteSpace:'nowrap', minWidth:'118px'}}>
                            {his?.result}
                          </p>
                          </td>
                        <td style={{fontSize:15, textAlign:'center', fontFamily:'textfont', border:'1px solid #6798c6'}} >
                          <p style={{whiteSpace:'nowrap', width:'170px', textAlign:'center'}}>
                            {his?.date?.split(' ')?.[0]?.split('T')?.[0]+' '+his?.date?.split(' ')?.[0]?.split('T')?.[1]}
                          </p>
                          </td>
                        <td style={{lineHeight:0, textAlign:'center', border:'1px solid #6798c6'}}>
                        <p style={{width:'80px'}}><input
                          size="large"
                          style={{
                            width:'25px'
                          }}
                          type='checkbox'
                          value={his?.id}
                          onChange={(event) => {handleChangeChecked(event, his?.result)}}
                          checked={checked?.some((item) => item?.id === his?.id)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        </p>
                        </td>
                        <td style={{width:'50px', border:'1px solid #6798c6'}}>
                          <p style={{textAlign:'center', width:'50px'}}>
                            <DeleteTwoToneIcon 
                              style={{marginBottom:-5}}
                                onClick={() => handleClickOpen([{'id':his?.id, 'result': his?.result}])}
                            />
                          </p>
                        </td>
                    </tr>
                    </>
                  ))
                }
              </tbody>
            </table>
            {/*<div className='tablehead'>
                <p style={{width:"50px", lineHeight:2}} className='tablebox'>SN</p>
                <p style={{width:'150px', lineHeight:2.2, fontFamily:'mmfont'}} className='tablebox'>ထိုးပီး ဂဏန်းများ</p>
                <p style={{width:'170px', lineHeight:2.2, fontFamily:'mmfont'}} className='tablebox'>ထိုးခဲ့သော အချိန်</p>
                <p style={{width:'80px', lineHeight:2.2, textAlign:'center', fontFamily:'mmfont'}} className='tablebox'>
                 {
                  checked?.length < 1?
                  'ရွေးရန်' :
                  <Button
                  onClick={() => handleClickOpen(checked)}
                  size='small'
                  style={{padding:'2px 3px', backgroundColor:'#d10606', fontFamily:'mmfont',
                    textDecoration:'none !important', color:'white'}}
                  >
                    ဖျက်မည်
                  </Button>
                 }
                </p>
                <p style={{width:50, lineHeight:2}} className='tablebox'>Del</p>
            </div>*/}
            {/*
            <List style={{paddingBottom:'30px'}} height={window.innerHeight - 80} itemSize={35} itemCount={newHistory?.length}>
            {({ index, style }) => (
              <div className='tabledata' style={style}>
                <p style={{width:"50px"}} className='tablebox'>{index+1}</p>
                <p style={{width:'150px', overflow:'scroll', whiteSpace:'nowrap', textAlign:'left !important', justifyContent:'left', padding:'0px 10px'}} className='tablebox'>{newHistory[index]?.result}</p>
                <p style={{width:'170px'}} className='tablebox'>{newHistory[index]?.date?.split(' ')?.[0]?.split('T')?.[0]+' '+newHistory[index]?.date?.split(' ')?.[0]?.split('T')?.[1]}</p>
                <div className='deltablebox' style={{width:'80px'}}>
                <input
                  type = "checkbox"
                  *
                  size='small'
                  sx={{
                    color: '#6798c6',
                    '&.Mui-checked': {
                      color: '#6798c6',
                    },
                  }}
                  *
                  value={newHistory[index]?.id}
                  key = {newHistory[index]?.result}
                  onChange={(event) => {handleChangeChecked(event, newHistory[index]?.result)}}
                  checked={checked?.some((item) => item?.id === newHistory[index]?.id)}
                  //inputProps={{ 'aria-label': 'controlled' }}
                />
                </div>
                <div className='deltablebox' style={{width:'50px'}}>
                    <DeleteTwoToneIcon 
                        onClick={() => handleClickOpen([{'id':newHistory[index]?.id, 'result': newHistory[index]?.result}])}
                    />
                </div>
            </div>
            )}
            </List>*/}
            {/*
              newHistory?.map((his,index) => (
                  <div className='tabledata'>
                      <p style={{width:"50px"}} className='tablebox'>{index+1}</p>
                      <p style={{width:'140px'}} className='tablebox'>{his?.result}</p>
                      <p style={{width:'170px'}} className='tablebox'>{his?.date?.split(' ')?.[0]?.split('T')?.[0]+' '+his?.date?.split(' ')?.[0]?.split('T')?.[1]}</p>
                      <div className='deltablebox' style={{width:'80px'}}>
                      <input
                        type = "checkbox"
                        
                        value={his?.id}
                        key = {his?.result}
                        onChange={(event) => {handleChangeChecked(event, his?.result)}}
                        checked={checked?.some((item) => item?.id === his?.id)}
                        //inputProps={{ 'aria-label': 'controlled' }}
                      />
                      </div>
                      <div className='deltablebox' style={{width:'50px'}}>
                          <DeleteTwoToneIcon 
                              onClick={() => handleClickOpen([{'id':his?.id, 'result': his?.result}])}
                          />
                      </div>
                  </div>
              ))
            */}
        </div>
        </div>
    )
}

export default ThreeHistoryScreen
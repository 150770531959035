import React, { useCallback, useMemo } from 'react';

function ThreeDigitArr({ numarray, getLimit, checkWithDigit, checkWithBuyDigit, handleClickOpen, firstDigit, lastDigit }) {
    const limit = useMemo(() => parseInt(getLimit?.[0]?.limit === undefined ? '0' : getLimit?.[0]?.limit), [getLimit]);
    const processedNumArray = useMemo(() => {
        return numarray?.filter(digit => parseInt(digit) >= firstDigit && parseInt(digit) < lastDigit).map((digit) => {
          //const digitInt = parseInt(digit);
          const amount = checkWithDigit(digit)?.[0]?.amount === undefined ? 0 : checkWithDigit(digit)?.[0]?.amount;
          const buy = checkWithBuyDigit(digit)?.[0]?.buy === undefined ? 0 : checkWithBuyDigit(digit)?.[0]?.buy;
          const amountDiff = amount - buy;
          const isLimitExceeded = limit < amountDiff;
    
          return {
            digit,
            amountDiff,
            style: {
                paddingTop:3,
              backgroundColor: isLimitExceeded ? '#2b3944' : 'rgba(103, 152, 198, 0.1)',
              color: isLimitExceeded ? 'white' : 'black',
              border: '1px solid #6798c6 !important'
            }
          };
        });
    }, [numarray, checkWithDigit, checkWithBuyDigit, limit, firstDigit, lastDigit]);

    const handleClick = useCallback((digit) => {
        handleClickOpen(digit);
    }, [handleClickOpen]);
    return (
        <div className='column'>
        {processedNumArray.map(({ digit, amountDiff, style }, index) => (
            <div className='dataBox' key={index}>
            <p className='digitBox' onClick={() => handleClick(digit)}>
                {digit}
            </p>
            <p className='amountBox' style={style}>
                {amountDiff !== 0 ? amountDiff : ''}
            </p>
            </div>
        ))}
        </div>
    )
}

export default ThreeDigitArr
import React from 'react'
import './SelectScreen.css'
import 'react-router-dom'
import { useAuth } from './Auth';

function SelectScreen() {
    const { setKindButton } = useAuth();
    return (
        <div className='selectWholeScreen'>
            <h1 style={{fontFamily:'digit'}}>Lucky</h1>
            <br/>
            <p className='title'>အမျိုးအစား ရွေးချယ်ပါ</p>
            <div className='selectBox'>
                <p onClick={() => setKindButton('2d')}>2D</p>
            </div><div className='selectBox'>
                <p onClick={() => setKindButton('3d')}>3D</p>
            </div>
            <p style={{marginTop:15, fontFamily:'mmfont', color:'red', textAlign:'center', lineHeight:2}}>သတိပြုရန် - စာရင်းသွင်းမည့် 2D (သို့) 3D ကို မှန်မှန်ကန်ကန်ရွေးပေးပါ။ စာရင်းမှားသွင်းမိပါက စာရင်းများကို ပြန်ကူးယူ၍ မရပါ၊ မှားသွင်းထားသည့်စာရင်းများကို ပြန်ဖျက်ပြီးမှ အားလုံးကို အသစ်ပြန်သွင်းရပါမည်။</p>
            <div style={{marginTop:10, display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
                <div style={{width:'100%', height:0.5, backgroundColor:'#6798c6'}}/>
                <p style={{display:'flex', flexDirection:'row', width:'280px', color:'#4f7aa3', textAlign:'center', justifyContent:'center'}}>Explore below</p>
                <div style={{width:'100%', height:0.5, backgroundColor:'#6798c6'}}/>
            </div>
            <div style={{marginTop:10, display:'flex', flexDirection:'column',
                justifyContent:'center', alignItems:'center'}}>
                <img
                onClick={
                    () => window.open('https://play.google.com/store/apps/details?id=com.yeminhtwe.latswelapp','_blank')
                }
                alt="ads"
                src={process.env.PUBLIC_URL + '/images/ads.png'}
                style={{
                    maxWidth:'250px',
                    borderRadius:10
                }}
                />
                <p style={{marginTop:15, fontFamily:'mmfont', fontSize:18, color:'#ed8200'}}>ပုံကို နိုပ်ပြီး ‌ဒေါင်းလုတ်ယူနိုင်ပါတယ်</p>
            </div>
        </div>
    )
}

export default SelectScreen
import React, {useState, useEffect, useMemo} from 'react'
import './HistoryScreen.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { mainUrl } from './url';
import { useAuth } from '../Auth';
import axios from 'axios';

function DetailScreen() {
    const navigate = useNavigate();
    const location = useLocation()
    const {userType, selLotteryDate} = useAuth()
    const [history, setHistory] = useState(location?.state?.history === undefined?[]:location?.state?.history)
    var selDate = selLotteryDate?.date
    var selDayPart = selLotteryDate?.datePart
    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistory&userid='+userType?.id+'&adminid='+userType?.adminid+'&date='+selDate+'&part='+selDayPart
                      }),
                    ]).then(response => {
                        setHistory(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [userType?.id, userType?.adminid, selDate, selDayPart])
    
    const numarray = Array(100).fill(0).map((n, i) => { 
        if((n + i) < 10){
          return '0'+String(n+i)
        } else {
          return String(n + i)
        }
      })

      const defineSplitlens = (data) => {
        if(data === ''){
          return 0
        } else {
          if(data?.includes('R')){
            return 3
          } else if(data?.includes('A') ||
          data?.includes('N') ||
          data?.includes('W') ||
          data?.includes('X')
          ) {
            return 1
          } else if(data?.includes('Z')) {
            var splZ = data?.split('Z')
            var last = splZ?.[splZ?.length-1]?.length
            var finalLen = data?.length - last
            return finalLen
          } else if(data?.includes('S') ||
            data?.includes('M')) {
            var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
            return len
          } else {
            return 2
          }
        }
    }
    
    const DefineDigit = (digit, result, numarray, amount, first, second) => {
        if(result?.includes('R') && !result?.includes('E')){
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var firstFirst = firstSplit?.slice(0,2)
        var reverseDigit = firstFirst?.split("").reverse().join("")
        return [{'digit':firstFirst, 'amount':first},{'digit':reverseDigit, 'amount':second}]
        } else if(digit.includes('P')){
        var newPDigit = digit?.replace('P','')
        var digitinclude = numarray.filter(num => num.includes(newPDigit))
        return digitinclude.map(item => ({'digit':item,'amount':amount}));
        } else if(digit.includes('A')){
        return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount}));
        } else if(digit === 'S'){
        var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
        return digitSEven.map(item => ({'digit':item,'amount':amount}));
        } else if(digit === 'M'){
        var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
        return digitOdd.map(item => ({'digit':item,'amount':amount}));
        } else if(digit === 'SS'){
        var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
        return digitSSEven.map(item => ({'digit':item,'amount':amount}));
        } else if(digit === 'SM'){
        var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
        return digitSMEven.map(item => ({'digit':item,'amount':amount}));
        } else if(digit === 'MM'){
        var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
        return digitMMEven.map(item => ({'digit':item,'amount':amount}));
        } else if(digit === 'MS'){
        var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
        return digitMSEven.map(item => ({'digit':item,'amount':amount}));
        } else if(digit.includes('F')){
        var newFDigit = digit?.replace('F','')
        var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
        return digitFFirst.map(item => ({'digit':item,'amount':amount}));
        } else if(digit.includes('L')){
        var newLDigit = digit?.replace('L','')
        var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
        return digitLFirst.map(item => ({'digit':item,'amount':amount}));
        } else if(digit.includes('B')){
        var newBDigit = digit?.replace('B','')
        var digitFirst = numarray.filter(num => 
            (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
            (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
            (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
            )
        return digitFirst.map(item => ({'digit':item,'amount':amount}));
        } else if(digit.includes('W')){
        return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount}));
        } else if(digit.includes('N')){
        return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount}));
        } else if(digit?.includes('X')){
        return ['01','10','12','21','23','32','34','43','45','54',
        '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount}));
        } else if(digit?.includes('Z')){
        if(digit?.includes('ZZ')){
            var newZZDigit = digit?.replace('ZZ','')
            var newZZArr = newZZDigit?.split('')
            return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount}));
        } else {
            var newZDigit = digit?.replace('Z','')
            var newZArr = newZDigit?.split('')
            return combinations(newZArr).map(item => ({'digit':item,'amount':amount}));
        }
        }else if(result?.includes('E')){
            var newEDigit = result?.split('E')?.[1]?.split(',')
            var amountE = result?.split('E')?.[2]
            var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
            var modEArr = []
            if(amountE?.includes('R')){
            newEDigit?.forEach((digit) => {
                if(digit === digit?.split("").reverse().join("")){
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
                } else {
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
                modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
                }
            })
            } else {
                newEDigit?.forEach((digit) => {
                    modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
                })
            }
            return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount}))
        }
        return [{'digit':digit, 'amount':amount}]
    }

    function removeDuplicates(arr) {
        return arr.filter((item,index) => arr.indexOf(item) === index);
    }
    const combinationsTwo = (arr) => {
        let set = new Set(arr);
        
        arr = [...set];
        var digit = []
        
        for(let i = 0; i < arr.length - 1; i++){       
        for(let j = i + 1; j < arr.length; j++){ 
            digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
        }     
        } 
        return removeDuplicates(digit)
    }

    const combinations = (arr) => {
        let set = new Set(arr);
        
        arr = [...set];
        var digit = []
        
        for(let i = 0; i < arr.length - 1; i++){       
        for(let j = i + 1; j < arr.length; j++){ 
            digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
        }     
        } 
        return digit
    }

    const useFilteredDetail = (detailAll) => {
    return useMemo(() => {
        if (!detailAll || detailAll.length === 0) return [];
    
        return detailAll.filter((his) => {
        return his?.buy === '1';
        });
    }, [detailAll]);
    };
    const newHistory = useFilteredDetail(history);

    const MakeDigitArr = () => {
        var digits = []
        newHistory?.forEach((his) => {
            var result = his?.result
            var newDigit = result?.split('R')
            var firstSplit = newDigit?.[0]
            var splitLen = defineSplitlens(result);
            var digitFormula = result?.slice(0,splitLen)
            var oneAmount = result?.slice(splitLen)
            /*
            var amount =  result?.includes('R') && result?.split('R')?.[0]?.length > 2?
                            {first:ramount[`${digitArr[0]}`], second:ramount[`${digitArr[1]}`]} : 
                            {first:result?.slice(splitLen), second:result?.slice(splitLen)};
            */
            var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
            //var ramount = setRamount(digitArr, result)
            
            digits?.push(digitArr)
        })
        //var digitMerged = digits?.flatMap(e => [...e, ...digits])
        /*
        const outputArray = digits.reduce((acc, obj) => {
            Object.entries(obj).forEach(([key, value]) => {
                acc.push({ value });
            });
            return acc;
        }, []);
        */
        return digits?.flat()
    }
    const digitMadeArr = MakeDigitArr()
    var details = Array.from(digitMadeArr?.reduce(
        (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + (parseInt(amount) || 0)), new Map()
      ), ([digit, amount]) => ({digit, amount}))
    return (
        <div
        style={{display:'flex', 
        justifyContent:'center', paddingBottom:10,
        alignItems:'start'}}
        >
        <div className='historyScreen'>
            <div className='historytitle'>
                <Button className='historylink' onClick= {() => navigate('/home')}>
                    <ArrowBackIosIcon color='black' 
                    style={{color:'black', 
                    }}/>
                </Button>
                <p style={{fontFamily:'mmfont'}}>အကွက်ဇယား</p>
            </div>
            <div className='tablehead'>
                <p style={{width:"20%", lineHeight:2.2}} className='tablebox'>SN</p>
                <p style={{width:'30%', lineHeight:2.2}} className='tablebox'>Digit</p>
                <p style={{width:'50%', lineHeight:2.2}} className='tablebox'>Amount</p>
                {/*<p style={{width:50}} className='tablebox'>Del</p>*/}
            </div>
            {
                details?.sort((a, b) => parseInt(a.digit) > parseInt(b.digit) ? 1 : -1)?.map((digit, index) => {
                    return (
                        <div className='tabledata'>
                            <p style={{width:"20%"}} className='tablebox'>{index+1}</p>
                            <p style={{width:'30%', backgroundColor:'rgba(117, 172, 212, 0.3)'}} className='tablebox'>{digit?.digit}</p>
                            <p style={{width:'50%'}} className='tablebox'>{digit?.amount}</p>
                            
                        </div>
                    )
                })
            }
            {<div className='tabledata'>
                <p style={{width:'50%', backgroundColor:'#6798c6', color:'white'}} className='tablebox'>Total</p>
                <p style={{width:'50%'}} className='tablebox'>{digitMadeArr?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)}</p>
            </div>
            }
        </div>
        </div>
    )
}

export default DetailScreen
import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Auth';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { ModeEditTwoTone } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SearchScreen() {
  const navigate = useNavigate();
  const {authTokens, userType, handleLogout, selLotteryDate} = useAuth()
  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [open, setOpen] = React.useState(false);
  const [defineId, setDefineId] = useState('')

  const handleClickOpen = (id) => {
    setOpen(true);
    setDefineId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateUser, setUpdateUser] = useState(0)

  const [users, setUsers] = useState([])
  const [getLimit, setGetLimit] = useState([])
  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimit&adminid='+userType?.id
                      }),
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                        var data = response?.[1]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                        setGetLimit(newdata)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [updateUser, userType?.id, selDayPart, selDate])
  
  const [delMes, setDelMes] = useState('')
  

  const deleteHistory = async(id, type) => {
        if(id !== ''){
            let delUrl = mainUrl+'api.php?op=deleteHistory&id='+id
            await axios({
            method: 'get',
            url: delUrl
            }).then(async() => {
                setDelMes(type==='edit'? 'ထိုးပီးဂဏန်းပြင်ဆင်ပီးပါပီ' : 'ထိုးပီးဂဏန်း ဖျက်ပီးပါပီ')
                setUpdateUser(updateUser+1)
                setOpen(false)
            })
        }
    }

  const handleClickEdit = (id) => {
    var historyDetail = historyAll?.filter((history) => history?.id === id)
    navigate('/searchinput',{
      state:{
        success:true,
        id:id,
        message:historyDetail?.[0]?.result + ' ထိုးကွက်အား ပြင်ဆင်ရန်',
        date: historyDetail?.[0]?.date,
        username:username
      }
    })
  }

  const [username, setUsername] = useState('')

  const handleChange = (event) => {
    setUsername(event.target.value);
  };
  const [historyAll, setHistoryAll] = useState([])
  const [history, setHistory] = useState([])
  useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                    axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistory&userid='+username+'&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                    }),
                    axios({
                      method:'get',
                      url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                  }),
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                        setHistory(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
        return () => {isSubscribed = false}
    }, [username, updateUser, userType?.id, selDate, selDayPart])

    const useNewHistory = (historyAll) => {
      return useMemo(() => {
        if (!historyAll || historyAll.length === 0) return [];
    
        return historyAll.filter((his) => {
          return his?.buy === '1';
        });
      }, [historyAll]);
    };
    const newHistory = useNewHistory(historyAll);

    /* ---------- digit edit screen ------ */

    const numarray = Array(100).fill(0).map((n, i) => { 
      if((n + i) < 10){
        return '0'+String(n+i)
      } else {
        return String(n + i)
      }
    })

    const defineSplitlens = (data) => {
      if(data === ''){
        return 0
      } else {
        if(data?.includes('R')){
          return 3
        } else if(data?.includes('A') ||
        data?.includes('N') ||
        data?.includes('W') ||
        data?.includes('X')
        ) {
          return 1
        } else if(data?.includes('Z')) {
          var splZ = data?.split('Z')
          var last = splZ?.[splZ?.length-1]?.length
          var finalLen = data?.length - last
          return finalLen
        } else if(data?.includes('S') ||
          data?.includes('M')) {
          var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
          return len
        } else {
          return 2
        }
      }
  }
  
  const DefineDigit = (digit, result, numarray, amount, first, second) => {
      if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,2)
      var reverseDigit = firstFirst?.split("").reverse().join("")
      return [{'digit':firstFirst, 'amount':first},{'digit':reverseDigit, 'amount':second}]
      } else if(digit.includes('P')){
      var newPDigit = digit?.replace('P','')
      var digitinclude = numarray.filter(num => num.includes(newPDigit))
      return digitinclude.map(item => ({'digit':item,'amount':amount}));
      } else if(digit.includes('A')){
      return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount}));
      } else if(digit === 'S'){
      var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
      return digitSEven.map(item => ({'digit':item,'amount':amount}));
      } else if(digit === 'M'){
      var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
      return digitOdd.map(item => ({'digit':item,'amount':amount}));
      } else if(digit === 'SS'){
      var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
      return digitSSEven.map(item => ({'digit':item,'amount':amount}));
      } else if(digit === 'SM'){
      var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
      return digitSMEven.map(item => ({'digit':item,'amount':amount}));
      } else if(digit === 'MM'){
      var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
      return digitMMEven.map(item => ({'digit':item,'amount':amount}));
      } else if(digit === 'MS'){
      var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
      return digitMSEven.map(item => ({'digit':item,'amount':amount}));
      } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount}));
      } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount}));
      } else if(digit.includes('B')){
      var newBDigit = digit?.replace('B','')
      var digitBFirst = numarray.filter(num => 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
          (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
          )
      return digitBFirst.map(item => ({'digit':item,'amount':amount}));
      } else if(digit.includes('W')){
      return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount}));
      } else if(digit.includes('N')){
      return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount}));
      } else if(digit?.includes('X')){
      return ['01','10','12','21','23','32','34','43','45','54',
      '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount}));
      } else if(digit?.includes('Z')){
      if(digit?.includes('ZZ')){
          var newZZDigit = digit?.replace('ZZ','')
          var newZZArr = newZZDigit?.split('')
          return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount}));
      } else {
          var newZDigit = digit?.replace('Z','')
          var newZArr = newZDigit?.split('')
          return combinations(newZArr).map(item => ({'digit':item,'amount':amount}));
      }
      }else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
              if(digit === digit?.split("").reverse().join("")){
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              } else {
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
              }
          })
        } else {
            newEDigit?.forEach((digit) => {
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount}))
      }
      return [{'digit':digit, 'amount':amount}]
  }

  function removeDuplicates(arr) {
      return arr.filter((item,index) => arr.indexOf(item) === index);
  }
  const combinationsTwo = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
      }     
      } 
      return removeDuplicates(digit)
  }

  const combinations = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
      }     
      } 
      return digit
  }

  const MakeDigitArr = () => {
      var digits = []
      newHistory?.forEach((his) => {
          var result = his?.result
          var newDigit = result?.split('R')
          var firstSplit = newDigit?.[0]
          var splitLen = defineSplitlens(result);
          var digitFormula = result?.slice(0,splitLen)
          var oneAmount = result?.slice(splitLen)
          var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
          digits?.push(digitArr)
      })
      return digits?.flat()
  }
  const digitMadeArr = MakeDigitArr()
  
    return (
        <div className='adminScreen'>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{fontFamily:'mmfont'}}>
            {"ထိုးဂဏန်း ဖျက်ရန်"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText style={{fontFamily:'mmfont'}} id="alert-dialog-description">
                ထိုးဂဏန်းများနှင့် ဂဏန်းပမာဏကို ဖျက်ရန် သေချာလား?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button style={{fontFamily:'mmfont'}} onClick={handleClose}>မဖျက်ပါ</Button>
            <Button style={{fontFamily:'mmfont'}} onClick={() => deleteHistory(defineId)} autoFocus>
                ဖျက်မည်
            </Button>
            </DialogActions>
        </Dialog>
        <div className='adminTop'>
            <Button
            onClick={() => navigate('/home')} 
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Home
            </Button>
            <Button
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            onClick={() => navigate('/adminbuy', {
              state:{
                limit:getLimit,
                historyAll:history,
                users:users
              }
            })} 
            >
            Admin buy
            </Button>
            <Button
            style={{textDecoration:'none', backgroundColor:'#2b3944',
            color:'white', textTransform:'capitalize', padding:'1px 5px'}}
            onClick={() => navigate('/search')} 
            >
            Search
            </Button>
            <Button
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            onClick={() => navigate('/profitloss', {
              state:{
                limit:getLimit,
                historyAll:history,
                users:users
              }
            })} 
            >
            Profit/Loss
            </Button>
            <Button
            onClick={() => navigate('/weekly', {
              state:{
                limit:getLimit,
                historyAll:historyAll,
                users:users
              }
            })} 
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
              Weekly Profit/Loss
            </Button>
            <Button
            onClick={() => navigate('/summary', {
              state:{
                limit:getLimit,
                historyAll:historyAll,
                users: users
              }
            })} 
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
              Weekly Summary
            </Button>
            <Button
            onClick={() => navigate('/account', {
              state:{
                limit:getLimit,
                historyAll:historyAll,
                users: users
              }
            })}
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Account
            </Button>
            <Button
            onClick={() => {navigate('/'); handleLogout()}}
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Log out
            </Button>
        </div>
        
        <div style={{backgroundColor:'white', marginTop:10, borderRadius:5, overflow:'auto'}}>
            <div style={{padding:10}}>
            <h4 style={{fontFamily:'mmfont'}}>ယခင်ရောင်းပီး စာရင်းများ</h4>
            <Select
            size='small'
            sx={{
                height:30,
                width:150,
                color: "black",
                '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                border:'1px solid rgba(0,0,0,0.2)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '.MuiSvgIcon-root ': {
                fill: "rgba(0,0,0,0.2) !important",
                }
            }}
            labelId="select-user"
            id="select-user"
            value={username}
            onChange={handleChange}
            >
                {
                users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                )
                }
            </Select>
            
            </div>
            <div style={{padding:10, flex:'0 0 auto'}}>
            <h4 style={{fontFamily:'mmfont'}}>Agent အလိုက် ရောင်းပီးစာရင်းများ</h4>
            <p style={{fontSize:15, color:'green'}}>{delMes}</p>
            <table>
                <tbody>
                <tr style={{backgroundColor:'#6798c6', color:'white'}}>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"30px"}}>SrN</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"160px", fontFamily:'mmfont'}}>ထိုးပီး ဂဏန်းများ</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"180px", fontFamily:'mmfont'}}>ထိုးခဲ့သောအချိန်</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"100px", fontFamily:'mmfont'}}>ပြင်ဆင်ရန်</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"50px"}}>Del</td>
                </tr>
                <tr style={{backgroundColor:'#6798c6', color:'white'}}>
                    <td colSpan={5}
                    style={{
                        border:'1px solid rgba(0,0,0,0.0)', fontFamily:'textfont'
                    }}
                    >Total {`->`} {digitMadeArr?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)}</td>
                </tr>
                {
                    newHistory?.map((history, index) => 
                    <tr className='number'>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                        {index+1}
                    </td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                        {history?.result}
                    </td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                        {history?.date?.split(' ')?.[0]?.split('T')?.[0]+' '+history?.date?.split(' ')?.[0]?.split('T')?.[1]}
                    </td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}
                    >
                        <ModeEditTwoTone
                         onClick={() => handleClickEdit(history?.id)}
                        />
                    </td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}
                    >
                        <DeleteTwoToneIcon 
                         onClick={() => handleClickOpen(history?.id)}
                        />
                    </td>
                    </tr>
                    )
                }
                </tbody>
            </table>
            </div>
        </div>
        </div>
    )
}

export default SearchScreen

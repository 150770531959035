import React, { useEffect, useState } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import './AccountTable.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { ModeEditTwoTone } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MenuItem, Select } from '@mui/material';
import { FixedSizeList as List } from "react-window";

function ThreeAccountScreen() {
  const navigate = useNavigate();
  const location = useLocation()
  const {authTokens, userType, handleLogout} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [open, setOpen] = React.useState(false);
  const [defineId, setDefineId] = useState('')

  const handleClickOpen = (id) => {
    setOpen(true);
    setDefineId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };
  var currentDate = moment().format('YYYY-MM-DD')
  const [updateUser, setUpdateUser] = useState(0)
  const [luckyreload, setLuckyReload] = useState(0)
  const [name, setName] = useState('')
  const [pass, setPass] = useState('')
  const [userLimit, setUserLimit] = useState('')
  const [commission, setCommission] = useState('')
  const [rate, setRate] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [rePass, setRePass] = useState('')
  const [closeTime, setCloseTime] = useState(dayjs(moment(currentDate+'T14:00:00').toLocaleString()))
  const [accStatus, setAccStatus] = useState('Open')

  var inputDate = moment().tz('Asia/Yangon').format();

  const [users, setUsers] = useState(location?.state?.users===null || undefined? []: location?.state?.users)
  const getLimit = location?.state?.limit===null || undefined? []: location?.state?.limit
  const historyAll = location?.state?.historyAll===null || undefined? []: location?.state?.historyAll
  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [updateUser, userType?.id])

  const [fetchData, setFetchData] = useState([])
  useEffect(() => {
  let isSubscribed = true;
      if(isSubscribed){
          (async function(){
              try{
                  await axios.all([
                    axios({
                      method:'get',
                      url: mainUrl+'api.php?op=threeuserdetail&username='+name
                    })
                  ]).then(response => {
                      setFetchData(response?.[0]?.['data']['data']['result'])
                  })
              } catch (error) {
                  
              }
          })()
      }
  return () => {isSubscribed = false}
  }, [name])

  const [mess, setMess] = useState('')
  const [existErr, setExistErr] = useState('')
  const handleCreate = async() => {
    let createUrl = mainUrl+'api.php?op=threecreateUser';
    if(fetchData?.length === 0){
      setExistErr('လိုင်းမကောင်းပါ')
      setMess('')
    }else {
      if(users?.length >= parseInt(userType?.limit)){
        setExistErr('အကောင့်ပြုလုပ်ရန် သတ်မှတ်ပမာဏကျော်နေပါတယ်')
      } else {
        if(fetchData?.length > 0) {
          setExistErr('အခြား Username နှင့် အမည်တူနေပါသည်. ပြန်ပြောင်းပေးပါ')
          setMess('')
        } else {
          if(name!=='' & pass !== '' & userLimit!=='' & commission!=='' & rate !== ''){
            await fetch(createUrl, {
              method:'post',
              headers:{
                'Content-Type':'application/x-www-form-urlencoded'
              },
              body:"username="+name+"&password="+pass+"&type=user&date="+inputDate+'&limit='+userLimit+'&commission='+commission+'&rate='+rate+'&adminid='+userType?.id+'&closeTime='+closeTime.format('HH:mm:ss')+"&status="+accStatus
            }).then(() => {
              setName('')
              setPass('')
              setUserLimit('')
              setCommission('')
              setRate('')
              setAccStatus('Open')
              setMess('Agent အကောင့် ပြုလုပ်ပီးပါပီ!')
              setExistErr('')
              setUpdateUser(updateUser+1)
            })
          } else {
            setExistErr('အချက်အလက် ပြည့်စုံစွာဖြည့်ပေးပါ')
            setMess('')
          }
        }
      }
    }
  }
  const [delMes, setDelMes] = useState('')
  const DeleteUser = async(id) => {
    if(id !== ''){
      let delUserUrl = mainUrl+'api.php?op=threedeleteUser&id='+id
      await axios({
        method: 'get',
        url: delUserUrl
      }).then(() => {
        setDelMes('Agent အကောင့်ဖျက်ပီးပါပီ')
        setUpdateUser(updateUser+1)
        setOpen(false)
      })
    }
  }

  const [updateMes, setUpdateMes] = useState('')
  const [successMes, setSuccessMes] = useState('')
  const updatePass = async() => {
    let updateUrl = mainUrl+'api.php?op=threechangePass';
    if(newPassword!=='' || rePass !== ''){
      if(newPassword === rePass){
        await fetch(updateUrl, {
          method:'post',
          headers:{
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body:"password="+newPassword+"&id="+userType?.id
        }).then(() => {
          setLuckyReload(luckyreload+1)
          setNewPassword('')
          setRePass('')
          setUpdateMes('')
          setSuccessMes('Password ချိန်းပီးပါပီ')
          handleLogout()
        })
      }else {
        setUpdateMes('Password တူအောင်ပြန်ရိုက်ထည့်ပေးပါ')
        setSuccessMes('')
      }
      }else {
        setUpdateMes('Password အသစ်ရိုက်ထည့်ပေးပါ')
        setSuccessMes('')
    }
  }
  const [edit, setEdit] = useState(false)
  const [upPass, setUpPass] = useState('')
  const [upLimit, setUpLimit] = useState('')
  const [upComm, setUpComm] = useState('')
  const [upRate, setUpRate] = useState('')
  const [upId, setupId] = useState('')
  const [upcloseTime, setUpCloseTime] = useState('')
  const [upAccStatus, setUpAccStatus] = useState('')
  const handleClickEdit = (id) => {
    var findUser = users?.filter((user) => user?.id === id)
    setupId(findUser?.[0]?.id)
    setName(findUser?.[0]?.username)
    setPass(findUser?.[0]?.password)
    setUserLimit(findUser?.[0]?.limit)
    setCommission(findUser?.[0]?.commission)
    setRate(findUser?.[0]?.rate)
    setCloseTime(dayjs(moment(currentDate+'T'+findUser?.[0]?.closeTime).toLocaleString()))
    setAccStatus(findUser?.[0]?.status)
    setUpPass(findUser?.[0]?.password)
    setUpLimit(findUser?.[0]?.limit)
    setUpComm(findUser?.[0]?.commission)
    setUpRate(findUser?.[0]?.rate)
    setUpCloseTime(dayjs(moment(currentDate+'T'+findUser?.[0]?.closeTime).toLocaleString()))
    setUpAccStatus(findUser?.[0]?.status)
  }
  const checkChange = () => {
    if(pass === upPass && userLimit === upLimit && commission === upComm && rate === upRate && closeTime.toString() === upcloseTime.toString() && accStatus === upAccStatus){
      return false
    } else {
      return true
    }
  }
  const handleClear = () => {
    setName('')
    setPass('')
    setUserLimit('')
    setCommission('')
    setRate('')
    setAccStatus('Open')
    setEdit(false)
  }
  const handleUpdate = async() => {
    let createUrl = mainUrl+'api.php?op=threeeditUser';
    if(pass !== '' & userLimit!=='' & commission!=='' & rate !== '' & closeTime!=="" & accStatus!==""){
      await fetch(createUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"password="+pass+'&limit='+userLimit+'&commission='+commission+'&rate='+rate+'&id='+upId+'&closeTime='+closeTime.format('HH:mm:ss')+"&status="+accStatus
      }).then(() => {
        setName('')
        setPass('')
        setUserLimit('')
        setCommission('')
        setRate('')
        setAccStatus('Open')
        setMess('Agent အကောင့် ပြင်ဆင်ပီးပါပီ!')
        setExistErr('')
        setUpdateUser(updateUser+1)
        setEdit(false)
      })
    } else {
      setExistErr('အချက်အလက် ပြည့်စုံစွာဖြည့်ပေးပါ')
      setMess('')
    }
  }
  return (
    <div className='adminScreen'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Agent အကောင့်ဖျက်မည်လား?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Agent နှင့်ပတ်သက်သည့် အချက်အလက်များ ပြန်မရနိုင်ပါ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>မဖျက်ပါ</Button>
          <Button onClick={() => DeleteUser(defineId)} autoFocus>
            ဖျက်မည်
          </Button>
        </DialogActions>
      </Dialog>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeadminbuy', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeprofitloss', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Profit/Loss
        </Button>
        {/*
        <Button
        onClick={() => navigate('/monthly')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Monthly
        </Button>
        */}
        <Button
        onClick={() => navigate('/account')}
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div style={{padding:10}}>
        <h4 style={{fontFamily:'mmfont', marginBottom:10}}>Admin Password ပြောင်းရန်</h4>
        <p style={{fontSize:15, color:'red'}}>{updateMes}</p>
        <p style={{fontSize:15, color:'green'}}>{successMes}</p>
        <TextField 
        autoComplete={false}
        size='small'
        id="new-password" variant="outlined" 
        value={newPassword}
        placeholder='Password အသစ်'
        onChange={(e) => setNewPassword(e.target.value)}
        type="password"
        /><br/>
        <TextField 
        style={{marginTop:5, marginBottom:5}}
        autoComplete={false}
        size='small'
        id="retype-password" variant="outlined" 
        value={rePass}
        placeholder='Password ပြန်ရိုက်ရန်'
        onChange={(e) => setRePass(e.target.value)}
        type="password"
        /><br/>
        <Button variant="contained" disableElevation
        style={{backgroundColor:'#6798c6', textTransform:'capitalize'}}
        onClick={() => updatePass()}
        >
            Save
        </Button>
      </div>
      <h5 style={{paddingLeft:10, color:'red', fontFamily:'mmfont', fontWeight:'550'}}>* အများဆုံး Agent အကောင့်အရေအတွက် - {userType?.limit}</h5>
      <div style={{display:'flex', overflow:'auto', flexWrap: 'nowrap'}}>
        <div style={{padding:10, flex:'0 0 auto', width:250}}>
          <h4 style={{fontFamily:'mmfont', marginBottom:10}}>Agent Account ပြုလုပ်ရန်</h4>
          <p style={{fontSize:15, color:'green'}}>{mess}</p>
          <p style={{fontSize:15, color:'red'}}>{existErr}</p>
          <TextField 
          disabled={edit}
          autoComplete={false}
          size='small'
          id="create-account-agent"
          value={name}
          placeholder='Username'
          onChange={(e) => setName(e.target.value)}
          /><br/>
          <TextField 
          style={{marginTop:5, marginBottom:5}}
          autoComplete={false}
          size='small'
          id="create-password" variant="outlined" 
          value={pass}
          placeholder='Password'
          onChange={(e) => setPass(e.target.value)}
          type="password"
          /><br/>
          <TextField 
          style={{marginBottom:5}}
          autoComplete={false}
          size='small'
          id="limit-amount-new" variant="outlined" 
          value={userLimit}
          placeholder='အများဆုံး ပမာဏ'
          onChange={(e) => setUserLimit(e.target.value)}
          type="number"
          /><br/>
          <TextField 
          style={{marginBottom:5}}
          autoComplete={false}
          size='small'
          id="commission-amount-new" variant="outlined" 
          value={commission}
          placeholder='ကော်မရှင်'
          onChange={(e) => setCommission(e.target.value)}
          type="number"
          /><br/>
          <TextField 
          style={{marginBottom:5}}
          autoComplete={false}
          size='small'
          id="rate-amount-new" variant="outlined" 
          value={rate}
          placeholder='အဆ'
          onChange={(e) => setRate(e.target.value)}
          type="number"
          />
          <h4 style={{paddingTop:10, paddingBottom:5, fontFamily:'mmfont', marginBottom:10}}>အကောင့် အဖွင့်/အပိတ်</h4>
          <Select
          size='small'
          sx={{
            height:40,
            width:210,
            color: "black",
            fontFamily:'number',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
              border:'1px solid #6798c6'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '.MuiSvgIcon-root ': {
              fill: "black !important",
            }
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={accStatus}
          //label="Day Part"
          onChange={(event) => setAccStatus(event.target.value)}
          >
            <MenuItem value='Open'>ဖွင့်မည်</MenuItem>
            <MenuItem value='Close'>ပိတ်မည်</MenuItem>
          </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={[
              'MobileTimePicker',
            ]}
          >
            <DemoItem
            label="ပိတ်ချိန်"
            >
              <MobileTimePicker
              views={['hours', 'minutes', 'seconds']}
              sx={{"& .css-gbv7e9-MuiFormControl-root-MuiTextField-root": {
                width: '100px !important'
              }}} 
              slotProps={{textField:{size:'small', style:{width:210}}}}
              value={closeTime}
              onChange={(newValue) => setCloseTime(newValue)}
              format='HH:mm:ss'
              />
            </DemoItem>
          </DemoContainer>
          </LocalizationProvider><br/>
          <Button variant="contained" disableElevation
          style={{backgroundColor:'#6798c6', textTransform:'capitalize'}}
          onClick={!checkChange()? () => handleClear() : edit? () => handleUpdate():() => handleCreate()}
          >
              {!checkChange()?'Clear': edit?'Update':'Create'}
          </Button>
        </div>
        <div style={{padding:10, flex:'0 0 auto'}}>
          <h4 style={{fontFamily:'mmfont', marginBottom:10}}>Agent အကောင့်များ</h4>
          <p style={{fontSize:15, color:'green'}}>{delMes}</p>
          <div style={{display:'flex', flexDirection:'row', marginBottom:'1.6px'}}>
            <p className='accountTableTd' style={{width:'50px', color:'white', backgroundColor:'#6798c6', maxWidth:'50px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>SrN</p>
            <p className='accountTableTd' style={{width:'100px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Username</p>
            <p className='accountTableTd' style={{width:'100px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Password</p>
            <p className='accountTableTd' style={{width:'140px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', fontFamily:'mmfont'}}>အများဆုံး ပမာဏ</p>
            <p className='accountTableTd' style={{width:'80px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', fontFamily:'mmfont'}}>ကော်မရှင်</p>
            <p className='accountTableTd' style={{width:'60px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', fontFamily:'mmfont'}}>အဆ</p>
            <p className='accountTableTd' style={{width:'90px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', fontFamily:'mmfont'}}>ပိတ်ချိန်</p>
            <p className='accountTableTd' style={{width:'110px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', fontFamily:'mmfont'}}>အဖွင့်/အပိတ်</p>
            <p className='accountTableTd' style={{width:'50px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Edit</p>
            <p className='accountTableTd' style={{width:'50px',color:'white', backgroundColor:'#6798c6', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Del</p>
          </div>
          
          <List style={{paddingBottom:'30px'}} width={1000} height={window.innerHeight - 80} itemSize={32} itemCount={users?.length}>
          {({ index, style }) => (
            <div style={style} className='accountTable'>
              <p className='number accountTableTd' style={{width:'50px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                {index+1}
              </p>
              <p className='number accountTableTd' style={{width:'100px', border:'1px solid #6798c6', padding:'0px 8px'}}>
                {users[index]?.username}
              </p> 
              <p className='number accountTableTd' style={{width:'100px', border:'1px solid #6798c6', padding:'0px 8px'}}>
                {users[index]?.password}
              </p>
              <p className='number accountTableTd' style={{width:'140px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'right'}}>
                {users[index]?.limit}
              </p>
              <p className='number accountTableTd' style={{width:'80px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                {users[index]?.commission}
              </p>
              <p className='number accountTableTd' style={{width:'60px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                {users[index]?.rate}
              </p>
              <p className='number accountTableTd' style={{width:'90px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                {users[index]?.closeTime}
              </p>
              <p className='number accountTableTd' style={{width:'110px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                {users[index]?.status}
              </p>
              <p className='accountTableTd' style={{width:'50px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                <ModeEditTwoTone 
                onClick={() => {
                  handleClickEdit(users[index]?.id);
                  setEdit(true)
                }}
                size={17}/>
              </p>
              <p style={{width:'50px', border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}
              onClick={() => handleClickOpen(users[index]?.id)}
              >
                <DeleteTwoToneIcon />
              </p>
            </div>
          )}
          </List>
           
        </div>
      </div>
    </div>
  )
}

export default ThreeAccountScreen

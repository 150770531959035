import React, {useEffect, useState} from 'react'
import './HomeScreen.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './ProfitLoss.css';
import axios from 'axios';
import { mainUrl } from './url';
import moment from 'moment-timezone'
import { useAuth } from '../Auth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function ThreeMonthlyScreen() {
  const [lucky, setLucky] = useState(null);
  const {authTokens, userType, handleLogout, threeDate} = useAuth()
  const navigate = useNavigate()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])
  const [detailAll, setDetailAll] = useState([])
  const [users, setUsers] = useState([])
  const [value, setValue] = useState(dayjs(moment(threeDate?.month).format('YYYY-MM')))
  var finalDate = value?.format('YYYY-MM-DD')

  useEffect(() => {
    let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threedetailAll&adminid='+userType?.id
                      }),
                    ]).then(response => {
                        setDetailAll(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
    return () => {isSubscribed = false}
  }, [userType?.id])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLucky&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      }),
                    ]).then(response => {
                      setLucky(response?.[0]?.['data']['data']['result'])
                      setUsers(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id])

  var newDetail = detailAll?.length>0 || detailAll!==null? 
  detailAll?.filter((his) => 
  moment(his?.date).tz('Asia/Yangon').format('YYYY-MM-DD') >= threeDate?.dateFrom && 
  moment(his?.date).tz('Asia/Yangon').format('YYYY-MM-DD') <= threeDate?.dateTo && his?.buy === '0'
  ) : []

  var newBuyDetail = detailAll?.length>0 || detailAll!==null? 
  detailAll?.filter((his) => 
  moment(his?.date).tz('Asia/Yangon').format('YYYY-MM-DD') >= threeDate?.dateFrom && 
  moment(his?.date).tz('Asia/Yangon').format('YYYY-MM-DD') <= threeDate?.dateTo && his?.amount === '0'
  ) : []

  
  var totalAmountWithAgent = Object.values(newDetail?.reduce((acc, {amount, userid, ...r}) => {
    var key = Object.entries(userid).join('-');
    acc[key] = (acc[key]  || {userid, ...r, amount:0});
    return (acc[key].amount += parseInt(amount), acc);
  }, {}));

  var currentMonday = moment(finalDate).format('YYYY-MM')
  var mondayAm = detailAll?.length>0 || detailAll!==null? 
  newDetail?.filter((his) => 
  his?.part === 'first'
  ) : []

  var mondayPm = detailAll?.length>0 || detailAll!==null? 
  newDetail?.filter((his) => 
  his?.part === 'second'
  ) : []

  const buyProduceArr = (day, daypart) => {
    return detailAll?.length>0 || detailAll!==null? newBuyDetail?.filter((his) => 
    his?.part === daypart):[]
  }

  var mondayAmBuy = buyProduceArr(currentMonday,'first')
  var mondayPmBuy = buyProduceArr(currentMonday,'second')

  const calculateTotalWithAgent = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const calculateTotalWithAgentBuy = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {buy}) => n + parseInt(buy), 0)
    return totalAmount
  }

  const calculateTotalWithLucky = (arr, uid, date, part) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === checkLucky(date, part)?.[0]?.lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const calculateTotalWithLuckyBuy = (arr, uid, date, part) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === checkLucky(date, part)?.[0]?.lucky)
    var totalAmount = searchUser?.reduce((n, {buy}) => n + parseInt(buy), 0)
    return totalAmount
  }

  const checkLucky = (date, part) => {
    return lucky?.filter((luck) => luck?.date === date && luck?.daypart === part )
  }

  const SaleTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgent(mondayAm,user?.id,currentMonday,'first') + calculateTotalWithAgent(mondayPm,user?.id,currentMonday,'second')
      var lossTotal = calculateTotalWithLucky(mondayAm,user?.id,currentMonday,'first') + calculateTotalWithLucky(mondayPm,user?.id,currentMonday,'second')
      sumTotal?.push({amount: (saleTotal - (saleTotal/100)*parseInt(user?.commission)) - (lossTotal * parseInt(user?.rate))
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }

  const BuyTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgentBuy(mondayAmBuy,user?.id,currentMonday,'first') + calculateTotalWithAgentBuy(mondayPmBuy,user?.id,currentMonday,'second')
      var lossTotal = calculateTotalWithLuckyBuy(mondayAmBuy,user?.id,currentMonday,'first') + calculateTotalWithLuckyBuy(mondayPmBuy,user?.id,currentMonday,'second')
      sumTotal?.push({amount: (lossTotal * parseInt(user?.rate)) - (saleTotal - (saleTotal/100)*parseInt(user?.commission))
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }

  var sr = 1
  var buysr = 1

  return (
    <div className='adminScreen'>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeadminbuy')} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search')} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeprofitloss', {
          state:{data:totalAmountWithAgent}
        })} 
        >
          Profit/Loss
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Monthly
        </Button>
        <Button
        onClick={() => navigate('/threeaccount')}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='bodyboxwhole'>
        <div className='bodybox'>
          <p style={{fontWeight:'bold'}}>လက်ရှိလ : {threeDate?.month}</p><br/>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DemoContainer
            components={['DatePicker', 'DatePicker']}>
                <DatePicker
                sx={{width:200, height:80}}
                format='YYYY-MM'
                label="Select month"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                />
            </DemoContainer>
          </LocalizationProvider>
          <h4>ကိုယ်စားလှယ်များနှင့် စာရင်းရှင်းချက် အချုပ်</h4><br/>
          <h4>အရောင်း စာရင်း (SALE)</h4>
          <table>
            <tbody>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td rowSpan={3} style={{width:50}}>SrNo</td>
                <td rowSpan={3} style={{width:110}}>Agent</td>
                <td colSpan={4}>{currentMonday}</td>
              </tr>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td colSpan={2}>ပထမအကြိမ်</td>
                <td colSpan={2}>ဒုတိယအကြိမ်</td>
              </tr>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td style={{width:110}}>
                  အတင်
                </td>
                <td style={{width:110}}>
                  အထိ
                </td>
                <td style={{width:110}}>
                  အတင်
                </td>
                <td style={{width:110}}>
                  အထိ
                </td>
                <td style={{width:110}}>
                  အတင်စုစုပေါင်း
                </td>
                <td style={{width:110}}>
                  အထိစုစုပေါင်း
                </td>
                <td style={{width:110}}>
                  Total
                </td>
              </tr>
              { users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => {
                var saleTotal = calculateTotalWithAgent(mondayAm,user?.id, currentMonday, 'first') + calculateTotalWithAgent(mondayPm,user?.id, currentMonday, 'second')

                var lossTotal = calculateTotalWithLucky(mondayAm,user?.id, currentMonday, 'first') + calculateTotalWithLucky(mondayPm,user?.id, currentMonday, 'second')
                if(saleTotal > 0){
                  return (
                    <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                    <td>
                      {sr++}
                    </td>
                    <td style={{width:120}}>
                      {user?.username}
                    </td>
                    <td>
                      {calculateTotalWithAgent(mondayAm,user?.id, currentMonday, 'first')}
                    </td>
                    <td>
                      {calculateTotalWithLucky(mondayAm,user?.id, currentMonday, 'first')}
                    </td>
                    <td>
                      {calculateTotalWithAgent(mondayPm,user?.id, currentMonday, 'second')}
                    </td>
                    <td>
                      {calculateTotalWithLucky(mondayPm,user?.id, currentMonday, 'second')}
                    </td>
                    {/*
                    <td>
                      {calculateTotalWithAgent(mondayAm,user?.id) + calculateTotalWithAgent(mondayPm,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLucky(mondayAm,user?.id) + calculateTotalWithLucky(mondayPm,user?.id)}
                    </td>
                    */}
                    <td>
                      {
                        saleTotal
                      }
                    </td>
                    <td>
                      {
                        lossTotal
                      }
                    </td>
                    <td style={{color:(saleTotal - (lossTotal*parseInt(user?.rate)) - ((saleTotal/100)*parseInt(user?.commission)))>=0?'black':'red'}}>
                      {
                        (saleTotal -
                          ((lossTotal) * 
                          parseInt(user?.rate))) - 
                          ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                      }
                    </td>
                  </tr>
                  )
                } else {
                  return null
                }
                  
              })
              }
            </tbody>
          </table>
          <table style={{marginTop:5, width:250}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#6798c6', paddingLeft:30, textAlign:'left', color:'white', lineHeight:2, paddingRight:15}}>Total Sale : {
                  SaleTotal()
                }</td>
              </tr>
            </tbody>
          </table><br/>
          <h4>ပြန်ဝယ် စာရင်း (BUY)</h4>
          <table>
            <tbody>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td></td>
                <td style={{width:110}}></td>
                <td colSpan={4}>1 Day ({currentMonday})</td>
              </tr>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td></td>
                <td style={{width:110}}></td>
                <td colSpan={2}>AM</td>
                <td colSpan={2}>PM</td>
              </tr>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td style={{width:50}}>
                  SrNo
                </td>
                <td style={{width:110}}>
                  Agent
                </td>
                <td style={{width:110}}>
                  အတင်
                </td>
                <td style={{width:110}}>
                  အထိ
                </td>
                <td style={{width:110}}>
                  အတင်
                </td>
                <td style={{width:110}}>
                  အထိ
                </td>
                <td style={{width:110}}>
                  အတင်စုစုပေါင်း
                </td>
                <td style={{width:110}}>
                  အထိစုစုပေါင်း
                </td>
                <td style={{width:110}}>
                  Total
                </td>
              </tr>
              { users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => {
                var saleTotal = calculateTotalWithAgentBuy(mondayAmBuy,user?.id, currentMonday, 'first') + calculateTotalWithAgentBuy(mondayPmBuy,user?.id, currentMonday, 'second')

                var lossTotal = calculateTotalWithLuckyBuy(mondayAmBuy,user?.id, currentMonday, 'first') + calculateTotalWithLuckyBuy(mondayPmBuy,user?.id, currentMonday, 'second')

                if(saleTotal > 0){
                  return (
                    <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                    <td>
                      {buysr++}
                    </td>
                    <td>
                      {user?.username}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(mondayAmBuy,user?.id,currentMonday,'first')}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(mondayAmBuy,user?.id,currentMonday,'first')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(mondayPmBuy,user?.id,currentMonday,'second')}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(mondayPmBuy,user?.id,currentMonday,'second')}
                    </td>
                    <td>
                      {
                        saleTotal
                      }
                    </td>
                    <td>
                      {
                        lossTotal
                      }
                    </td>
                    <td style={{color:saleTotal - (lossTotal*parseInt(user?.rate)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))>=0?'red':'black'}}>
                      {
                        ((lossTotal * 
                          parseInt(user?.rate)) -
                          (saleTotal)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                      }
                    </td>
                  </tr>
                  )
                } else {
                  return null
                }
              })
              }
            </tbody>
          </table>
          <table style={{marginTop:5, width:250}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#6798c6', paddingLeft:30, textAlign:'left', color:'white', lineHeight:2, paddingRight:15}}>Total Buy : {
                  BuyTotal()
                }</td>
              </tr>
            </tbody>
          </table>
          <table style={{marginTop:1, width:250}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#2b3944', paddingLeft:30, textAlign:'left', color:'white', lineHeight:2, paddingRight:15}}>Grand Total : {
                  SaleTotal() + BuyTotal()
                }</td>
              </tr>
            </tbody>
          </table><br/>
        </div>
      </div>
    </div>
  )
}

export default ThreeMonthlyScreen
import React, {useEffect, useState, useMemo} from 'react'
import './HomeScreen.css';
import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProfitLoss.css';
import axios from 'axios';
import { mainUrl } from './url';
import moment from 'moment-timezone'
import { useAuth } from '../Auth';

function ProfitLossScreen() {
  const [lucky, setLucky] = useState(null);
  const {selLotteryDate, authTokens, userType, handleLogout} = useAuth()
  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const numarray = Array(100).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '0'+String(n+i)
    } else {
      return String(n + i)
    }
  })

  const [historyAll, setHistoryAll] = useState(location?.state?.historyAll===null || undefined? []: location?.state?.historyAll)
  const [users, setUsers] = useState(location?.state?.users===null || undefined? []: location?.state?.users?.slice(0, 15))
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  useEffect(() => {
    let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                      }),
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
    return () => {isSubscribed = false}
  }, [currentDate, userType?.id, selDate, selDayPart])
  const [getLimit, setGetLimit] = useState([])
  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLucky&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimit&adminid='+userType?.id
                      }),
                    ]).then(response => {
                      var data = response?.[0]?.['data']['data']['result']
                      var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                      setLucky(newdata?.[0]?.lucky)
                      setUsers(response?.[1]?.['data']['data']['result'])
                      var dd = response?.[2]?.['data']['data']['result']
                      var newdd = dd?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                      setGetLimit(newdd)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id, selDate, selDayPart])

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 3
      } else if(data?.includes('A') ||
      data?.includes('N') ||
      data?.includes('W') ||
      data?.includes('X')
      ) {
        return 1
      } else if(data?.includes('Z')) {
        var splZ = data?.split('Z')
        var last = splZ?.[splZ?.length-1]?.length
        var finalLen = data?.length - last
        return finalLen
      } else if(data?.includes('S') ||
        data?.includes('M')) {
        var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
        return len
      } else {
        return 2
      }
    }
  }

  const DefineDigit = (digit, result, numarray, amount, first, second, uid) => {
      if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,2)
      var reverseDigit = firstFirst?.split("").reverse().join("")
      return [{'digit':firstFirst, 'amount':first, 'userid':uid},{'digit':reverseDigit, 'amount':second, 'userid':uid}]
      } else if(digit.includes('P')){
      var newPDigit = digit?.replace('P','')
      var digitinclude = numarray.filter(num => num.includes(newPDigit))
      return digitinclude.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('A')){
      return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'S'){
      var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
      return digitSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'M'){
      var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
      return digitOdd.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'SS'){
      var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
      return digitSSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'SM'){
      var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
      return digitSMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'MM'){
      var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
      return digitMMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'MS'){
      var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
      return digitMSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('B')){
      var newBDigit = digit?.replace('B','')
      var digitBFirst = numarray.filter(num => 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
          (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
          )
      return digitBFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('W')){
      return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('N')){
      return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit?.includes('X')){
      return ['01','10','12','21','23','32','34','43','45','54',
      '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount, 'userid':uid}));
      } else if(digit?.includes('Z')){
      if(digit?.includes('ZZ')){
          var newZZDigit = digit?.replace('ZZ','')
          var newZZArr = newZZDigit?.split('')
          return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else {
          var newZDigit = digit?.replace('Z','')
          var newZArr = newZDigit?.split('')
          return combinations(newZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      }
      }
      else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
              if(digit === digit?.split("").reverse().join("")){
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              } else {
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
              }
          })
        } else {
            newEDigit?.forEach((digit) => {
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid}))
      }
      return [{'digit':digit, 'amount':amount, 'userid':uid}]
  }

  function removeDuplicates(arr) {
      return arr.filter((item,index) => arr.indexOf(item) === index);
  }
  const combinationsTwo = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
      }     
      } 
      return removeDuplicates(digit)
  }

  const combinations = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
      }     
      } 
      return digit
  }

  const useFilteredDetail = (detailAll,check) => {
  return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];

      return detailAll.filter((his) => {
      return his?.buy === check;
      });
  }, [detailAll,check]);
  };

  const newHistory = useFilteredDetail(historyAll, '1');
  const newBuyHistory = useFilteredDetail(historyAll, '2');

  const MakeDigitArr = (newHistory) => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.userid);
        digits?.push(digitArr)
    })
    return digits?.flat()
  }
  const newDetail = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)
  
  const calculateTotalWithAgent = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const calculateTotalWithLucky = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const calculateTotalWithAgentBuy = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const calculateTotalWithLuckyBuy = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const SaleTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgent(newDetail,user?.id)
      var lossTotal = calculateTotalWithLucky(newDetail,user?.id)
      sumTotal?.push({amount: (saleTotal - (saleTotal/100)*parseInt(user?.commission)) - (lossTotal * parseInt(user?.rate))
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }
  
  const BuyTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgentBuy(newBuyDetail,user?.id)
      var lossTotal = calculateTotalWithLuckyBuy(newBuyDetail,user?.id)
      sumTotal?.push({amount: (lossTotal * parseInt(user?.rate)) - (saleTotal - (saleTotal/100)*parseInt(user?.commission))
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }

  var sr = 1
  var buysr = 1

  return (
    <div className='adminScreen'>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/adminbuy', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users: users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/profitloss')} 
        >
          Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/weekly', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/summary',{
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users: users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Summary
        </Button>
        <Button
        onClick={() => navigate('/account', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users: users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='bodyboxwhole'>
        <div className='bodybox'>
          <p style={{fontWeight:'bold'}}>Date : {selDate} {selDayPart}</p><br/>
          <h4 style={{fontFamily:'mmfont'}}>ကိုယ်စားလှယ်များနှင့် စာရင်းရှင်းချက် အချုပ် (တစ်ကြိမ်စာ)</h4><br/>
          <h4 style={{fontFamily:'mmfont'}}>အရောင်း စာရင်း (SALE)</h4>
          <table>
            <tbody>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td>
                  SrNo
                </td>
                <td>
                  Agent
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အရောင်း
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ကော်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ဒဲ့ပေါက်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အဆ
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  စုစုပေါင်း
                </td>
              </tr>
              {
                users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => {
                  var saleTotal = calculateTotalWithAgent(newDetail,user?.id)
                  var lossTotal = calculateTotalWithLucky(newDetail,user?.id)
                  if(saleTotal > 0){
                    return (
                      <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                        <td style={{textAlign:'center'}}>{sr++}</td>
                        <td style={{textAlign:'left'}}>
                          {user?.username}
                        </td>
                        <td>
                          {saleTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.commission}
                        </td>
                        <td>
                          {lossTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.rate}
                        </td>
                        <td style={{color:saleTotal - (lossTotal*parseInt(user?.rate)) - ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))>=0?'black':'red'}}>
                          {
                            (saleTotal -
                            ((lossTotal) * 
                            parseInt(user?.rate))) - 
                            ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                          }
                        </td>
                      </tr>
                    )
                  } else {
                    return null;
                  }
                })
              }
              {/*totalAmountWithAgent?.map((data, index) => {
                  var checkLuckNum = checkwithAllDigit(checkWithAllAgent(data?.userid))
                  var checkwithLuck = checkLuckNum?.filter((num) => num?.digit === lucky)
                  return (
                    <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                      <td>{index+1}</td>
                      <td>
                        {getEachUser(data?.userid)?.[0]?.username}
                      </td>
                      <td>
                        {data?.amount}
                      </td>
                      <td>
                        {getEachUser(data?.userid)?.[0]?.commission}
                      </td>
                      <td>
                        {checkwithLuck?.[0]?.amount !== undefined? (checkwithLuck?.[0]?.amount) : 0}
                      </td>
                      <td>
                        {getEachUser(data?.userid)?.[0]?.rate}
                      </td>
                      <td style={{color:
                        (data?.amount - ((data?.amount/100)*parseInt(getEachUser(data?.userid)?.[0]?.commission!==undefined? 
                        getEachUser(data?.userid)?.[0]?.commission:'13'))) > 
                        (checkwithLuck?.[0]?.amount !== undefined? (checkwithLuck?.[0]?.amount) * parseInt(getEachUser(data?.userid)?.[0]?.rate!==undefined?getEachUser(data?.userid)?.[0]?.rate:'80') : 0)?
                        'black':'red'
                      }}>
                        {(data?.amount - 
                          ((data?.amount/100)*parseInt(getEachUser(data?.userid)?.[0]?.commission !== undefined? getEachUser(data?.userid)?.[0]?.commission: '13'))) - 
                          (checkwithLuck?.[0]?.amount !== undefined? (checkwithLuck?.[0]?.amount) * 
                          parseInt(getEachUser(data?.userid)?.[0]?.rate !== undefined? getEachUser(data?.userid)?.[0]?.rate: '80') : 0)}
                      </td>
                    </tr>
                  )
                }
              )  
              */}
              <tr className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white', textAlign:'center'}}>
                  Total
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white'}}>
                  {SaleTotal()}
                </td>
              </tr>
            </tbody>
          </table><br/>

          <h4 style={{fontFamily:'mmfont'}}>အဝယ် စာရင်း (BUY)</h4>
          <table>
            <tbody>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td>
                  SrNo
                </td>
                <td>
                  Agent
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အရောင်း
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ကော်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ဒဲ့ပေါက်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အဆ
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  စုစုပေါင်း
                </td>
              </tr>
              {
                users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => {
                  var saleTotal = calculateTotalWithAgentBuy(newBuyDetail,user?.id)
                  var lossTotal = calculateTotalWithLuckyBuy(newBuyDetail,user?.id)
                  if(saleTotal > 0){
                    return (
                      <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                        <td style={{textAlign:'center'}}>{buysr++}</td>
                        <td style={{textAlign:'left'}}>
                          {user?.username}
                        </td>
                        <td>
                          {saleTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.commission}
                        </td>
                        <td>
                          {lossTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.rate}
                        </td>
                        <td style={{color:saleTotal - (lossTotal*parseInt(user?.rate)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))>=0?'red':'black'}}>
                          {
                            ((lossTotal * 
                            parseInt(user?.rate)) -
                            (saleTotal)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                          }
                        </td>
                      </tr>
                    )
                  } else {
                    return null;
                  }
                })
              }
              { /*totalBuyAmountWithAgent?.map((data, index) => {
                  var checkLuckNum = checkwithAllBuyDigit(checkWithAllBuyAgent(data?.userid))
                  var checkwithLuck = checkLuckNum?.filter((num) => num?.digit === lucky)
                  return (
                    <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                      <td>{index+1}</td>
                      <td>
                        {getEachUser(data?.userid)?.[0]?.username}
                      </td>
                      <td>
                        {-data?.buy}
                      </td>
                      <td>
                        {getEachUser(data?.userid)?.[0]?.commission}
                      </td>
                      <td>
                        {checkwithLuck?.[0]?.buy !== undefined? (checkwithLuck?.[0]?.buy) : 0}
                      </td>
                      <td>
                        {getEachUser(data?.userid)?.[0]?.rate}
                      </td>
                      <td style={{color:
                        (data?.buy - ((data?.buy/100)*parseInt(getEachUser(data?.userid)?.[0]?.commission))) < (checkwithLuck?.length > 0? checkLuckNum?.[0]?.buy * parseInt(getEachUser(data?.userid)?.[0]?.rate) : 0)?
                        'black':'red'
                      }}>
                        {-(data?.buy - ((data?.buy/100)*parseInt(getEachUser(data?.userid)?.[0]?.commission))) + (checkwithLuck?.length > 0? checkLuckNum?.[0]?.buy * parseInt(getEachUser(data?.userid)?.[0]?.rate) : 0)}
                      </td>
                    </tr>
                  )
                }
              )  
              */}
              <tr className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white', textAlign:'center'}}>
                  Total
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white'}}>
                  {BuyTotal()}
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{marginTop:5}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#2b3944', textAlign:'center', color:'white', lineHeight:2, paddingRight:15}}>Grand Total (Profit/ Loss) : {SaleTotal()+(BuyTotal())}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ProfitLossScreen
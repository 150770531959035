import { useMemo } from 'react';
import './AdminDigitArr.css';

const AdminDigitArr = ({ numarray, getLimit, checkWithDigit, checkWithBuyDigit, handleClickOpen, firstDigit, lastDigit }) => {
  // Precompute limit value
  const limitValue = useMemo(() => parseInt(getLimit?.[0]?.limit || '0'), [getLimit]);

  // Precompute digit data
  const digitData = useMemo(() => {
    if (!numarray) return [];
    return numarray.filter(digit => parseInt(digit) >= firstDigit && parseInt(digit) < lastDigit).map(digit => {
      //const parsedDigit = parseInt(digit);
      const digitDetails = checkWithDigit(digit)?.[0] || {};
      const buyDetails = checkWithBuyDigit(digit)?.[0] || {};
      const amount = digitDetails.amount ? (parseInt(digitDetails.amount) || 0) : 0;
      const buy = buyDetails.amount ? (parseInt(buyDetails.amount) || 0) : 0;
      const overLimit = limitValue < (amount - buy);
      
      return {
        digit,
        amount,
        buy,
        overLimit
      };
    });
  }, [numarray, limitValue, checkWithDigit, checkWithBuyDigit, firstDigit, lastDigit]);
  
  return (
    <div className='column'>
      {digitData.map((data, index) => (
        <div className='dataBox' key={index}>
          <p className='digitBox' onClick={() => handleClickOpen(data.digit)}>
            {data.digit}
          </p>
          <p style={{paddingTop:3}} className={`amountBox ${data.overLimit ? 'over-limit' : ''}`}>
            {data.amount ? (data.amount - data.buy) : ''}
          </p>
        </div>
      ))}
    </div>
  );
};

export default AdminDigitArr;

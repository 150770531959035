import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function ThreeAdminInput() {
  let wonAudio = new Audio('./songs/win.wav')
  let loseAudio = new Audio('./songs/lose.wav')
  const navigate = useNavigate();
  const {authTokens, userType, threeDate} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [result, setResult] = useState('')
  const [history, setHistory] = useState([])
  const [historyAll, setHistoryAll] = useState([])
  const [hotNum, setHotNum] = useState([])
  const [takeLoad, setTakeLoad] = useState(0)
  const [errorMes, setErrorMes] = useState('')
  const [success, setSuccess] = useState(false)
  const [limitAmount, setLimitAmount] = useState('0')

  var inputDate = moment().tz('Asia/Yangon').format();

  const numarray = Array(1000).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '00'+String(n+i)
    }else if((n + i) >= 10 && (n + i) < 100) {
      return '0'+String(n+i)
    } 
    else {
      return String(n + i)
    }
  })

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 4
      } else if(data?.includes('A') ||
      data?.includes('H')
      ) {
        return 1
      } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
        return 2
      } 
      else {
        return 3
      }
    }
  }
  const mixingDigit = (digit, first, second) => {
    var arr = digit?.split('');
    const makeDigit = [
      arr[0]+''+arr[1]+''+arr[2],
      arr[0]+''+arr[2]+''+arr[1],
      arr[1]+''+arr[0]+''+arr[2],
      arr[1]+''+arr[2]+''+arr[0],
      arr[2]+''+arr[0]+''+arr[1],
      arr[2]+''+arr[1]+''+arr[0],
    ]
    const digitArr = [...new Set(makeDigit)];
    const outputArray = digitArr.map((item, index) => {
      if(index === 0){
        return ({ 'digit': item, 'amount': first })
      } else {
        return ({ 'digit':item, 'amount': second })
      }
    });
    return outputArray
  }

  var splitLen = defineSplitlens(result);
  
  const DefineDigit = (digit, result, numarray, amount, first, second) => {
    if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,3)
      return mixingDigit(firstFirst, first, second)
    } else if(digit.includes('A')){
      return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('H')){
      return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('M')){
      var newMDigit = digit?.replace('M','')
      var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
      return digitMFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(result?.includes('S')){
      var splitSDigit = result?.split('S')
      var amountS = splitSDigit?.[0]?.length === 0? 
      splitSDigit?.[1]?.slice(2) :
      splitSDigit?.[0]?.length === 1 ?
      splitSDigit?.[1]?.slice(1) :
      splitSDigit?.[1]
      var firstDigit = ''
      var secondDigit = ''
      var thirdDigit = ''
      var sDigitArr = []
      if(splitSDigit?.[0]?.length === 0){
        secondDigit = splitSDigit?.[1]?.[0]
        thirdDigit = splitSDigit?.[1]?.[1]
        sDigitArr = ['0'+secondDigit+thirdDigit, 
          '1'+secondDigit+thirdDigit,
          '2'+secondDigit+thirdDigit,
          '3'+secondDigit+thirdDigit,
          '4'+secondDigit+thirdDigit,
          '5'+secondDigit+thirdDigit,
          '6'+secondDigit+thirdDigit,
          '7'+secondDigit+thirdDigit,
          '8'+secondDigit+thirdDigit,
          '9'+secondDigit+thirdDigit,
        ]
      }else if(splitSDigit?.[0]?.length === 1){
        firstDigit = splitSDigit?.[0]
        thirdDigit = splitSDigit?.[1]?.[0]
        sDigitArr = [firstDigit+'0'+thirdDigit, 
          firstDigit+'1'+thirdDigit,
          firstDigit+'2'+thirdDigit,
          firstDigit+'3'+thirdDigit,
          firstDigit+'4'+thirdDigit,
          firstDigit+'5'+thirdDigit,
          firstDigit+'6'+thirdDigit,
          firstDigit+'7'+thirdDigit,
          firstDigit+'8'+thirdDigit,
          firstDigit+'9'+thirdDigit,
        ]
      }else {
        firstDigit = splitSDigit?.[0]?.[0]
        secondDigit = splitSDigit?.[0]?.[1]
        sDigitArr = [firstDigit+secondDigit+'0', 
          firstDigit+secondDigit+'1',
          firstDigit+secondDigit+'2',
          firstDigit+secondDigit+'3',
          firstDigit+secondDigit+'4',
          firstDigit+secondDigit+'5',
          firstDigit+secondDigit+'6',
          firstDigit+secondDigit+'7',
          firstDigit+secondDigit+'8',
          firstDigit+secondDigit+'9',
        ]
      }
      return sDigitArr.map(item => ({'digit':item,'amount':amountS}))
    } else if(result?.includes('E')){
      var newEDigit = result?.split('E')?.[1]?.split(',')
      var amountE = result?.split('E')?.[2]
      var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
      var modEArr = []
      if(amountE?.includes('R')){
        newEDigit?.forEach((digit) => {
          modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
        })
      } else {
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
        })
      }
      return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount}))
    }
    return [{'digit':digit, 'amount':amount}]
  }

  const [users, setUsers] = useState([])
  const [username, setUsername] = useState('')
  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id])
  
	useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+username+'&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveDigitLimitUser&userid='+userType?.id+'&adminid='+userType?.adminid
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryAllFilter&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLimitAllDigit&adminid='+userType?.id
                      }),
                    ]).then(response => {
                        //setHistory(response?.[0]?.['data']['data']['result'])
                        setHotNum(response?.[0]?.['data']['data']['result'])
                        //setHistoryAll(response?.[2]?.['data']['data']['result'])
                        var nextdata = response?.[1]?.['data']['data']['result']
                        setLimitAmount(nextdata?.[0]?.limit_amount === undefined || nextdata===null?'0':nextdata?.[0]?.limit_amount)
                      })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [takeLoad, currentDate, userType?.id, userType?.adminid])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+username+'&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveDigitLimitUser&userid='+userType?.id+'&adminid='+userType?.adminid
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryAllFilter&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLimitAllDigit&adminid='+userType?.id
                      }),
                      */
                    ]).then(response => {
                        setHistory(response?.[0]?.['data']['data']['result'])
                        //setHotNum(response?.[1]?.['data']['data']['result'])
                        setHistoryAll(response?.[1]?.['data']['data']['result'])
                        /*
                        var nextdata = response?.[3]?.['data']['data']['result']
                        setLimitAmount(nextdata?.[0]?.limit_amount === undefined || nextdata===null?'0':nextdata?.[0]?.limit_amount)
                        */
                      })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [takeLoad, username, userType?.id, threeDate?.dateFrom, threeDate?.dateTo])
  
  const useFilteredDetail = (detailAll) => {
    return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];
  
      return detailAll.filter((his) => {
        return his?.buy === '1';
      });
    }, [detailAll]);
  };

  const newHistory = useFilteredDetail(history);
  const newHistoryAll = useFilteredDetail(historyAll)

  const MakeDigitArr = () => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const MakeDigitArrAll = () => {
    var digits = []
    newHistoryAll?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const digitMadeArr = MakeDigitArr()
  const digitMadeArrAll = MakeDigitArrAll()

  const useDetailAmount = (newDetail) => {
    return useMemo(() => {
      const detailAmount = newDetail.reduce((acc, { amount, digit, ...rest }) => {
        const key = digit;
        if (!acc[key]) {
          acc[key] = { digit, ...rest, amount: 0 };
        }
        acc[key].amount += parseInt(amount, 10);
        return acc;
      }, {});
  
      return Object.values(detailAmount);
    }, [newDetail]);
  };

  const detailAmount = useDetailAmount(digitMadeArrAll);
  
  const CheckWithDigit = (digit) => {
    return detailAmount?.filter((detail) => detail?.digit === digit) || []
  }

  const checkWithAgent = (uid) => {
    return users?.filter((user) => user?.id === uid)
  }

  useEffect(() => {
    var spl = result?.split('R')
    if(spl?.length > 2){
      reset()
    }else if(result?.includes('H') && result?.includes('R')) {
      reset()
    }else if(result?.includes('A') && result?.includes('R')) {
      reset()
    }else if(result?.includes('L') && result?.includes('R')) {
      reset()
    }else if(result?.includes('F') && result?.includes('R')) {
      reset()
    }else if(result?.includes('M') && result?.includes('R')) {
      reset()
    }else if(result?.includes('R') && result?.length < 4){
      reset()
    }else if(result?.includes('R')){
      if(result?.[0] === result?.[1] 
        & result?.[0] === result?.[2]
      ){
        reset()
      }
    }else if(result?.includes('S')){
      if((result?.indexOf('S') === 0 || result?.indexOf('S') === 1 || result?.indexOf('S') === 2) && (result?.match(/S/g) || []).length === 1) {
        //continue
      }else {
        reset()
      }
    }
  },[result, splitLen])
  
  const onClick = (button) => {
    if(button === "Save") {
      SaveDigit();
    }
    else if(button === "C") {
      setErrorMes('')
      reset();
    }
    else {
      if(button === 'A' ||
      button === 'H'
      ) {
        setResult(button)
      } else if (button === "F" ||
        button === "L" ||
        button === "M"
      ) {
        if(result?.length > 0) {
          if(result?.[0] === '0' ||
          result?.[0] === '1' ||
          result?.[0] === '2' ||
          result?.[0] === '3' ||
          result?.[0] === '4' ||
          result?.[0] === '5' ||
          result?.[0] === '6' ||
          result?.[0] === '7' ||
          result?.[0] === '8' ||
          result?.[0] === '9'
          ) {
            setResult(result?.[0]+button)
          } else {
            reset()
          }
        } else {
          reset()
        }
      } else if(button === 'R') {
        setResult(result+button)
      }
      else {
        setResult(result+button)
      }
    }
  }
  const reset = () => {
    setResult('')
  }

  var newDigit = result?.split('R')
  var firstSplit = newDigit?.[0]
  const digitArrForResult = DefineDigit(result?.slice(0,splitLen), result, numarray, result?.slice(defineSplitlens(result)), result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');

  var overdigit = [];
  var limitAmountCheck = digitArrForResult?.some((digit) => {
    var amountcheck = Array.from(digitMadeArr?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
    ), ([digit, amount]) => ({digit, amount}));
    var hotamount = hotNum !== null? Array.from(hotNum?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
    ), ([digit, amount]) => ({digit, amount})):[];
    var getDigitAmount = amountcheck?.filter((getDigit) => getDigit?.digit === digit)
    
    var getHotNumber = hotamount?.filter((hot) => hot?.digit === digit)
    overdigit = [{
      hotAmount: getHotNumber?.[0]?.amount,
      digit:digit,
      digitamount: getDigitAmount?.[0]?.amount
    }]
    
    return getHotNumber?.length > 0 && hotNum !== null? 
    getHotNumber?.some((hot) => {
      var getDigit = amountcheck?.filter((a) => a?.digit === hot?.digit)
      return ((getDigit?.[0]?.amount!==undefined? getDigit?.[0]?.amount:0)+
      (parseInt(digit?.amount))) > 
      hot?.amount
    }):
    getDigitAmount?.length > 0?
    getDigitAmount?.some((sumdigit) => {
      return (
        (sumdigit?.amount + parseInt(digit?.amount)) > parseInt(checkWithAgent(username)?.[0]?.limit)
      )
    }):
    parseInt(digit?.amount) > parseInt(checkWithAgent(username)?.[0]?.limit)
  })

  var checkLimitOver = digitArrForResult?.filter((digit) => {
    return (
      (parseInt(CheckWithDigit(digit?.digit)?.[0]?.amount === undefined ? '0':CheckWithDigit(digit?.digit)?.[0]?.amount)) + parseInt(digit?.amount===undefined?'0':digit?.amount)) > parseInt(limitAmount)
  })
  
  var checkLimitCorrect = digitArrForResult?.filter((digit) => {
    return (parseInt(digit?.amount) || 0) < 1
  })

  const SaveDigit = async() => {
    if(username !==''){
      let messageUrl = mainUrl+'api.php?op=threesaveDigit';
      setErrorMes('')
      if(checkLimitOver?.length > 0){
          loseAudio.play()
          setSuccess(false)
          setErrorMes(checkLimitOver?.[0]?.digit+' -> သတ်မှတ်ပမာဏ ကျော်နေပါတယ်!')
      } else {
        // amount correct
        if(checkLimitCorrect?.length > 0){
          loseAudio.play()
          setSuccess(false)
          setErrorMes('ပမာဏ မမှန်ကန်ပါ')
        } else {
          if(limitAmountCheck){
            loseAudio.play()
            setSuccess(false)
            setErrorMes(overdigit?.[0]?.digit?.digit+' (ကျန်ပမာဏ - '+
            (overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount !== undefined? overdigit?.[0]?.hotAmount - overdigit?.[0]?.digitamount : 
            overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount === undefined? overdigit?.[0]?.hotAmount - 0:
            overdigit?.[0]?.hotAmount === undefined && overdigit?.[0]?.digitamount !== undefined? parseInt(userType?.limit) - overdigit?.[0]?.digitamount:
            parseInt(userType?.limit))+')')
          } else {
            await fetch(messageUrl, {
              method:'post',
              headers:{
                'Content-Type':'application/x-www-form-urlencoded'
              },
              body:"result="+result+"&userid="+username+"&date="+inputDate+"&buy=1&adminid="+userType?.id+"&part="+threeDate?.part
            }).then(async() => {
              wonAudio.play()
              setResult('')
              setTakeLoad(takeLoad+1)
              setErrorMes(result+' သိမ်းပြီး!')
              setSuccess(true)
            })
          }
        }
      }
    } else {
      setErrorMes('Agent အရင်ရွေးပါ')
      setSuccess(false)
      loseAudio.play()
    }
	}

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])

  //var digitStatus = DigitStatus()
  var digitStatus = 'Open'

  return (
    <div className='adminScreen'>
      
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Back
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='adminHomeScreen'>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>အမည်</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {userType?.username} (ဒိုင်)</p>
            </div>
          </div>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>ဖွင့်ရက်</p>
            </div>
            <div style={{marginLeft: 20, fontSize:13}}>
              <p className='text'>: &nbsp; {threeDate?.dateFrom} မှ {threeDate?.dateTo}</p>
            </div>
            <div style={{marginLeft: 20}}>
            <Button
            style={{padding:'0px 5px', backgroundColor:'white', fontSize:13,
              textDecoration:'none !important', color:'black', textTransform:'lowercase'}}
            onClick={() => navigate('/')}
            >
              Select Date
            </Button>
            </div>
          </div>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>Status</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {digitStatus}</p>
            </div>
          </div>
          <div className='digitscreen' style={{paddingBottom:10}}>
            <div className='topbar'>
              <p className='text'>Agents</p>
            </div>
            <div style={{marginLeft: 20}}>
              <Select
                size='small'
                sx={{
                  height:30,
                  width:150,
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                    border:'2px solid white'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                }}
                labelId="select-user"
                id="select-user"
                value={username}
                onChange={handleChange}
                >
                  {
                    users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                    <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                    )
                  }
              </Select>
            </div>
          </div>
          <div style={{padding:'3px 10px 0px 10px', display:'flex'}}>
            <p>Result: </p> <p style={{color: success?'#1bbc12':'#d8005d', lineHeight:'20px'}}>&nbsp; {errorMes}</p>
          </div>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: "95%"},
            }}
            className='White'
            style={{textAlign:'center'}}
            noValidate
            autoComplete="off"
          >
          <div>
            <TextField
              size="small"
              disabled
              value={result}
              id="outlined-disabled"
              placeholder='Press any digit'
              sx={{
                  "& .MuiInputBase-root.Mui-disabled": {
                      "& > fieldset": {
                          borderColor: "rgba(0,0,0,0.1)",
                          WebkitTextFillColor: "#000000",
                      },
                      WebkitTextFillColor: "#000000",
                      fontSize:27,
                  }
              }}
              InputProps={{
                sx: {
                  "&.MuiInputBase-root.Mui-disabled": { color: "unset" },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "unset",
                  },
                },
                autoComplete: 'off'
              }}
            />
          </div>
          </Box>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value="C"
            style={{fontFamily:'mmfont'}}
            >
            ဖျက်မည်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "A"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
            Tri
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "R"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              R
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "F"
            disabled={digitStatus==="Closed"}
            style={{fontFamily:'mmfont'}}
            >
            ထိပ်
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "7"
            disabled={digitStatus==="Closed"}
            >
            7
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "8"
            disabled={digitStatus==="Closed"}
            >
            8
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "9"
            disabled={digitStatus==="Closed"}
            >
            9
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "M"
            disabled={digitStatus==="Closed"}
            style={{fontFamily:'mmfont'}}
            >
            လယ်
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "4"
            disabled={digitStatus==="Closed"}
            >
            4
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "5"
            disabled={digitStatus==="Closed"}
            >
            5
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "6"
            disabled={digitStatus==="Closed"}
            >
            6
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "L"
            disabled={digitStatus==="Closed"}
            style={{fontFamily:'mmfont'}}
            >
            နောက်
            </button>
          </div>
          <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "1"
            disabled={digitStatus==="Closed"}
            >
            1
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'203%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "0"
            disabled={digitStatus==="Closed"}
            >
            0
            </button>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            style={{width:'100%'}}
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "2"
            disabled={digitStatus==="Closed"}
            >
            2
            </button>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "3"
            disabled={digitStatus==="Closed"}
            >
            3
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            style={{width:'100%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "00"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              00
            </button>
            </div>
            <button
            //variant="contained" disableElevation
            className='buttonBox fourthBox'
            //disableRipple
            style={{backgroundColor:'#6798c6 !important', fontFamily:'mmfont'}}
            onClick={(e) => onClick(e.target.value)}
            value = "Save"
            disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
            >
            သိမ်းမည်
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThreeAdminInput

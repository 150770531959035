import { InfoOutlined } from '@mui/icons-material'
import React from 'react'

function NotfoundScreen() {
    return (
        <div style={{
            display:'flex',
            flex:1,
            justifyContent:"center",
            alignItems:'center',
            height:'100vh'
        }}>
            <div style={{display:'flex', alignItems:'center'}}>
            <InfoOutlined/>
            <h3 style={{fontFamily:'digit', color:'black', marginLeft:10}}>
                Page not found!
            </h3>
            </div>
        </div>
    )
}

export default NotfoundScreen
import React from 'react'
import { Dialog, DialogContent, IconButton, AppBar, Toolbar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowBack } from '@mui/icons-material';

function EachResultEditable({index, eachEditResult, setEachEditResult, editResult, setEditResult, eachEditOpen, handleeachEditClose, Transition, digitStatus}) {
    return (
        <Dialog
        fullScreen
        open={eachEditOpen}
        onClose={handleeachEditClose}
        TransitionComponent={Transition}
      >
        <AppBar
        sx={{ position: 'relative', backgroundColor:'#6798c6', boxShadow:'0 0 0 0px' }}
        >
          <Toolbar>
            <IconButton
            edge="start"
            color="inherit"
            onClick={handleeachEditClose}
            aria-label="close"
            >
              <ArrowBack />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, fontFamily:'mmfont' }} variant="h6" component="div">
              ရိုက်ကွက်ပြင်ဆင်ရန်
            </Typography>
            <Button 
            onClick={() => {
                const updatedArray = editResult?.split('\n')?.map((item, i) => (i === index ? eachEditResult : item));
                setEditResult(updatedArray?.join('\n'));
                handleeachEditClose()
            }}
            style={{fontFamily:'mmfont'}} autoFocus color="inherit">
              သိမ်းမည်
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent
        sx={{
          padding:'5px',
          overflowX:'hidden'
        }}
        >
        <div
        style={{width:'100%', border:'1px solid #6798c6', padding:10, overflow:'auto'}}
        >
            <p style={{fontFamily:'textfont', fontSize:27, padding:'5px 5px'}}>
                {eachEditResult}
            </p>
        </div>
          
          <div style={{marginTop:5, marginLeft:-10, marginRight:-10}}>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={() => {
                setEachEditResult(eachEditResult.slice(0,-1))
            }}
            value="C"
            style={{fontFamily: 'mmfont'}}
            >
            ဖျက်မည်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "P"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            အပါ
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "R"
            disabled={digitStatus==="Closed"}
            id="digitBox"
            >
            R
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "A"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            အပူး
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "7"
            disabled={digitStatus==="Closed"}
            >
            7
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "8"
            disabled={digitStatus==="Closed"}
            >
            8
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "9"
            disabled={digitStatus==="Closed"}
            >
            9
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "S"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            စုံ
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "4"
            disabled={digitStatus==="Closed"}
            >
            4
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "5"
            disabled={digitStatus==="Closed"}
            >
            5
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "6"
            disabled={digitStatus==="Closed"}
            >
            6
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "M"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            မ
            </button>
          </div>
          <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "1"
            disabled={digitStatus==="Closed"}
            >
            1
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'202%', marginTop:3}}
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "0"
            disabled={digitStatus==="Closed"}
            >
            0
            </button>
            </div>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "2"
            disabled={digitStatus==="Closed"}
            >
            2
            </button>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "3"
            disabled={digitStatus==="Closed"}
            >
            3
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%', marginTop:3}}
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "00"
            disabled={digitStatus==="Closed"}
            >
            00
            </button>
            </div>
            <button
            //variant="contained" disableElevation
            className='buttonBox fourthBox'
            //disableRipple
            style={{backgroundColor:'#6798c6 !important', fontFamily:'digit'}}
            onClick={() => {
                const updatedArray = editResult?.split('\n')?.map((item, i) => (i === index ? eachEditResult : item));
                setEditResult(updatedArray?.join('\n'));
                handleeachEditClose()
            }}
            //value = "Save"
            //disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
            >
            OK
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "F"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ထိပ်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "L"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            နောက်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "E"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ငွေတူ
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "B"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ဘရိတ်
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "W"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ပါဝါ
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "N"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            နခတ်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "X"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ညီကို
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => {
                setEachEditResult((prevVal) => prevVal+e.target.value)
            }}
            value = "Z"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            ခွေ
            </button>
          </div>
          </div>
        </DialogContent>
      </Dialog>
    )
}

export default EachResultEditable
import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Auth';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { ModeEditTwoTone } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function ThreeSearchScreen() {
  const navigate = useNavigate();
  const location = useLocation()
  const {authTokens, userType, handleLogout, threeDate} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [open, setOpen] = React.useState(false);
  const [defineId, setDefineId] = useState('')

  const handleClickOpen = (id) => {
    setOpen(true);
    setDefineId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateUser, setUpdateUser] = useState(0)

  const [users, setUsers] = useState(location?.state?.users===null || undefined? []: location?.state?.users)
  const [getLimit, setGetLimit] = useState(location?.state?.limit===null || undefined? []: location?.state?.limit)

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLimit&adminid='+userType?.id
                      }),
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                        var data = response?.[1]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                        setGetLimit(newdata)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [updateUser, userType?.id, threeDate?.part, threeDate?.month])
  
  const [delMes, setDelMes] = useState('')
  

  const deleteHistory = async(id, type) => {
        if(id !== ''){
            let delUrl = mainUrl+'api.php?op=threedeleteHistory&id='+id
            await axios({
            method: 'get',
            url: delUrl
            }).then(async() => {
                setDelMes(type==='edit'? 'ထိုးပီးဂဏန်းပြင်ဆင်ပီးပါပီ' : 'ထိုးပီးဂဏန်း ဖျက်ပီးပါပီ')
                setUpdateUser(updateUser+1)
                setOpen(false)
            })
        }
    }
  const handleClickEdit = (id) => {
    var historyDetail = historyAll?.filter((history) => history?.id === id)
    navigate('/threesearchinput',{
      state:{
        success:true,
        id:id,
        message:historyDetail?.[0]?.result + ' ထိုးကွက်အား ပြင်ဆင်ရန်',
        date: historyDetail?.[0]?.date,
        username:username
      }
    })
  }
  
  const [username, setUsername] = useState('')

  const handleChange = (event) => {
    setUsername(event.target.value);
  };
  const [historyAll, setHistoryAll] = useState([])
  useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                    axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+username+'&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                    }),
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
        return () => {isSubscribed = false}
    }, [username, updateUser, userType?.id, threeDate?.dateFrom, threeDate?.dateTo])

    const numarray = Array(1000).fill(0).map((n, i) => { 
      if((n + i) < 10){
        return '00'+String(n+i)
      }else if((n + i) >= 10 && (n + i) < 100) {
        return '0'+String(n+i)
      } 
      else {
        return String(n + i)
      }
    })

    const defineSplitlens = (data) => {
      if(data === ''){
        return 0
      } else {
        if(data?.includes('R')){
          return 4
        } else if(data?.includes('A') ||
        data?.includes('H')
        ) {
          return 1
        } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
          return 2
        } 
        else {
          return 3
        }
      }
    }
    const mixingDigit = (digit, first, second, uid) => {
      var arr = digit?.split('');
      const makeDigit = [
        arr[0]+''+arr[1]+''+arr[2],
        arr[0]+''+arr[2]+''+arr[1],
        arr[1]+''+arr[0]+''+arr[2],
        arr[1]+''+arr[2]+''+arr[0],
        arr[2]+''+arr[0]+''+arr[1],
        arr[2]+''+arr[1]+''+arr[0],
      ]
      const digitArr = [...new Set(makeDigit)];
      const outputArray = digitArr.map((item, index) => {
        if(index === 0){
          return ({ 'digit': item, 'amount': first, 'userid':uid })
        } else {
          return ({ 'digit':item, 'amount': second, 'userid':uid })
        }
      });
      return outputArray
    }
    
    const DefineDigit = (digit, result, numarray, amount, first, second, uid) => {
      if(result?.includes('R') && !result?.includes('E')){
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var firstFirst = firstSplit?.slice(0,3)
        return mixingDigit(firstFirst, first, second, uid)
      } else if(digit.includes('A')){
        return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
      } else if(digit.includes('H')){
        return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
      } else if(digit.includes('F')){
        var newFDigit = digit?.replace('F','')
        var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
        return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
      } else if(digit.includes('M')){
        var newMDigit = digit?.replace('M','')
        var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
        return digitMFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
      } else if(digit.includes('L')){
        var newLDigit = digit?.replace('L','')
        var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
        return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
      } else if(result?.includes('S')){
        var splitSDigit = result?.split('S')
        var amountS = splitSDigit?.[0]?.length === 0? 
        splitSDigit?.[1]?.slice(2) :
        splitSDigit?.[0]?.length === 1 ?
        splitSDigit?.[1]?.slice(1) :
        splitSDigit?.[1]
        var firstDigit = ''
        var secondDigit = ''
        var thirdDigit = ''
        var sDigitArr = []
        if(splitSDigit?.[0]?.length === 0){
          secondDigit = splitSDigit?.[1]?.[0]
          thirdDigit = splitSDigit?.[1]?.[1]
          sDigitArr = ['0'+secondDigit+thirdDigit, 
            '1'+secondDigit+thirdDigit,
            '2'+secondDigit+thirdDigit,
            '3'+secondDigit+thirdDigit,
            '4'+secondDigit+thirdDigit,
            '5'+secondDigit+thirdDigit,
            '6'+secondDigit+thirdDigit,
            '7'+secondDigit+thirdDigit,
            '8'+secondDigit+thirdDigit,
            '9'+secondDigit+thirdDigit,
          ]
        }else if(splitSDigit?.[0]?.length === 1){
          firstDigit = splitSDigit?.[0]
          thirdDigit = splitSDigit?.[1]?.[0]
          sDigitArr = [firstDigit+'0'+thirdDigit, 
            firstDigit+'1'+thirdDigit,
            firstDigit+'2'+thirdDigit,
            firstDigit+'3'+thirdDigit,
            firstDigit+'4'+thirdDigit,
            firstDigit+'5'+thirdDigit,
            firstDigit+'6'+thirdDigit,
            firstDigit+'7'+thirdDigit,
            firstDigit+'8'+thirdDigit,
            firstDigit+'9'+thirdDigit,
          ]
        }else {
          firstDigit = splitSDigit?.[0]?.[0]
          secondDigit = splitSDigit?.[0]?.[1]
          sDigitArr = [firstDigit+secondDigit+'0', 
            firstDigit+secondDigit+'1',
            firstDigit+secondDigit+'2',
            firstDigit+secondDigit+'3',
            firstDigit+secondDigit+'4',
            firstDigit+secondDigit+'5',
            firstDigit+secondDigit+'6',
            firstDigit+secondDigit+'7',
            firstDigit+secondDigit+'8',
            firstDigit+secondDigit+'9',
          ]
        }
        return sDigitArr.map(item => ({'digit':item,'amount':amountS, 'userid':uid}))
      } else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
            modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
          })
        } else {
          newEDigit?.forEach((digit) => {
            modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
          })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid}))
      }
      return [{'digit':digit, 'amount':amount, 'userid':uid}]
    }
  
    const useFilteredDetail = (detailAll,check) => {
      return useMemo(() => {
        if (!detailAll || detailAll.length === 0) return [];
    
        return detailAll.filter((his) => {
          return his?.buy === check;
        });
      }, [detailAll,check]);
    };
  
    const newHistory = useFilteredDetail(historyAll,'1')

    const MakeDigitArr = (newHistory) => {
      var digits = []
      newHistory?.forEach((his) => {
          var result = his?.result
          var newDigit = result?.split('R')
          var firstSplit = newDigit?.[0]
          var splitLen = defineSplitlens(result);
          var digitFormula = result?.slice(0,splitLen)
          var oneAmount = result?.slice(splitLen)
          var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.userid);
          digits?.push(digitArr)
      })
      return digits?.flat()
    }
  
    const newDetail = MakeDigitArr(newHistory)

    return (
        <div className='adminScreen'>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"ထိုးဂဏန်း ဖျက်ရန်"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                ထိုးဂဏန်းများနှင့် ဂဏန်းပမာဏကို ဖျက်ရန် သေချာလား?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>မဖျက်ပါ</Button>
            <Button onClick={() => deleteHistory(defineId)} autoFocus>
                ဖျက်မည်
            </Button>
            </DialogActions>
        </Dialog>
        <div className='adminTop'>
            <Button
            onClick={() => navigate('/home')} 
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Home
            </Button>
            <Button
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            onClick={() => navigate('/threeadminbuy', {
              state:{
                limit:getLimit,
                historyAll:location?.state?.historyAll,
                users:users
              }
            })} 
            >
            Admin buy
            </Button>
            <Button
            style={{textDecoration:'none', backgroundColor:'#2b3944',
            color:'white', textTransform:'capitalize', padding:'1px 5px'}}
            onClick={() => navigate('/search')} 
            >
            Search
            </Button>
            <Button
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            onClick={() => navigate('/threeprofitloss', {
              state:{
                limit:getLimit,
                historyAll:location?.state?.historyAll,
                users:users
              }
            })} 
            >
            Profit/Loss
            </Button>
            {/*
            <Button
            onClick={() => navigate('/monthly')} 
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Monthly
            </Button>
            */}
            <Button
            onClick={() => navigate('/threeaccount', {
              state:{
                limit:getLimit,
                historyAll:historyAll,
                users:users
              }
            })}
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Account
            </Button>
            <Button
            onClick={() => {navigate('/'); handleLogout()}}
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Log out
            </Button>
        </div>
        
        <div style={{backgroundColor:'white', marginTop:10, borderRadius:5, overflow:'auto'}}>
            <div style={{padding:10}}>
            <h4 style={{fontFamily:'mmfont'}}>ယခင်ရောင်းပီး စာရင်းများ</h4>
            <Select
            size='small'
            sx={{
                height:30,
                width:150,
                color: "black",
                '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                border:'1px solid rgba(0,0,0,0.2)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '.MuiSvgIcon-root ': {
                fill: "rgba(0,0,0,0.2) !important",
                }
            }}
            labelId="select-user"
            id="select-user"
            value={username}
            onChange={handleChange}
            >
                {
                users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                )
                }
            </Select>
           
            <div style={{padding:10, flex:'0 0 auto', paddingLeft:0}}>
            <h4 style={{fontFamily:'mmfont'}}>Agent အလိုက် ရောင်းပီးစာရင်းများ</h4>
            <p style={{fontSize:15, color:'green'}}>{delMes}</p>
            <table>
                <tbody>
                <tr style={{backgroundColor:'#6798c6', color:'white'}}>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"30px"}}>SrN</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"160px", fontFamily:'mmfont'}}>ထိုးပီး ဂဏန်းများ</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"180px", fontFamily:'mmfont'}}>ထိုးခဲ့သောအချိန်</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"100px", fontFamily:'mmfont'}}>ပြင်ဆင်ရန်</td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center', minWidth:"50px"}}>Del</td>
                </tr>
                <tr style={{backgroundColor:'#6798c6', color:'white'}}>
                    <td colSpan={5}
                    style={{
                        border:'1px solid rgba(0,0,0,0.0)', fontFamily:'textfont'
                    }}
                    >Total {`->`} {newDetail?.reduce((n, {amount}) => n + parseInt(amount), 0)}</td>
                </tr>
                {
                    newHistory?.map((history, index) => 
                    <tr>
                    <td className='number' style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                        {index+1}
                    </td>
                    <td className='number' style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                        {history?.result}
                    </td>
                    <td className='number' style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                        {history?.date?.split(' ')?.[0]?.split('T')?.[0]+' '+history?.date?.split(' ')?.[0]?.split('T')?.[1]}
                    </td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}
                    >
                        <ModeEditTwoTone
                         onClick={() => handleClickEdit(history?.id)}
                        />
                    </td>
                    <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}
                    >
                        <DeleteTwoToneIcon 
                         onClick={() => handleClickOpen(history?.id)}
                        />
                    </td>
                    </tr>
                    )
                }
                </tbody>
            </table>
            </div>
            </div>
        </div>
        </div>
    )
}

export default ThreeSearchScreen

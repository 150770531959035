import React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './SelectDateScreen.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';

function SelectDateScreen() {
    const {setLotteryDate} = useAuth()
    var currentDayPart = moment().format('A')
    const navigate = useNavigate();
    const [value, setValue] = React.useState(dayjs(moment().format('YYYY-MM-DD')));
    const [dayPart, setDayPart] = React.useState(currentDayPart);
    const handleChange = (event) => {
        setDayPart(event.target.value);
    };
    const SetDate = () => {
        setLotteryDate({date:value?.format('YYYY-MM-DD'), datePart: dayPart})
        navigate('/home')
    }
    return (
        <div style={{display:'flex', 
        justifyContent:'center', 
        height:'100vh',
        alignItems:'start'}}>
        <div className='calendar'>
            <h3 className='calendarTitle'>စာရင်းသွင်းလိုသည့် ရက်ကို ရွေးပါ</h3>
            <h3 style={{marginBottom:10, marginTop:10, padding:15}}>Select Date</h3>
            <div className='datebox'>
            <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DemoContainer
            components={['DatePicker', 'DatePicker']}>
                <DatePicker
                sx={{width:'100%'}}
                format='YYYY-MM-DD'
                label="Select date"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                />
            </DemoContainer>
            </LocalizationProvider>
            <Box sx={{ minWidth: 200 }} style={{marginTop:20}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Day Part</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dayPart}
                label="Day Part"
                onChange={handleChange}
                >
                <MenuItem value='AM'>AM</MenuItem>
                <MenuItem value='PM'>PM</MenuItem>
                </Select>
                <Button variant="contained" disableElevation
                style={{backgroundColor:'#6798c6', marginTop:20}}
                onClick={() => SetDate()}
                >
                    Next
                </Button>
            </FormControl>
            </Box>
            </div>
        </div>
        </div>
    )
}

export default SelectDateScreen
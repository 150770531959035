import React, {useEffect, useState, useMemo} from 'react'
import './HomeScreen.css';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProfitLoss.css';
import axios from 'axios';
import { mainUrl } from './url';
import moment from 'moment-timezone'
import { useAuth } from '../Auth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import html2canvas from "html2canvas";
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';

const buyProduceArr = (detailAll, day, daypart) => {
  return detailAll?.length>0 || detailAll!==null? detailAll?.filter((his) => 
  his?.date?.split('T')?.[0] === day && 
  his?.part === daypart):[]
}

const saleProduceArr = (detailAll, day, daypart) => {
  return detailAll?.length>0 || detailAll!==null? detailAll?.filter((his) => 
    his?.date?.split('T')?.[0] === day && 
  his?.part === daypart
    ):[]
}

function AdminWeeklyProfitLoss() {
  const [lucky, setLucky] = useState(null);
  const location = useLocation()
  const {selLotteryDate, authTokens, userType, handleLogout} = useAuth()
  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  const navigate = useNavigate()
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const numarray = Array(100).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '0'+String(n+i)
    } else {
      return String(n + i)
    }
  })

  const [historyAll, setHistoryAll] = useState(location?.state?.historyAll===null || undefined? []: location?.state?.historyAll)
  const [users, setUsers] = useState(location?.state?.users===null || undefined? []: location?.state?.users?.slice(0, 10))
  const [value, setValue] = useState(dayjs(moment(selDate).format('YYYY-MM-DD')))
  var finalDate = value?.format('YYYY-MM-DD')
  const [historys, setHistorys] = useState([])
  const [getLimit, setGetLimit] = useState([])

  var currentMonday = moment(finalDate).isoWeekday(1).format('YYYY-MM-DD')
  var currentTueday = moment(finalDate).isoWeekday(2).format('YYYY-MM-DD')
  var currentWedday = moment(finalDate).isoWeekday(3).format('YYYY-MM-DD')
  var currentThuday = moment(finalDate).isoWeekday(4).format('YYYY-MM-DD')
  var currentFriday = moment(finalDate).isoWeekday(5).format('YYYY-MM-DD')

  useEffect(() => {
    let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAllNoFilter&adminid='+userType?.id+'&from='+currentMonday+'&to='+currentFriday
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimit&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                        setHistorys(response?.[1]?.['data']['data']['result'])
                        var data = response?.[0]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                        setGetLimit(newdata)
                    })
                } catch (error) {
                    
                }
            })()
        }
    return () => {isSubscribed = false}
  }, [userType?.id, currentMonday, currentFriday, selDate, selDayPart])
  
  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLucky&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      }),
                    ]).then(response => {
                      setLucky(response?.[0]?.['data']['data']['result'])
                      setUsers(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id])

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 3
      } else if(data?.includes('A') ||
      data?.includes('N') ||
      data?.includes('W') ||
      data?.includes('X')
      ) {
        return 1
      } else if(data?.includes('Z')) {
        var splZ = data?.split('Z')
        var last = splZ?.[splZ?.length-1]?.length
        var finalLen = data?.length - last
        return finalLen
      } else if(data?.includes('S') ||
        data?.includes('M')) {
        var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
        return len
      } else {
        return 2
      }
    }
  }

  const DefineDigit = (digit, result, numarray, amount, first, second, uid, date, part) => {
      if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,2)
      var reverseDigit = firstFirst?.split("").reverse().join("")
      return [{'digit':firstFirst, 'amount':first, 'userid':uid, 'date':date, 'part':part},{'digit':reverseDigit, 'amount':second, 'userid':uid, 'date':date, 'part':part}]
      } else if(digit.includes('P')){
      var newPDigit = digit?.replace('P','')
      var digitinclude = numarray.filter(num => num.includes(newPDigit))
      return digitinclude.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('A')){
      return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'S'){
      var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
      return digitSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'M'){
      var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
      return digitOdd.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'SS'){
      var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
      return digitSSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'SM'){
      var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
      return digitSMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'MM'){
      var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
      return digitMMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'MS'){
      var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
      return digitMSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('B')){
      var newBDigit = digit?.replace('B','')
      var digitBFirst = numarray.filter(num => 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
          (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
          )
      return digitBFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('W')){
      return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('N')){
      return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit?.includes('X')){
      return ['01','10','12','21','23','32','34','43','45','54',
      '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit?.includes('Z')){
      if(digit?.includes('ZZ')){
          var newZZDigit = digit?.replace('ZZ','')
          var newZZArr = newZZDigit?.split('')
          return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else {
          var newZDigit = digit?.replace('Z','')
          var newZArr = newZDigit?.split('')
          return combinations(newZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      }
      }else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
              if(digit === digit?.split("").reverse().join("")){
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              } else {
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
              }
          })
        } else {
            newEDigit?.forEach((digit) => {
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid, 'date':date, 'part':part}))
      }
      return [{'digit':digit, 'amount':amount, 'userid':uid, 'date':date, 'part':part}]
  }

  function removeDuplicates(arr) {
      return arr.filter((item,index) => arr.indexOf(item) === index);
  }
  const combinationsTwo = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
      }     
      } 
      return removeDuplicates(digit)
  }

  const combinations = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
      }     
      } 
      return digit
  }

  const useFilteredDetail = (detailAll,check) => {
  return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];

      return detailAll.filter((his) => {
      return his?.buy === check;
      });
  }, [detailAll,check]);
  };

  const newHistory = useFilteredDetail(historyAll, '1');
  const newBuyHistory = useFilteredDetail(historyAll, '2');

  const MakeDigitArr = (newHistory) => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, 
          oneAmount, 
          result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), 
          result?.includes('R')? newDigit?.[1]:'0', his?.userid, his?.date, his?.part);
        digits?.push(digitArr)
    })
    return digits?.flat()
  }
  const detailAll = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)

  var mondayAm = saleProduceArr(detailAll,currentMonday, 'AM')

  var mondayPm = saleProduceArr(detailAll,currentMonday, 'PM')

  var tueDayAm = saleProduceArr(detailAll,currentTueday, 'AM')

  var tueDayPm = saleProduceArr(detailAll,currentTueday, 'PM')
  
  var wedDayAm = saleProduceArr(detailAll,currentWedday, 'AM')

  var wedDayPm = saleProduceArr(detailAll,currentWedday, 'PM')

  var thuDayAm = saleProduceArr(detailAll,currentThuday, 'AM')

  var thuDayPm = saleProduceArr(detailAll,currentThuday, 'PM')
  
  var friDayAm = saleProduceArr(detailAll,currentFriday, 'AM')

  var friDayPm = saleProduceArr(detailAll,currentFriday, 'PM')
  
  var mondayAmBuy = buyProduceArr(newBuyDetail, currentMonday,'AM')
  var mondayPmBuy = buyProduceArr(newBuyDetail, currentMonday,'PM')
  var tuedayAmBuy = buyProduceArr(newBuyDetail, currentTueday,'AM')
  var tuedayPmBuy = buyProduceArr(newBuyDetail, currentTueday,'PM')
  var weddayAmBuy = buyProduceArr(newBuyDetail, currentWedday,'AM')
  var weddayPmBuy = buyProduceArr(newBuyDetail, currentWedday,'PM')
  var thudayAmBuy = buyProduceArr(newBuyDetail, currentThuday,'AM')
  var thudayPmBuy = buyProduceArr(newBuyDetail, currentThuday,'PM')
  var fridayAmBuy = buyProduceArr(newBuyDetail, currentFriday,'AM')
  var fridayPmBuy = buyProduceArr(newBuyDetail, currentFriday,'PM')
  
  const calculateTotalWithAgent = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const calculateTotalWithAgentBuy = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const checkLucky = (date, part) => {
    return lucky?.filter((luck) => luck?.date === date && luck?.daypart === part )
  }

  const calculateTotalWithLucky = (arr, uid, date, part) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === checkLucky(date, part)?.[0]?.lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const calculateTotalWithLuckyBuy = (arr, uid, date, part) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === checkLucky(date, part)?.[0]?.lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }
  
  const SaleTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgent(mondayAm,user?.id) + calculateTotalWithAgent(mondayPm,user?.id)+
      calculateTotalWithAgent(tueDayAm,user?.id)+calculateTotalWithAgent(tueDayPm,user?.id)+
      calculateTotalWithAgent(wedDayAm,user?.id)+calculateTotalWithAgent(wedDayPm,user?.id)+
      calculateTotalWithAgent(thuDayAm,user?.id)+calculateTotalWithAgent(thuDayPm,user?.id)+
      calculateTotalWithAgent(friDayAm,user?.id)+calculateTotalWithAgent(friDayPm,user?.id)
      var lossTotal = calculateTotalWithLucky(mondayAm,user?.id, currentMonday, 'AM') + calculateTotalWithLucky(mondayPm,user?.id, currentMonday, 'PM')+
      calculateTotalWithLucky(tueDayAm,user?.id, currentTueday, 'AM')+calculateTotalWithLucky(tueDayPm,user?.id, currentTueday, 'PM')+
      calculateTotalWithLucky(wedDayAm,user?.id, currentWedday, 'AM')+calculateTotalWithLucky(wedDayPm,user?.id, currentWedday, 'PM')+
      calculateTotalWithLucky(thuDayAm,user?.id, currentThuday, 'AM')+calculateTotalWithLucky(thuDayPm,user?.id, currentThuday, 'PM')+
      calculateTotalWithLucky(friDayAm,user?.id, currentFriday, 'AM')+calculateTotalWithLucky(friDayPm,user?.id, currentFriday, 'PM')
      sumTotal?.push({amount: (saleTotal - (saleTotal/100)*parseInt(user?.commission)) - (lossTotal * parseInt(user?.rate))
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }
  
  const BuyTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgentBuy(mondayAmBuy,user?.id) + calculateTotalWithAgentBuy(mondayPmBuy,user?.id)+
      calculateTotalWithAgentBuy(tuedayAmBuy,user?.id)+calculateTotalWithAgentBuy(tuedayPmBuy,user?.id)+
      calculateTotalWithAgentBuy(weddayAmBuy,user?.id)+calculateTotalWithAgentBuy(weddayPmBuy,user?.id)+
      calculateTotalWithAgentBuy(thudayAmBuy,user?.id)+calculateTotalWithAgentBuy(thudayPmBuy,user?.id)+
      calculateTotalWithAgentBuy(fridayAmBuy,user?.id)+calculateTotalWithAgentBuy(fridayPmBuy,user?.id)
      var lossTotal = calculateTotalWithLuckyBuy(mondayAmBuy,user?.id, currentMonday, 'AM') + calculateTotalWithLuckyBuy(mondayPmBuy,user?.id, currentMonday, 'PM')+
      calculateTotalWithLuckyBuy(tuedayAmBuy,user?.id, currentTueday, 'AM')+calculateTotalWithLuckyBuy(tuedayPmBuy,user?.id, currentTueday, 'PM')+
      calculateTotalWithLuckyBuy(weddayAmBuy,user?.id, currentWedday, 'AM')+calculateTotalWithLuckyBuy(weddayPmBuy,user?.id, currentWedday, 'PM')+
      calculateTotalWithLuckyBuy(thudayAmBuy,user?.id, currentThuday, 'AM')+calculateTotalWithLuckyBuy(thudayPmBuy,user?.id, currentThuday, 'PM')+
      calculateTotalWithLuckyBuy(fridayAmBuy,user?.id, currentFriday, 'AM')+calculateTotalWithLuckyBuy(fridayPmBuy,user?.id, currentFriday, 'PM')
      sumTotal?.push({amount: (lossTotal * parseInt(user?.rate)) - (saleTotal - (saleTotal/100)*parseInt(user?.commission))
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }
  var sr = 1
  var buysr = 1

  const takeFullScreenshot = () => {
    const element = document.body; // Adjust this to the element you want to capture
    document.body.style.width = "2450px";
    html2canvas(element, {
      scale:3,
      width: document.documentElement.scrollWidth, // Full page width
      height: document.documentElement.scrollHeight, // Full page height
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
      scrollX: 0, // Start from the top left
      scrollY: 0,
      x: 0,
      y: 0,
      useCORS: true, // Handle cross-origin issues if you have external images
    }).then((canvas) => {
      document.body.style.width = "";
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "full_screenshot.png";
      link.click();
    });
  };

  return (
    <div className='adminScreen'>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/adminbuy',{
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/profitloss', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })} 
        >
          Profit/Loss
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/summary', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users:users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Summary
        </Button>
        <Button
        onClick={() => navigate('/account', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='bodyboxwhole'>
        <div className='bodybox'>
          <p style={{fontWeight:'bold'}}>Date : {selDate} {selDayPart}</p><br/>
          <p style={{fontWeight:'bold'}}>Week : From {currentMonday} to {currentFriday}</p><br/>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DemoContainer
            components={['DatePicker', 'DatePicker']}>
                <DatePicker
                sx={{width:200, height:80}}
                format='YYYY-MM-DD'
                label="Select week"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                />
            </DemoContainer>
          </LocalizationProvider>
          <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
            <h4 style={{fontFamily:'mmfont', marginBottom:10}}>ကိုယ်စားလှယ်များနှင့် စာရင်းရှင်းချက် အချုပ်</h4>
            <Button 
            style={{backgroundColor:'#6798c6', color:'white', textTransform:"capitalize", padding:'5px 15px', fontFamily:'mmfont'}}
            startIcon={<PhotoSizeSelectLargeIcon/>}
            onClick={takeFullScreenshot}>Screenshot ရယူရန်</Button>
          </div><br/>
          <h4 style={{fontFamily:'mmfont', marginBottom:10}}>အရောင်း စာရင်း (SALE)</h4>
          
          <table>
            <tbody>
              <tr style={{backgroundColor:'#2b3944', color:'white'}}>
                <td rowSpan={3} style={{textAlign:'center', width:'45px'}}>SrNo</td>
                <td rowSpan={3} style={{textAlign:'center', width:'55px'}}>Agent</td>
                <td colSpan={6} style={{textAlign:'center'}}>1 Day ({currentMonday})</td>
                <td colSpan={6} style={{textAlign:'center'}}>2 Day ({currentTueday})</td>
                <td colSpan={6} style={{textAlign:'center'}}>3 Day ({currentWedday})</td>
                <td colSpan={6} style={{textAlign:'center'}}>4 Day ({currentThuday})</td>
                <td colSpan={6} style={{textAlign:'center'}}>5 Day ({currentFriday})</td>
              </tr>
              <tr style={{backgroundColor:'#2b3944', color:'white'}}>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>1 Day (AM+PM)</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>2 Day (AM+PM)</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>3 Day (AM+PM)</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>4 Day (AM+PM)</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>5 Day (AM+PM)</td>
              </tr>
              <tr style={{backgroundColor:'#2b3944', color:'white', fontFamily:'mmfont'}}>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'110px', textAlign:'center'}}>
                  အတင်စုစုပေါင်း
                </td>
                <td style={{width:'105px', textAlign:'center'}}>
                  အထိစုစုပေါင်း
                </td>
                <td style={{width:'65px', textAlign:'center'}}>
                  Total
                </td>
              </tr>
              { users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => {
                
                var saleTotal = calculateTotalWithAgent(mondayAm,user?.id) + calculateTotalWithAgent(mondayPm,user?.id)+
                calculateTotalWithAgent(tueDayAm,user?.id)+calculateTotalWithAgent(tueDayPm,user?.id)+
                calculateTotalWithAgent(wedDayAm,user?.id)+calculateTotalWithAgent(wedDayPm,user?.id)+
                calculateTotalWithAgent(thuDayAm,user?.id)+calculateTotalWithAgent(thuDayPm,user?.id)+
                calculateTotalWithAgent(friDayAm,user?.id)+calculateTotalWithAgent(friDayPm,user?.id)

                var lossTotal = calculateTotalWithLucky(mondayAm,user?.id, currentMonday, 'AM') + calculateTotalWithLucky(mondayPm,user?.id, currentMonday, 'PM')+
                calculateTotalWithLucky(tueDayAm,user?.id, currentTueday, 'AM')+calculateTotalWithLucky(tueDayPm,user?.id, currentTueday, 'PM')+
                calculateTotalWithLucky(wedDayAm,user?.id, currentWedday, 'AM')+calculateTotalWithLucky(wedDayPm,user?.id, currentWedday, 'PM')+
                calculateTotalWithLucky(thuDayAm,user?.id, currentThuday, 'AM')+calculateTotalWithLucky(thuDayPm,user?.id, currentThuday, 'PM')+
                calculateTotalWithLucky(friDayAm,user?.id, currentFriday, 'AM')+calculateTotalWithLucky(friDayPm,user?.id, currentFriday, 'PM')
                if(saleTotal > 0){
                    return (
                      <tr key={index} className='tableRowData' style={{backgroundColor:'white', color:'black'}}>
                      <td style={{textAlign:'center'}}>
                        {sr++}
                      </td>
                      <td style={{textAlign:'left'}}>
                        {user?.username}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithAgent(mondayAm,user?.id)}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithLucky(mondayAm,user?.id, currentMonday, 'AM')}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithAgent(mondayPm,user?.id)}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithLucky(mondayPm,user?.id, currentMonday, 'PM')}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithAgent(mondayAm,user?.id) + calculateTotalWithAgent(mondayPm,user?.id)}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithLucky(mondayAm,user?.id, currentMonday, 'AM') + calculateTotalWithLucky(mondayPm,user?.id, currentMonday, 'PM')}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithAgent(tueDayAm,user?.id)}
                      </td>
                      <td style={{textAlign:'right'}}>
                        {calculateTotalWithLucky(tueDayAm,user?.id, currentTueday, 'AM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(tueDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(tueDayPm,user?.id, currentTueday, 'PM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(tueDayAm,user?.id)+calculateTotalWithAgent(tueDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(tueDayAm,user?.id, currentTueday, 'AM')+calculateTotalWithLucky(tueDayPm,user?.id, currentTueday, 'PM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(wedDayAm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(wedDayAm,user?.id, currentWedday, 'AM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(wedDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(wedDayPm,user?.id, currentWedday, 'PM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(wedDayAm,user?.id)+calculateTotalWithAgent(wedDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(wedDayAm,user?.id, currentWedday, 'AM')+calculateTotalWithLucky(wedDayPm,user?.id, currentWedday, 'PM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(thuDayAm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(thuDayAm,user?.id, currentThuday, 'AM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(thuDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(thuDayPm,user?.id, currentThuday, 'PM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(thuDayAm,user?.id)+calculateTotalWithAgent(thuDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(thuDayAm,user?.id, currentThuday, 'AM')+calculateTotalWithLucky(thuDayPm,user?.id, currentThuday, 'PM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(friDayAm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(friDayAm,user?.id, currentFriday, 'AM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(friDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(friDayPm,user?.id, currentFriday, 'PM')}
                      </td>
                      <td>
                        {calculateTotalWithAgent(friDayAm,user?.id)+calculateTotalWithAgent(friDayPm,user?.id)}
                      </td>
                      <td>
                        {calculateTotalWithLucky(friDayAm,user?.id, currentFriday, 'AM')+calculateTotalWithLucky(friDayPm,user?.id, currentFriday, 'PM')}
                      </td>
                      <td>
                        {
                          saleTotal
                        }
                      </td>
                      <td>
                        {
                          lossTotal
                        }
                      </td>
                      <td style={{color:saleTotal - (lossTotal*parseInt(user?.rate)) - ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))>=0?'black':'red'}}>
                        {
                          (saleTotal -
                          ((lossTotal) * 
                          parseInt(user?.rate))) - 
                          ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                        }
                      </td>
                    </tr>
                  )
                } 
                else {
                  return null;
                }
              })
              }
            </tbody>
          </table>
          <table style={{marginTop:5}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#6798c6', paddingLeft:30, textAlign:'left', color:'white', lineHeight:2, paddingRight:15}}>Total Sale : {
                  SaleTotal()
                }</td>
              </tr>
            </tbody>
          </table><br/>
          <h4 style={{fontFamily:'mmfont', marginBottom:10}}>ပြန်ဝယ် စာရင်း (BUY)</h4>
          <table>
            <tbody>
              <tr style={{backgroundColor:'#2b3944', color:'white'}}>
                <td rowSpan={3} style={{textAlign:'center', width:'30px'}}>SrNo</td>
                <td rowSpan={3} style={{textAlign:'center', width:'50px'}}>Agent</td>
                <td colSpan={4} style={{textAlign:'center'}}>1 Day ({currentMonday})</td>
                <td colSpan={4} style={{textAlign:'center'}}>2 Day ({currentTueday})</td>
                <td colSpan={4} style={{textAlign:'center'}}>3 Day ({currentWedday})</td>
                <td colSpan={4} style={{textAlign:'center'}}>4 Day ({currentThuday})</td>
                <td colSpan={4} style={{textAlign:'center'}}>5 Day ({currentFriday})</td>
              </tr>
              <tr style={{backgroundColor:'#2b3944', color:'white'}}>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
                <td colSpan={2} style={{textAlign:'center'}}>AM</td>
                <td colSpan={2} style={{textAlign:'center'}}>PM</td>
              </tr>
              <tr style={{backgroundColor:'#2b3944', color:'white', fontFamily:'mmfont'}}>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိ
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အတင်စုစုပေါင်း
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  အထိစုစုပေါင်း
                </td>
                <td style={{width:'45px', textAlign:'center'}}>
                  Total
                </td>
              </tr>
              { users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => {
                var saleTotal = calculateTotalWithAgentBuy(mondayAmBuy,user?.id) + calculateTotalWithAgentBuy(mondayPmBuy,user?.id)+
                calculateTotalWithAgentBuy(tuedayAmBuy,user?.id)+calculateTotalWithAgentBuy(tuedayPmBuy,user?.id)+
                calculateTotalWithAgentBuy(weddayAmBuy,user?.id)+calculateTotalWithAgentBuy(weddayPmBuy,user?.id)+
                calculateTotalWithAgentBuy(thudayAmBuy,user?.id)+calculateTotalWithAgentBuy(thudayPmBuy,user?.id)+
                calculateTotalWithAgentBuy(fridayAmBuy,user?.id)+calculateTotalWithAgentBuy(fridayPmBuy,user?.id)

                var lossTotal = calculateTotalWithLuckyBuy(mondayAmBuy,user?.id, currentMonday, 'AM') + calculateTotalWithLuckyBuy(mondayPmBuy,user?.id, currentMonday, 'PM')+
                calculateTotalWithLuckyBuy(tuedayAmBuy,user?.id, currentTueday, 'AM')+calculateTotalWithLuckyBuy(tuedayPmBuy,user?.id, currentTueday, 'PM')+
                calculateTotalWithLuckyBuy(weddayAmBuy,user?.id, currentWedday, 'AM')+calculateTotalWithLuckyBuy(weddayPmBuy,user?.id, currentWedday, 'PM')+
                calculateTotalWithLuckyBuy(thudayAmBuy,user?.id, currentThuday, 'AM')+calculateTotalWithLuckyBuy(thudayPmBuy,user?.id, currentThuday, 'PM')+
                calculateTotalWithLuckyBuy(fridayAmBuy,user?.id, currentFriday, 'AM')+calculateTotalWithLuckyBuy(fridayPmBuy,user?.id, currentFriday, 'PM')

                if(saleTotal > 0){
                  return (
                    <tr key={index} className='tableRowData' style={{backgroundColor:'white', color:'black'}}>
                    <td style={{textAlign:'center'}}>
                      {buysr++}
                    </td>
                    <td style={{textAlign:'left'}}>
                      {user?.username}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(mondayAmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(mondayAmBuy,user?.id,currentMonday,'AM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(mondayPmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(mondayPmBuy,user?.id,currentMonday,'PM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(tuedayAmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(tuedayAmBuy,user?.id, currentTueday,'AM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(tuedayPmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(tuedayPmBuy,user?.id, currentTueday,'PM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(weddayAmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(weddayAmBuy,user?.id, currentWedday,'AM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(weddayPmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(weddayPmBuy,user?.id, currentWedday,'PM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(thudayAmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(thudayAmBuy,user?.id, currentThuday, 'AM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(thudayPmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(thudayPmBuy,user?.id, currentThuday, 'PM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(fridayAmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(fridayAmBuy,user?.id, currentFriday, 'AM')}
                    </td>
                    <td>
                      {calculateTotalWithAgentBuy(fridayPmBuy,user?.id)}
                    </td>
                    <td>
                      {calculateTotalWithLuckyBuy(fridayPmBuy,user?.id, currentFriday, 'PM')}
                    </td>
                    <td>
                      {
                        saleTotal
                      }
                    </td>
                    <td>
                      {
                        lossTotal
                      }
                    </td>
                    <td style={{color:saleTotal - (lossTotal*parseInt(user?.rate)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))>=0?'red':'black'}}>
                      {
                        ((lossTotal * 
                        parseInt(user?.rate)) -
                        (saleTotal)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                      }
                    </td>
                  </tr>
                  )
                }else {
                  return null
                }
              })
              }
            </tbody>
          </table>
          <table style={{marginTop:5}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#6798c6', paddingLeft:30, textAlign:'left', color:'white', lineHeight:2, paddingRight:15}}>Total Buy : {
                  BuyTotal()
                }</td>
              </tr>
            </tbody>
          </table>
          <table style={{marginTop:1}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#2b3944', paddingLeft:30, textAlign:'left', color:'white', lineHeight:2, paddingRight:15}}>Grand Total : {
                  SaleTotal() + BuyTotal()
                }</td>
              </tr>
            </tbody>
          </table><br/>
        </div>
      </div>
    </div>
  )
}

export default AdminWeeklyProfitLoss
import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function AdminHomeInput() {
  let wonAudio = new Audio('./songs/win.wav')
  let loseAudio = new Audio('./songs/lose.wav')
  const navigate = useNavigate();
  const {authTokens, selLotteryDate, userType} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])
  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [result, setResult] = useState('')
  const [history, setHistory] = useState('')
  const [historyAll, setHistoryAll] = useState([])
  const [hotNum, setHotNum] = useState([])
  const [limitAmount, setLimitAmount] = useState('0')
  const [takeLoad, setTakeLoad] = useState(0)
  const [errorMes, setErrorMes] = useState('')
  const [success, setSuccess] = useState(false)

  var inputDate = moment().tz('Asia/Yangon').format();

  const numarray = Array(100).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '0'+String(n+i)
    } else {
      return String(n + i)
    }
  })

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 3
      } else if(data?.includes('A') ||
      data?.includes('N') ||
      data?.includes('W') ||
      data?.includes('X')
      ) {
        return 1
      } else if(data?.includes('Z')) {
        var splZ = data?.split('Z')
        var last = splZ?.[splZ?.length-1]?.length
        var finalLen = data?.length - last
        return finalLen
      } else if(data?.includes('S') ||
        data?.includes('M')) {
        var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
        return len
      } else {
        return 2
      }
    }
  }

  var splitLen = defineSplitlens(result);
  const DefineDigit = (digit, result, numarray, amount, first, second) => {
    if(result?.includes('R') && !result?.includes('E')){
    var newDigit = result?.split('R')
    var firstSplit = newDigit?.[0]
    var firstFirst = firstSplit?.slice(0,2)
    var reverseDigit = firstFirst?.split("").reverse().join("")
    return [{'digit':firstFirst, 'amount':first},{'digit':reverseDigit, 'amount':second}]
    } else if(digit.includes('P')){
    var newPDigit = digit?.replace('P','')
    var digitinclude = numarray.filter(num => num.includes(newPDigit))
    return digitinclude.map(item => ({'digit':item,'amount':amount}));
    } else if(digit.includes('A')){
    return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount}));
    } else if(digit === 'S'){
    var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
    return digitSEven.map(item => ({'digit':item,'amount':amount}));
    } else if(digit === 'M'){
    var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
    return digitOdd.map(item => ({'digit':item,'amount':amount}));
    } else if(digit === 'SS'){
    var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
    return digitSSEven.map(item => ({'digit':item,'amount':amount}));
    } else if(digit === 'SM'){
    var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
    return digitSMEven.map(item => ({'digit':item,'amount':amount}));
    } else if(digit === 'MM'){
    var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
    return digitMMEven.map(item => ({'digit':item,'amount':amount}));
    } else if(digit === 'MS'){
    var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
    return digitMSEven.map(item => ({'digit':item,'amount':amount}));
    } else if(digit.includes('F')){
    var newFDigit = digit?.replace('F','')
    var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
    return digitFFirst.map(item => ({'digit':item,'amount':amount}));
    } else if(digit.includes('L')){
    var newLDigit = digit?.replace('L','')
    var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
    return digitLFirst.map(item => ({'digit':item,'amount':amount}));
    } else if(digit.includes('B')){
    var newBDigit = digit?.replace('B','')
    var digitBFirst = numarray.filter(num => 
        (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
        (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
        (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
        )
    return digitBFirst.map(item => ({'digit':item,'amount':amount}));
    } else if(digit.includes('W')){
    return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount}));
    } else if(digit.includes('N')){
    return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount}));
    } else if(digit?.includes('X')){
    return ['01','10','12','21','23','32','34','43','45','54',
    '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount}));
    } else if(digit?.includes('Z')){
    if(digit?.includes('ZZ')){
        var newZZDigit = digit?.replace('ZZ','')
        var newZZArr = newZZDigit?.split('')
        return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount}));
    } else {
        var newZDigit = digit?.replace('Z','')
        var newZArr = newZDigit?.split('')
        return combinations(newZArr).map(item => ({'digit':item,'amount':amount}));
    }
    }else if(result?.includes('E')){
      var newEDigit = result?.split('E')?.[1]?.split(',')
      var amountE = result?.split('E')?.[2]
      var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
      var modEArr = []
      if(amountE?.includes('R')){
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
          modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
        })
      } else {
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
        })
      }
      return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount}))
    }
    return [{'digit':digit, 'amount':amount}]
  }

  function removeDuplicates(arr) {
    return arr.filter((item,index) => arr.indexOf(item) === index);
  }
  const combinationsTwo = (arr) => {
    let set = new Set(arr);
    
    arr = [...set];
    var digit = []
    
    for(let i = 0; i < arr.length - 1; i++){       
    for(let j = i + 1; j < arr.length; j++){ 
        digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
    }     
    } 
    return removeDuplicates(digit)
  }

  const combinations = (arr) => {
    let set = new Set(arr);
    
    arr = [...set];
    var digit = []
    
    for(let i = 0; i < arr.length - 1; i++){       
    for(let j = i + 1; j < arr.length; j++){ 
        digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
    }     
    } 
    return digit
  }

  const [users, setUsers] = useState([])
  const [username, setUsername] = useState('')
  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id])
  
	useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistory&userid='+username+'&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveDigitLimitUser&userid='+userType?.id+'&adminid='+userType?.adminid
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimitAllDigit&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                      }),
                    ]).then(response => {
                        setHistory(response?.[0]?.['data']['data']['result'])
                        setHotNum(response?.[1]?.['data']['data']['result'])
                        var nextdata = response?.[2]?.['data']['data']['result']
                        setLimitAmount(nextdata?.[0]?.limit_amount === undefined || nextdata===null?'0':nextdata?.[0]?.limit_amount)
                        setHistoryAll(response?.[3]?.['data']['data']['result'])
                      })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [takeLoad, currentDate, username, userType?.id, selDate, selDayPart, userType?.adminid])

  const useFilteredDetail = (detailAll) => {
    return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];
  
      return detailAll.filter((his) => {
        return his?.buy === '1';
      });
    }, [detailAll]);
  };
  const newHistory = useFilteredDetail(history);
  const newHistoryAll = useFilteredDetail(historyAll)

  const MakeDigitArr = () => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const MakeDigitArrAll = () => {
    var digits = []
    newHistoryAll?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const digitMadeArr = MakeDigitArr()
  const digitMadeArrAll = MakeDigitArrAll()

  const checkWithAgent = (uid) => {
    return users?.filter((user) => user?.id === uid)
  }

  function checkIfDuplicateExists(arr) {
    return new Set(arr).size !== arr.length
  }

  useEffect(() => {
    var len = result?.[1] === 'S' || result?.[1] === 'M'?2:1
    var checkSt = result?.slice(len)
    var amount = result?.slice(splitLen)
    var spl = result?.split('R')
    var splZ = result?.split('Z')
    var splZZ = result?.includes('ZZ')? result?.split('ZZ') : splZ
    var digits = result?.slice(0,splitLen)
    var newDigit = digits?.replace('Z','')
    var newDig = newDigit?.split('')
    if(checkSt?.includes('S') || checkSt?.includes('M')){
      reset()
    }else if(amount?.includes('S')||amount?.includes('M')||
    amount?.includes('S')||amount?.includes('M')|| amount?.includes('A')||amount?.includes('N')||
    amount?.includes('X')||amount?.includes('W')|| amount?.includes('F')||amount?.includes('B')||
    amount?.includes('P')
    ){
      reset()
    }else if(spl?.length > 2){
      reset()
    }else if(splZZ?.length > 2 || splZZ?.[1]?.includes('Z')){
      reset()
    }else if(result?.includes('Z') && checkIfDuplicateExists(newDig)) {
      reset()
    }else if(result?.includes('Z') && result?.includes('R')) {
      reset()
    }else if(result?.includes('X') && result?.includes('R')) {
      reset()
    }else if(result?.includes('X') && result?.includes('Z')) {
      reset()
    }else if(result?.includes('S') && result?.includes('R')) {
      reset()
    }else if(result?.includes('M') && result?.includes('R')) {
      reset()
    }else if(result?.includes('S') && result?.includes('Z')) {
      reset()
    }else if(result?.includes('M') && result?.includes('Z')) {
      reset()
    }else if(result?.includes('N') && result?.includes('R')) {
      reset()
    }else if(result?.includes('W') && result?.includes('R')) {
      reset()
    }else if(result?.includes('B') && result?.includes('R')) {
      reset()
    }else if(result?.includes('F') && result?.includes('R')) {
      reset()
    }else if(result?.includes('L') && result?.includes('R')) {
      reset()
    }else if(result?.includes('P') && result?.includes('R')) {
      reset()
    }else if(result?.includes('A') && result?.includes('R')) {
      reset()
    }else if(spl?.[0]?.length === 2 && result?.includes('R')){
      if(spl?.[0]?.[0] === spl?.[0]?.[1]){
        reset()
      }
    }else if(result?.includes('R') && result?.length < 3) {
      reset()
    }else if((result?.includes('E') && result?.includes('M')) || (result?.includes('E') && result?.includes('S'))) {
      reset()
    }else if(result?.[0] === 'Z' || result?.[1] === 'Z') {
      reset()
    }else if(result?.split('E').length > 3 || (result?.[0] === 'E' && result?.[1] === 'E') || (result?.[0] === 'E' && result?.[2] === 'E')){
      reset()
    }else if(result?.includes('EE')){
      reset()
    }
  },[result, splitLen])
  
  const onClick = (button) => {
    if(button === "Save") {
      SaveDigit();
    }
    else if(button === "C") {
      setErrorMes('')
      reset();
    }
    else {
      if(button === 'A' ||
      button === 'N' ||
      button === 'X' ||
      button === 'W'
      
      ) {
        setResult(button)
      } else if(button === 'S' || button === 'M') {
        setResult(result+button)
        if(result?.length > 0){
          if(result?.[0] === '1' ||
            result?.[0] === '2' ||
            result?.[0] === '3' ||
            result?.[0] === '4' ||
            result?.[0] === '5' ||
            result?.[0] === '6' ||
            result?.[0] === '7' ||
            result?.[0] === '8' ||
            result?.[0] === '9'
            ) {
              reset()
            } else {
              setResult(result+button)
          }
        }
        if(result?.length > 1){
          if(result?.[2] === '1' ||
            result?.[2] === '2' ||
            result?.[2] === '3' ||
            result?.[2] === '4' ||
            result?.[2] === '5' ||
            result?.[2] === '6' ||
            result?.[2] === '7' ||
            result?.[2] === '8' ||
            result?.[2] === '9'
            ) {
              setResult(result+button)
            } else {
              reset()
          }
        }
      } else if (button === "F" ||
        button === "L" ||
        button === "B" ||
        button === "P"
      ) {
        if(result?.length > 0) {
          if(result?.[0] === '0' ||
          result?.[0] === '1' ||
          result?.[0] === '2' ||
          result?.[0] === '3' ||
          result?.[0] === '4' ||
          result?.[0] === '5' ||
          result?.[0] === '6' ||
          result?.[0] === '7' ||
          result?.[0] === '8' ||
          result?.[0] === '9'
          ) {
            setResult(result?.[0]+button)
          } else {
            reset()
          }
        } else {
          reset()
        }
      } else if(button === 'R') {
        setResult(result+button)
      } else if(button === 'Z') {
        setResult(result+button)
      } else if(button === 'E'){
        if(result?.length > 0){
          if(result?.[0] === 'E'){
            var substringBetweenEs = result?.split('E')?.[1]
            var chunks = substringBetweenEs?.match(/\d{2}/g);
            var formattedSubstring = chunks?.join(',');
            if(formattedSubstring === undefined){
              reset()
            }else {
              var wantstring = result.replace(substringBetweenEs, formattedSubstring);
              setResult(wantstring+button)
            }
          } else {
            setResult(button)
          }
        } else {
          setResult(result+button)
        }
      }
      else {
        setResult(result+button)
      }
    }
  }
  const reset = () => {
    setResult('')
  }

  var newDigit = result?.split('R')
  var firstSplit = newDigit?.[0]

  const digitArrForResult = DefineDigit(result?.slice(0,splitLen), result, numarray, result?.slice(defineSplitlens(result)), result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');

  var overdigit = [];
  var limitAmountCheck = digitArrForResult?.some((digit) => {
    var amountcheck = Array.from(digitMadeArr?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + (parseInt(amount) || 0)), new Map()
    ), ([digit, amount]) => ({digit, amount}));
    var hotamount = hotNum !== null? Array.from(hotNum?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + (parseInt(amount) || 0)), new Map()
    ), ([digit, amount]) => ({digit, amount})):[];
    var getDigitAmount = amountcheck?.filter((getDigit) => getDigit?.digit === digit?.digit)
    
    var getHotNumber = hotamount?.filter((hot) => hot?.digit === digit?.digit)
    overdigit = [{
      hotAmount: getHotNumber?.[0]?.amount,
      digit:digit,
      digitamount: getDigitAmount?.[0]?.amount
    }]
  
    return getHotNumber?.length > 0 && hotNum !== null? 
    getHotNumber?.some((hot) => {
      var getDigit = amountcheck?.filter((a) => a?.digit === hot?.digit)
      return ((getDigit?.[0]?.amount!==undefined? getDigit?.[0]?.amount:0)+
      (parseInt(digit?.amount))) > 
      hot?.amount
    }):
    getDigitAmount?.length > 0?
    getDigitAmount?.some((sumdigit) => {
      return (
        (sumdigit?.amount + parseInt(digit?.amount)) > parseInt(checkWithAgent(username)?.[0]?.limit)
      )
    }):
    parseInt(digit?.amount) > parseInt(checkWithAgent(username)?.[0]?.limit)
  })

  const useDetailAmount = (newDetail) => {
    return useMemo(() => {
      const detailAmount = newDetail.reduce((acc, { amount, digit, ...rest }) => {
        const key = digit;
        if (!acc[key]) {
          acc[key] = { digit, ...rest, amount: 0 };
        }
        acc[key].amount += (parseInt(amount, 10) || 0);
        return acc;
      }, {});
  
      return Object.values(detailAmount);
    }, [newDetail]);
  };
  
  const detailAmount = useDetailAmount(digitMadeArrAll);

  const CheckWithDigit = (digit) => {
    return detailAmount?.filter((detail) => detail?.digit === digit) || []
  }

  var checkLimitOver = digitArrForResult?.filter((digit) => {
    return (
      (parseInt(CheckWithDigit(digit?.digit)?.[0]?.amount === undefined ? '0':CheckWithDigit(digit?.digit)?.[0]?.amount)) + parseInt(digit?.amount===undefined?'0':digit?.amount)) > parseInt(limitAmount)
  })
  
  var checkLimitCorrect = digitArrForResult?.filter((digit) => {
    return (parseInt(digit?.amount) || 0) < 1
  })

  const SaveDigit = async() => {
    if(username!==''){
    let messageUrl = mainUrl+'api.php?op=saveDigit';
      setErrorMes('')
      if(checkLimitOver?.length > 0) {
        loseAudio.play()
        setSuccess(false)
        setErrorMes(checkLimitOver?.[0]?.digit+' -> သတ်မှတ်ပမာဏ ကျော်နေပါတယ်!')
      } else {
        if(checkLimitCorrect?.length > 0 || digitArrForResult?.length < 1){
          loseAudio.play()
          setSuccess(false)
          setErrorMes('ပမာဏ မမှန်ကန်ပါ')
        } else {
          if(limitAmountCheck){
            loseAudio.play()
            setSuccess(false)
            setErrorMes(overdigit?.[0]?.digit?.digit+' (ကျန်ပမာဏ -> '+
            (overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount !== undefined? overdigit?.[0]?.hotAmount - overdigit?.[0]?.digitamount : 
            overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount === undefined? overdigit?.[0]?.hotAmount - 0:
            overdigit?.[0]?.hotAmount === undefined && overdigit?.[0]?.digitamount !== undefined? parseInt(checkWithAgent(username)?.[0]?.limit) - overdigit?.[0]?.digitamount:
            parseInt(checkWithAgent(username)?.[0]?.limit))+')')
          } else {
            await fetch(messageUrl, {
              method:'post',
              headers:{
                'Content-Type':'application/x-www-form-urlencoded'
              },
              body:"result="+result+"&userid="+username+"&date="+inputDate+"&buy=1&adminid="+userType?.id+"&part="+selDayPart
            }).then(() => {
              wonAudio.play()
              setResult('')
              setTakeLoad(takeLoad+1)
              setErrorMes(result+' သိမ်းပြီး!')
              setSuccess(true)
            })
          }
        }
      }
    } else {
      setErrorMes('Agent အရင်ရွေးပါ')
      setSuccess(false)
      loseAudio.play()
    }
  }

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])

  //var digitStatus = DigitStatus()
  var digitStatus = 'Open'

  return (
    <div className='adminScreen'>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Back
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='adminHomeScreen'>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>အမည်</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {userType?.username} (ဒိုင်)</p>
            </div>
          </div>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>ရက်စွဲ</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {selDate} {selDayPart}</p>
            </div>
            <div style={{marginLeft: 20}}>
            <Button
            style={{padding:'0px 10px', backgroundColor:'white',
              textDecoration:'none !important', color:'black', textTransform:'lowercase'}}
            onClick={() => navigate('/')}
            >
              Select Date
            </Button>
            </div>
          </div>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>Status</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {digitStatus}</p>
            </div>
          </div>
          <div className='digitscreen' style={{paddingBottom:10}}>
            <div className='topbar'>
              <p className='text'>Users</p>
            </div>
            <div style={{marginLeft: 20}}>
              <Select
                size='small'
                sx={{
                  height:30,
                  width:150,
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                    border:'2px solid white'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                }}
                labelId="select-user"
                id="select-user"
                value={username}
                onChange={handleChange}
                >
                  {
                    users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                    <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                    )
                  }
              </Select>
            </div>
          </div>
          <div style={{padding:'3px 10px 0px 10px', display:'flex'}}>
            <p>Result: </p> <p style={{color: success?'#1bbc12':'#d8005d', lineHeight:'20px', fontFamily:'mmfont'}}>&nbsp; {errorMes}</p>
          </div>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: "95%"},
            }}
            className='White'
            style={{textAlign:'center'}}
            noValidate
            autoComplete="off"
          >
          <div>
            <TextField
              size='small'
              disabled
              value={result}
              id="outlined-disabled"
              placeholder='Press any digit'
              sx={{
                  "& .MuiInputBase-root.Mui-disabled": {
                      "& > fieldset": {
                          borderColor: "rgba(0,0,0,0.1)",
                          WebkitTextFillColor: "#000000",
                      },
                      WebkitTextFillColor: "#000000",
                      fontSize:27
                  }
              }}
              InputProps={{
                sx: {
                  "&.MuiInputBase-root.Mui-disabled": { color: "unset" },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "unset",
                  },
                },
                autoComplete: 'off'
              }}
            />
          </div>
          </Box>
          <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value="C"
          style={{fontFamily:'mmfont'}}
          >
          ဖျက်မည်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "P"
          disabled={digitStatus==="Closed"}
          style={{fontFamily:'mmfont'}}
          >
          အပါ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "R"
          disabled={digitStatus==="Closed"}
          id="digitBox"
          >
          R
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "A"
          disabled={digitStatus==="Closed"}
          style={{fontFamily:'mmfont'}}
          >
          အပူး
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "7"
          disabled={digitStatus==="Closed"}
          >
          7
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "8"
          disabled={digitStatus==="Closed"}
          >
          8
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "9"
          disabled={digitStatus==="Closed"}
          >
          9
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "S"
          disabled={digitStatus==="Closed"}
          style={{fontFamily:'mmfont'}}
          >
          စုံ
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "4"
          disabled={digitStatus==="Closed"}
          >
          4
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "5"
          disabled={digitStatus==="Closed"}
          >
          5
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "6"
          disabled={digitStatus==="Closed"}
          >
          6
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "M"
          disabled={digitStatus==="Closed"}
          style={{fontFamily:'mmfont'}}
          >
          မ
          </button>
        </div>
        <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
          <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'100%'}}
          onClick={(e) => onClick(e.target.value)}
          value = "1"
          disabled={digitStatus==="Closed"}
          >
          1
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'202%', marginTop:3}}
          onClick={(e) => onClick(e.target.value)}
          value = "0"
          disabled={digitStatus==="Closed"}
          >
          0
          </button>
          </div>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "2"
          disabled={digitStatus==="Closed"}
          >
          2
          </button>
          <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'100%'}}
          onClick={(e) => onClick(e.target.value)}
          value = "3"
          disabled={digitStatus==="Closed"}
          >
          3
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'100%', marginTop:3}}
          onClick={(e) => onClick(e.target.value)}
          value = "00"
          disabled={digitStatus==="Closed"}
          >
          00
          </button>
          </div>
          <button
          //variant="contained" disableElevation
          className='buttonBox fourthBox'
          //disableRipple
          style={{backgroundColor:'#6798c6 !important', fontFamily:'mmfont'}}
          onClick={(e) => onClick(e.target.value)}
          value = "Save"
          disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
          >
          သိမ်းမည်
          </button>
        </div>
        <div className='firstrow'>
        <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "F"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ထိပ်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "L"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          နောက်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "E"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ငွေတူ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "B"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ဘရိတ်
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "W"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ပါဝါ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "N"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          နခတ်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "X"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ညီကို
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "Z"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ခွေ
          </button>
        </div>
        </div>
      </div>
    </div>
  )
}

export default AdminHomeInput

import React, {useState, useEffect} from 'react'
import './LoginScreen.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useAuth } from '../Auth';
import moment from 'moment-timezone'
import axios from 'axios';
import { mainUrl } from './url';

function LoginScreen() {
    const { setAuthTokens, setTypeUser, kind, handleLogout } = useAuth();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [fetchData, setFetchData] = useState([])
    const [checkInternet, setCheckInternet] = useState(false)
    const [passwordError, setPasswordError] = useState("")

    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            if(kind === '2d'){
                (async function(){
                    try{
                        setCheckInternet(false)
                        await axios.all([
                          axios({
                            method:'get',
                            url: mainUrl+'api.php?op=userdetail&username='+username
                          })
                        ]).then(response => {
                            setFetchData(response?.[0]?.['data']['data']['result'])
                            setCheckInternet(true)
                        })
                    } catch (error) {
                        
                    }
                })()
            } else {
                (async function(){
                    try{
                        setCheckInternet(false)
                        await axios.all([ 
                          axios({
                            method:'get',
                            url: mainUrl+'api.php?op=threeuserdetail&username='+username
                          })
                        ]).then(response => {
                            setFetchData(response?.[0]?.['data']['data']['result'])
                            setCheckInternet(true)
                        })
                    } catch (error) {
                        
                    }
                })()
            }
        }
		return () => {isSubscribed = false}
    }, [username, kind])
    
    const handleLogin = () => {
        if(checkInternet){
            if(username === "" || password === ""){
                setPasswordError('အမည် (သို့) လျှို့ဝှက်နံပါတ် ဖြည့်ပေးပါ')
            } else {
                if(fetchData?.[0]?.adminstatus === 'Open' || fetchData?.[0]?.type === 'admin' || fetchData?.[0]?.type === 'master'){
                    if(fetchData?.[0]?.status === 'Open' || fetchData?.[0]?.type === 'master'){
                        if(username === fetchData?.[0]?.username && password === fetchData?.[0]?.password){
                            setAuthTokens(moment().tz('Asia/Yangon').toJSON())
                            setTypeUser(fetchData?.[0])
                            setPasswordError('')
                            setPassword('')
                            setUsername('')
                        } else {
                            setPasswordError('အမည် (သို့) လျှို့ဝှက်နံပါတ် မှားနေပါသည်')
                        }
                    } else {
                        setPasswordError(fetchData?.[0]?.status === 'Close'?'သင့်၏အကောင့်အား ဒိုင်ပိတ်ထားပါသည်':'သင့်အကောင့် မရှိသေးပါ')
                    }
                } else {
                    setPasswordError(fetchData?.[0]?.adminstatus === 'Close'?'သင်၏ဒိုင်အကောင့်အား ပိတ်ထားပါသည်':'သင့်အကောင့် မရှိသေးပါ')
                }
            }
        } else {
            setPasswordError('အင်တာနက် လိုင်းမကောင်းပါ')
        }
    }

    return (
        <div style={{display:'flex', 
        justifyContent:'center', 
        height:'100vh',
        alignItems:'start'}}>
            <div className='Login'>
                <h3 className='login-title'>Lottery Myanmar</h3>
                <h3 className='subtitle'>Login Screen</h3>
                <p style={{marginTop:-10, marginBottom:0, padding:'0px 20px', color:'#d8005d', fontSize:14, fontFamily:'mmfont'}}>{passwordError}</p>
                <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '90%'},
                }}
                style={{textAlign:'center', marginBottom:'10px'}}
                noValidate
                autoComplete="off"
                >
                <TextField
                size='small'
                autoComplete={false}
                id="outlined-basic" variant="outlined" 
                value={username}
                placeholder='Username'
                onChange={(e) => setUsername(e.target.value)}
                />
                <TextField 
                autoComplete={false}
                size='small'
                id="outlined-basic" variant="outlined" 
                value={password}
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                />
                <Button variant="contained" disableElevation
                style={{backgroundColor:'#6798c6'}}
                onClick={() => handleLogin()}
                >
                    Login
                </Button>
                <Button variant="outlined" disableElevation
                onClick={() => handleLogout()}
                style={{fontFamily:'mmfont'}}
                >
                    နောက်သို့
                </Button>
                </Box>
            </div>
        </div>
    )
}

export default LoginScreen
import React, {useEffect, useState, useMemo} from 'react'
import './HomeScreen.css';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProfitLoss.css';
import axios from 'axios';
import { mainUrl } from './url';
import moment from 'moment-timezone'
import { useAuth } from '../Auth';

function ThreeProfitLossScreen() {
  const [lucky, setLucky] = useState(null);
  const {authTokens, userType, handleLogout, threeDate} = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const numarray = Array(1000).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '00'+String(n+i)
    }else if((n + i) >= 10 && (n + i) < 100) {
      return '0'+String(n+i)
    } 
    else {
      return String(n + i)
    }
  })

  const [historyAll, setHistoryAll] = useState(location?.state?.historyAll===null || undefined? []: location?.state?.historyAll)
  const [getLimit, setGetLimit] = useState(location?.state?.limit===null || undefined? []: location?.state?.limit)
  const [users, setUsers] = useState(location?.state?.users===null || undefined? []: location?.state?.users?.slice(0, 15))
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  useEffect(() => {
    let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryAllFilter&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
    return () => {isSubscribed = false}
  }, [currentDate, userType?.id, threeDate?.dateFrom, threeDate?.dateTo])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLucky&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLimit&adminid='+userType?.id
                      })
                    ]).then(response => {
                      var data = response?.[0]?.['data']['data']['result']
                      var newdataLuck = data?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                      setLucky(newdataLuck?.[0]?.lucky)
                      setUsers(response?.[1]?.['data']['data']['result'])
                      var dd = response?.[2]?.['data']['data']['result']
                      var newdata = dd?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                      setGetLimit(newdata)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id, threeDate?.part, threeDate?.month])

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 4
      } else if(data?.includes('A') ||
      data?.includes('H')
      ) {
        return 1
      } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
        return 2
      } 
      else {
        return 3
      }
    }
  }
  const mixingDigit = (digit, first, second, uid) => {
    var arr = digit?.split('');
    const makeDigit = [
      arr[0]+''+arr[1]+''+arr[2],
      arr[0]+''+arr[2]+''+arr[1],
      arr[1]+''+arr[0]+''+arr[2],
      arr[1]+''+arr[2]+''+arr[0],
      arr[2]+''+arr[0]+''+arr[1],
      arr[2]+''+arr[1]+''+arr[0],
    ]
    const digitArr = [...new Set(makeDigit)];
    const outputArray = digitArr.map((item, index) => {
      if(index === 0){
        return ({ 'digit': item, 'amount': first, 'userid':uid })
      } else {
        return ({ 'digit':item, 'amount': second, 'userid':uid })
      }
    });
    return outputArray
  }
  
  const DefineDigit = (digit, result, numarray, amount, first, second, uid) => {
    if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,3)
      return mixingDigit(firstFirst, first, second, uid)
    } else if(digit.includes('A')){
      return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('H')){
      return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('M')){
      var newMDigit = digit?.replace('M','')
      var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
      return digitMFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(result?.includes('S')){
      var splitSDigit = result?.split('S')
      var amountS = splitSDigit?.[0]?.length === 0? 
      splitSDigit?.[1]?.slice(2) :
      splitSDigit?.[0]?.length === 1 ?
      splitSDigit?.[1]?.slice(1) :
      splitSDigit?.[1]
      var firstDigit = ''
      var secondDigit = ''
      var thirdDigit = ''
      var sDigitArr = []
      if(splitSDigit?.[0]?.length === 0){
        secondDigit = splitSDigit?.[1]?.[0]
        thirdDigit = splitSDigit?.[1]?.[1]
        sDigitArr = ['0'+secondDigit+thirdDigit, 
          '1'+secondDigit+thirdDigit,
          '2'+secondDigit+thirdDigit,
          '3'+secondDigit+thirdDigit,
          '4'+secondDigit+thirdDigit,
          '5'+secondDigit+thirdDigit,
          '6'+secondDigit+thirdDigit,
          '7'+secondDigit+thirdDigit,
          '8'+secondDigit+thirdDigit,
          '9'+secondDigit+thirdDigit,
        ]
      }else if(splitSDigit?.[0]?.length === 1){
        firstDigit = splitSDigit?.[0]
        thirdDigit = splitSDigit?.[1]?.[0]
        sDigitArr = [firstDigit+'0'+thirdDigit, 
          firstDigit+'1'+thirdDigit,
          firstDigit+'2'+thirdDigit,
          firstDigit+'3'+thirdDigit,
          firstDigit+'4'+thirdDigit,
          firstDigit+'5'+thirdDigit,
          firstDigit+'6'+thirdDigit,
          firstDigit+'7'+thirdDigit,
          firstDigit+'8'+thirdDigit,
          firstDigit+'9'+thirdDigit,
        ]
      }else {
        firstDigit = splitSDigit?.[0]?.[0]
        secondDigit = splitSDigit?.[0]?.[1]
        sDigitArr = [firstDigit+secondDigit+'0', 
          firstDigit+secondDigit+'1',
          firstDigit+secondDigit+'2',
          firstDigit+secondDigit+'3',
          firstDigit+secondDigit+'4',
          firstDigit+secondDigit+'5',
          firstDigit+secondDigit+'6',
          firstDigit+secondDigit+'7',
          firstDigit+secondDigit+'8',
          firstDigit+secondDigit+'9',
        ]
      }
      return sDigitArr.map(item => ({'digit':item,'amount':amountS, 'userid':uid}))
    } else if(result?.includes('E')){
      var newEDigit = result?.split('E')?.[1]?.split(',')
      var amountE = result?.split('E')?.[2]
      var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
      var modEArr = []
      if(amountE?.includes('R')){
        newEDigit?.forEach((digit) => {
          modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
        })
      } else {
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
        })
      }
      return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid}))
    }
    return [{'digit':digit, 'amount':amount, 'userid':uid}]
  }

  const useFilteredDetail = (detailAll,check) => {
    return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];
  
      return detailAll.filter((his) => {
        return his?.buy === check;
      });
    }, [detailAll,check]);
  };

  const newHistory = useFilteredDetail(historyAll,'1')
  const newBuyHistory = useFilteredDetail(historyAll,'2')
  
  const MakeDigitArr = (newHistory) => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.userid);
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const newDetail = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)

  const calculateTotalWithAgent = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const calculateTotalWithAgentBuy = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const calculateTotalWithLucky = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const defineDigit = (digit) => { 
    if(digit < 10){
      return '00'+String(digit)
    }else if(digit >= 10 && digit < 100) {
      return '0'+String(digit)
    } 
    else {
      return String(digit)
    }
  }

  const mixingDigitNear = (digit) => {
    var arr = digit === undefined? []: digit?.split('');
    if(arr?.length > 0){
      return [
        arr[0]+''+arr[1]+''+arr[2],
        arr[0]+''+arr[2]+''+arr[1],
        arr[1]+''+arr[0]+''+arr[2],
        arr[1]+''+arr[2]+''+arr[0],
        arr[2]+''+arr[0]+''+arr[1],
        arr[2]+''+arr[1]+''+arr[0],
      ]
    }
    return []
  }
  
  var nearArrFull = [...new Set(mixingDigitNear(lucky))];
  var nearArr = nearArrFull?.length > 0? nearArrFull?.filter((arr) => arr !== lucky) :[]
  var upDigit = parseInt(lucky) === 999? false:true
  var downDigit = parseInt(lucky) < 1? false:true
  downDigit? nearArr?.push(defineDigit(parseInt(lucky)-1)) : nearArr?.push('999')
  upDigit ? nearArr?.push(defineDigit(parseInt(lucky)+1)) : nearArr?.push('000')

  const calculateTotalWithLuckyNear = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && (nearArr?.includes(user?.digit))
    )
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const calculateTotalWithLuckyBuy = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && user?.digit === lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }
  
  const calculateTotalWithLuckyBuyNear = (arr, uid) => {
    var searchUser = arr?.filter((user) => user?.userid === uid && (nearArr?.includes(user?.digit))
    )
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const SaleTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgent(newDetail,user?.id)
      var lossTotal = calculateTotalWithLucky(newDetail,user?.id)
      var nearTotal = calculateTotalWithLuckyNear(newDetail,user?.id)
      var commission = parseInt(user?.commission) || 0;
      var rate = parseInt(user?.rate) || 0;
      sumTotal?.push({amount: (saleTotal - (saleTotal/100)* commission) - (lossTotal * rate) - (nearTotal * 10)
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }

  /*
  const SaleTotal = () => {
    const total = useMemo(() => {
        let sum = 0;
        users?.forEach((user) => {
            if (user?.type === 'user') {
                var saleTotal = calculateTotalWithAgent(newDetail, user?.id);
                var lossTotal = calculateTotalWithLucky(newDetail, user?.id, lucky);
                var nearTotal = calculateTotalWithLuckyNear(newDetail, user?.id, nearArr);
                var commission = parseInt(user?.commission) || 0;
                var rate = parseInt(user?.rate) || 0;

                sum += (saleTotal - (saleTotal / 100) * commission) 
                        - (lossTotal * rate) 
                        - (nearTotal * 10);
            }
        });
        return sum;
    }, [nearArr]);

    return total;
  }
  */
  const BuyTotal = () => {
    var sumTotal = [];
    users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
      var saleTotal = calculateTotalWithAgentBuy(newBuyDetail, user?.id);
      var lossTotal = calculateTotalWithLuckyBuy(newBuyDetail, user?.id);
      var nearTotal = calculateTotalWithLuckyBuyNear(newBuyDetail, user?.id);
      sumTotal?.push({amount: (((lossTotal * 
        parseInt(user?.rate)) + (nearTotal*10)) -
        (saleTotal)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
      })
    })
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }
  /*
  const BuyTotal = () => {
    let totalAmount = 0;
    users?.forEach(user => {
      if (user?.type === 'user') {
        const saleTotal = calculateTotalWithAgentBuy(newBuyDetail, user?.id);
        const lossTotal = calculateTotalWithLuckyBuy(newBuyDetail, user?.id);
        const nearTotal = calculateTotalWithLuckyBuyNear(newBuyDetail, user?.id);
  
        const userAmount = ((lossTotal * parseInt(user?.rate)) + (nearTotal*10)) -
        (saleTotal + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13')));
  
        totalAmount += userAmount;
      }
    });
  
    return totalAmount;
  }
  */

  var sr = 1
  var buysr = 1

  return (
    <div className='adminScreen'>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeadminbuy', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeprofitloss')} 
        >
          Profit/Loss
        </Button>
        
        <Button
        onClick={() => navigate('/threeaccount', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='bodyboxwhole'>
        <div className='bodybox'>
          <p style={{fontWeight:'bold', fontFamily:'mmfont'}}>လက်ရှိလ : {threeDate?.month}</p><br/>
          <h4 style={{fontFamily:'mmfont'}}>ကိုယ်စားလှယ်များနှင့် စာရင်းရှင်းချက် အချုပ် (တစ်ကြိမ်စာ)</h4><br/>
          <h4 style={{fontFamily:'mmfont'}}>အရောင်း စာရင်း (SALE)</h4>
          <table>
            <tbody>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td>
                  SrNo
                </td>
                <td>
                  Agent
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အရောင်း
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ကော်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ဒဲ့ပေါက်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  တွတ်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အဆ
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  စုစုပေါင်း
                </td>
              </tr>
              {
                
                users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => {
                  var saleTotal = calculateTotalWithAgent(newDetail,user?.id)
                  var lossTotal = calculateTotalWithLucky(newDetail,user?.id)
                  var nearTotal = calculateTotalWithLuckyNear(newDetail,user?.id)
                  if(saleTotal > 0){
                    return  (
                      <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                        <td style={{textAlign:'center'}}>{sr++}</td>
                        <td style={{textAlign:'left'}}>
                          {user?.username}
                        </td>
                        <td>
                          {saleTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.commission}
                        </td>
                        <td>
                          {lossTotal}
                        </td>
                        <td>
                          {nearTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.rate}
                        </td>
                        <td style={{color:((saleTotal -
                              ((lossTotal) * 
                              parseInt(user?.rate)) - ((nearTotal) * 
                              10)) - 
                              ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13')))>=0?'black':'red'}}>
                          {
                            (saleTotal -
                              ((lossTotal) * 
                              parseInt(user?.rate)) - ((nearTotal) * 
                              10)) - 
                              ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                          }
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                    
                })
                
              }
              
              <tr className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white'}}>
                  Total
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white'}}>
                  {SaleTotal()}
                </td>
              </tr>
            </tbody>
          </table><br/>

          <h4 style={{fontFamily:'mmfont'}}>အဝယ် စာရင်း (BUY)</h4>
          <table>
            <tbody>
              <tr className='tableRow' style={{backgroundColor:'#2b3944', color:'white'}}>
                <td>
                  SrNo
                </td>
                <td>
                  Agent
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အရောင်း
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ကော်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  ဒဲ့ပေါက်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  တွတ်
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  အဆ
                </td>
                <td style={{fontFamily:'mmfont'}}>
                  စုစုပေါင်း
                </td>
              </tr>
              {
                users?.filter((user) => user?.type === 'user').map((user, index) => {
                  var saleTotal = calculateTotalWithAgentBuy(newBuyDetail,user?.id)
                  var lossTotal = calculateTotalWithLuckyBuy(newBuyDetail,user?.id)
                  var nearTotal = calculateTotalWithLuckyBuyNear(newBuyDetail,user?.id)
                  if(saleTotal > 0){
                    return  (
                      <tr key={index} className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                        <td style={{textAlign:'center'}}>{buysr++}</td>
                        <td style={{textAlign:'left'}}>
                          {user?.username}
                        </td>
                        <td>
                          {saleTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.commission}
                        </td>
                        <td>
                          {lossTotal}
                        </td>
                        <td>
                          {nearTotal}
                        </td>
                        <td style={{textAlign:'center'}}>
                          {user?.rate}
                        </td>
                        <td style={{color:(((lossTotal * 
                              parseInt(user?.rate)) + (nearTotal*10)) -
                              (saleTotal)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))>=0?'black':'red'}}>
                          {
                            (((lossTotal * 
                              parseInt(user?.rate)) + (nearTotal*10)) -
                              (saleTotal)) + ((saleTotal/100)*parseInt(user?.commission !== undefined? user?.commission: '13'))
                          }
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                    
                })
              }
              
              <tr className='tableRow tableRowData' style={{backgroundColor:'white', color:'black'}}>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td>
                  
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white'}}>
                  Total
                </td>
                <td style={{backgroundColor:'#6798c6', color:'white'}}>
                  {BuyTotal()}
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{marginTop:5}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#2b3944', textAlign:'center', color:'white', lineHeight:2, paddingRight:15}}>Grand Total (Profit/ Loss) : {SaleTotal()+(BuyTotal())}</td>
              </tr>
            </tbody>
          </table>
          <div style={{height:50}}/>
        </div>
      </div>
    </div>
  )
}

export default ThreeProfitLossScreen
import React, {useEffect, useState, useMemo} from 'react'
import './HomeScreen.css';
import { Button, Select } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProfitLoss.css';
import axios from 'axios';
import { mainUrl } from './url';
import moment from 'moment-timezone'
import { useAuth } from '../Auth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import html2canvas from "html2canvas";
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';

const buyProduceArr = (detailAll, day, daypart) => {
  return detailAll?.length>0 || detailAll!==null? detailAll?.filter((his) => 
  his?.date?.split('T')?.[0] === day && 
  his?.part === daypart):[]
}

const saleProduceArr = (detailAll, day, daypart) => {
  return detailAll?.length>0 || detailAll!==null? detailAll?.filter((his) => 
    his?.date?.split('T')?.[0] === day && 
  his?.part === daypart
    ):[]
}

function DailyScreen() {
  const [lucky, setLucky] = useState(null);
  const location = useLocation()
  const {selLotteryDate, authTokens, userType, handleLogout} = useAuth()
  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  const navigate = useNavigate()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [username, setUsername] = useState('allagent')
  const [usernameBuy, setUsernameBuy] = useState('allagent')
  const handleChange = (event) => {
    setUsername(event.target.value);
  };
  const handleChangeBuy = (event) => {
    setUsernameBuy(event.target.value);
  };

  const numarray = Array(100).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '0'+String(n+i)
    } else {
      return String(n + i)
    }
  })

  const [historyAll, setHistoryAll] = useState(location?.state?.historyAll===null || undefined? []: location?.state?.historyAll)
  const [users, setUsers] = useState(location?.state?.users===null || undefined? []: location?.state?.users?.slice(0, 10))
  const [value, setValue] = useState(dayjs(moment(selDate).format('YYYY-MM-DD')))
  var finalDate = value?.format('YYYY-MM-DD')
  const [historys, setHistorys] = useState([])
  const [getLimit, setGetLimit] = useState([])

  var currentMonday = moment(finalDate).isoWeekday(1).format('YYYY-MM-DD')
  var currentTueday = moment(finalDate).isoWeekday(2).format('YYYY-MM-DD')
  var currentWedday = moment(finalDate).isoWeekday(3).format('YYYY-MM-DD')
  var currentThuday = moment(finalDate).isoWeekday(4).format('YYYY-MM-DD')
  var currentFriday = moment(finalDate).isoWeekday(5).format('YYYY-MM-DD')

  useEffect(() => {
    let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAllNoFilter&adminid='+userType?.id+'&from='+currentMonday+'&to='+currentFriday
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimit&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                        setHistorys(response?.[1]?.['data']['data']['result'])
                        var data = response?.[0]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                        setGetLimit(newdata)
                    })
                } catch (error) {
                    
                }
            })()
        }
    return () => {isSubscribed = false}
  }, [currentMonday, currentFriday, userType?.id, selDate, selDayPart])
  
  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLucky&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      }),
                    ]).then(response => {
                      setLucky(response?.[0]?.['data']['data']['result'])
                      setUsers(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id])

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 3
      } else if(data?.includes('A') ||
      data?.includes('N') ||
      data?.includes('W') ||
      data?.includes('X')
      ) {
        return 1
      } else if(data?.includes('Z')) {
        var splZ = data?.split('Z')
        var last = splZ?.[splZ?.length-1]?.length
        var finalLen = data?.length - last
        return finalLen
      } else if(data?.includes('S') ||
        data?.includes('M')) {
        var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
        return len
      } else {
        return 2
      }
    }
  }

  const DefineDigit = (digit, result, numarray, amount, first, second, uid, date, part) => {
      if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,2)
      var reverseDigit = firstFirst?.split("").reverse().join("")
      return [{'digit':firstFirst, 'amount':first, 'userid':uid, 'date':date, 'part':part},{'digit':reverseDigit, 'amount':second, 'userid':uid, 'date':date, 'part':part}]
      } else if(digit.includes('P')){
      var newPDigit = digit?.replace('P','')
      var digitinclude = numarray.filter(num => num.includes(newPDigit))
      return digitinclude.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('A')){
      return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'S'){
      var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
      return digitSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'M'){
      var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
      return digitOdd.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'SS'){
      var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
      return digitSSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'SM'){
      var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
      return digitSMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'MM'){
      var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
      return digitMMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit === 'MS'){
      var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
      return digitMSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('B')){
      var newBDigit = digit?.replace('B','')
      var digitBFirst = numarray.filter(num => 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
          (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
          )
      return digitBFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('W')){
      return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit.includes('N')){
      return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit?.includes('X')){
      return ['01','10','12','21','23','32','34','43','45','54',
      '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else if(digit?.includes('Z')){
      if(digit?.includes('ZZ')){
          var newZZDigit = digit?.replace('ZZ','')
          var newZZArr = newZZDigit?.split('')
          return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      } else {
          var newZDigit = digit?.replace('Z','')
          var newZArr = newZDigit?.split('')
          return combinations(newZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid, 'date':date, 'part':part}));
      }
      }else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
            if(digit === digit?.split("").reverse().join("")){
            modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            } else {
            modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
            }
        })
        } else {
            newEDigit?.forEach((digit) => {
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid, 'date':date, 'part':part}))
      }
      return [{'digit':digit, 'amount':amount, 'userid':uid, 'date':date, 'part':part}]
  }

  function removeDuplicates(arr) {
      return arr.filter((item,index) => arr.indexOf(item) === index);
  }
  const combinationsTwo = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
      }     
      } 
      return removeDuplicates(digit)
  }

  const combinations = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
      }     
      } 
      return digit
  }

  const useFilteredDetail = (detailAll,check) => {
  return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];

      return detailAll.filter((his) => {
      return his?.buy === check;
      });
  }, [detailAll,check]);
  };

  const newHistory = useFilteredDetail(historyAll, '1');
  const newBuyHistory = useFilteredDetail(historyAll, '2');

  const MakeDigitArr = (newHistory) => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, 
          oneAmount, 
          result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), 
          result?.includes('R')? newDigit?.[1]:'0', his?.userid, his?.date, his?.part);
        digits?.push(digitArr)
    })
    return digits?.flat()
  }
  const detailAll = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)

  var mondayAm = saleProduceArr(detailAll,currentMonday, 'AM')

  var mondayPm = saleProduceArr(detailAll,currentMonday, 'PM')

  var tueDayAm = saleProduceArr(detailAll,currentTueday, 'AM')

  var tueDayPm = saleProduceArr(detailAll,currentTueday, 'PM')
  
  var wedDayAm = saleProduceArr(detailAll,currentWedday, 'AM')

  var wedDayPm = saleProduceArr(detailAll,currentWedday, 'PM')

  var thuDayAm = saleProduceArr(detailAll,currentThuday, 'AM')

  var thuDayPm = saleProduceArr(detailAll,currentThuday, 'PM')
  
  var friDayAm = saleProduceArr(detailAll,currentFriday, 'AM')

  var friDayPm = saleProduceArr(detailAll,currentFriday, 'PM')
  
  var mondayAmBuy = buyProduceArr(newBuyDetail, currentMonday,'AM')
  var mondayPmBuy = buyProduceArr(newBuyDetail, currentMonday,'PM')
  var tuedayAmBuy = buyProduceArr(newBuyDetail, currentTueday,'AM')
  var tuedayPmBuy = buyProduceArr(newBuyDetail, currentTueday,'PM')
  var weddayAmBuy = buyProduceArr(newBuyDetail, currentWedday,'AM')
  var weddayPmBuy = buyProduceArr(newBuyDetail, currentWedday,'PM')
  var thudayAmBuy = buyProduceArr(newBuyDetail, currentThuday,'AM')
  var thudayPmBuy = buyProduceArr(newBuyDetail, currentThuday,'PM')
  var fridayAmBuy = buyProduceArr(newBuyDetail, currentFriday,'AM')
  var fridayPmBuy = buyProduceArr(newBuyDetail, currentFriday,'PM')
  
  const calculateTotalWithAgent = (arr, uid) => {
    var searchUser = uid === 'allagent'? arr : arr?.filter((user) => user?.userid === uid)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const calculateTotalWithLucky = (arr, uid, date, part) => {
    var searchUser = uid === 'allagent'? arr?.filter((user) => user?.digit === checkLucky(date, part)?.[0]?.lucky) : 
    arr?.filter((user) => user?.userid === uid && user?.digit === checkLucky(date, part)?.[0]?.lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
    return totalAmount
  }

  const checkLucky = (date, part) => {
    return lucky?.filter((luck) => luck?.date === date && luck?.daypart === part )
  }

  const CommissionTotal = (arr, id) => {
    var sumTotal = [];
    if(id === 'allagent'){
      users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
        var saleTotal = calculateTotalWithAgent(arr, user?.id)
        sumTotal?.push({amount: (saleTotal/100)*parseInt(user?.commission)})
      })
    } else {
      users?.filter((userf) => userf?.type === 'user' & userf?.id === id)?.forEach((user) => {
        var saleTotal = calculateTotalWithAgent(arr, user?.id)
        sumTotal?.push({amount: (saleTotal/100)*parseInt(user?.commission)})
      })
    }
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }

  const LossTotal = (arr, id, date, part) => {
    var sumTotal = [];
    if(id === 'allagent'){
      users?.filter((userf) => userf?.type === 'user')?.forEach((user) => {
        var saleTotal = calculateTotalWithLucky(arr, user?.id, date, part)
        sumTotal?.push({amount: (saleTotal)*parseInt(user?.rate)})
      })
    } else {
      users?.filter((userf) => userf?.type === 'user' & userf?.id === id)?.forEach((user) => {
        var saleTotal = calculateTotalWithLucky(arr, user?.id, date, part)
        sumTotal?.push({amount: (saleTotal)*parseInt(user?.rate)})
      })
    }
    return sumTotal?.reduce((n, {amount}) => n + amount, 0)
  }

  const takeFullScreenshot = () => {
    const element = document.body; // Adjust this to the element you want to capture
    document.body.style.width = document.documentElement.scrollWidth < 1000? "1300px": document.documentElement.scrollWidth+'px';
    document.body.style.height = '100px'
    html2canvas(element, {
      scale:3,
      width: document.documentElement.scrollWidth, // Full page width
      //height: document.documentElement.scrollHeight, // Full page height
      height:290,
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
      scrollX: 0, // Start from the top left
      scrollY: 0,
      x: 0,
      y: 270,
      useCORS: true, // Handle cross-origin issues if you have external images
    }).then((canvas) => {
      document.body.style.width = "";
      document.body.style.height = "";
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "full_screenshot.png";
      link.click();
    });
  };
  return (
    <div className='adminScreen'>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/adminbuy',{
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/profitloss', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })} 
        >
          Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/weekly', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users:users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Profit/Loss
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Summary
        </Button>
        <Button
        onClick={() => navigate('/account', {
          state:{
            limit:getLimit,
            historyAll:historys,
            users: users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='bodyboxwhole'>
        <div className='bodybox'>
          <p style={{fontFamily:'textfont'}}>Date : {selDate} {selDayPart}</p><br/>
          <p style={{fontFamily:'textfont'}}>Week : From {currentMonday} to {currentFriday}</p>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DemoContainer
            components={['DatePicker', 'DatePicker']}>
                <DatePicker
                sx={{width:'174px',
                  '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                border:'1px solid rgba(0,0,0,0.2)',
                padding:0,
                },
                '.MuiOutlinedInput-input': {
                  padding:'6px 10px'
                },
                '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
                  width:'174px !important'
                }
                }}
                format='YYYY-MM-DD'
                value={value}
                onChange={(newValue) => setValue(newValue)}
                />
            </DemoContainer>
          </LocalizationProvider><br/>
          <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
            <h4 style={{fontFamily:'mmfont', marginBottom:10}}>ကိုယ်စားလှယ်များနှင့် စာရင်းရှင်းချက် အချုပ်</h4>
            <Button 
            style={{backgroundColor:'#6798c6', fontFamily:'mmfont', color:'white', textTransform:"capitalize", padding:'5px 15px'}}
            startIcon={<PhotoSizeSelectLargeIcon/>}
            onClick={takeFullScreenshot}>Screenshot ရယူရန်</Button>
          </div><br/>
          <h4 style={{fontFamily:'mmfont', marginBottom:10}}>အရောင်း စာရင်း (SALE)</h4>
          <Select
          native
          size='small'
          sx={{
            height:30,
            width:174,
            color: "black",
            '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
            border:'1px solid rgba(0,0,0,0.2)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,0.2)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,0.2)',
            },
            '.MuiSvgIcon-root ': {
            fill: "rgba(0,0,0,0.2) !important",
            }
          }}
          id="grouped-native-select"
          value={username}
          onChange={handleChange}
          >
            <option
            value="allagent"
            selected>
            All agent
            </option>
            {
            users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
            <option key={index} value={user?.id}>{user?.username}</option>
            )
            }
          </Select>
          <table style={{marginTop:10}}>
            <tbody>
            <tr className='number'>
              <td style={{minWidth:'90px !important'}}></td>
              <td style={{border:'1px solid #6798c6',
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentMonday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentTueday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentWedday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentThuday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentFriday}</td>
            </tr>
            <tr className='number'>
                <td style={{minWidth:'90px !important'}}></td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အရောင်း</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(mondayAm,username)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(mondayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(tueDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(tueDayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(wedDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(wedDayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(thuDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(thuDayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(friDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(friDayPm,username)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ကော်မရှင်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(mondayAm,username)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(mondayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(tueDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(tueDayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(wedDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(wedDayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(thuDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(thuDayPm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(friDayAm,username)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(friDayPm,username)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ဒဲ့ပေါက်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(mondayAm, username, currentMonday, 'AM')}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(mondayPm,username, currentMonday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(tueDayAm,username, currentTueday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(tueDayPm,username, currentTueday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(wedDayAm,username, currentWedday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(wedDayPm,username, currentWedday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(thuDayAm,username, currentThuday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(thuDayPm,username, currentThuday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(friDayAm,username, currentFriday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(friDayPm,username, currentFriday, 'PM')}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အချုပ်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(mondayAm,username) -
                  CommissionTotal(mondayAm,username) -
                  LossTotal(mondayAm, username, currentMonday, 'AM')) >= 0?'black':'red', 
                  padding:'2px 10px'}}>{calculateTotalWithAgent(mondayAm,username) -
                    CommissionTotal(mondayAm,username) -
                    LossTotal(mondayAm, username, currentMonday, 'AM')}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(mondayPm,username) - 
                  CommissionTotal(mondayPm,username) -
                  LossTotal(mondayPm,username, currentMonday, 'PM')) >= 0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(mondayPm,username) - 
                    CommissionTotal(mondayPm,username) -
                    LossTotal(mondayPm,username, currentMonday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(tueDayAm,username) -
                  CommissionTotal(tueDayAm,username) -
                  LossTotal(tueDayAm,username, currentTueday, 'AM')) >= 0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(tueDayAm,username) -
                    CommissionTotal(tueDayAm,username) -
                    LossTotal(tueDayAm,username, currentTueday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(tueDayPm,username) -
                  CommissionTotal(tueDayPm,username) -
                  LossTotal(tueDayPm,username, currentTueday, 'PM')) >= 0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(tueDayPm,username) -
                    CommissionTotal(tueDayPm,username) -
                    LossTotal(tueDayPm,username, currentTueday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(wedDayAm,username) - 
                  CommissionTotal(wedDayAm,username) -
                  LossTotal(wedDayAm,username, currentWedday, 'AM')) >= 0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(wedDayAm,username) - 
                    CommissionTotal(wedDayAm,username) -
                    LossTotal(wedDayAm,username, currentWedday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(wedDayPm,username) -
                  CommissionTotal(wedDayPm,username) -
                  LossTotal(wedDayPm,username, currentWedday, 'PM'))>=0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(wedDayPm,username) -
                    CommissionTotal(wedDayPm,username) -
                    LossTotal(wedDayPm,username, currentWedday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(thuDayAm,username) -
                  CommissionTotal(thuDayAm,username) -
                  LossTotal(thuDayAm,username, currentThuday, 'AM')) >= 0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(thuDayAm,username) -
                    CommissionTotal(thuDayAm,username) -
                    LossTotal(thuDayAm,username, currentThuday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(thuDayPm,username) -
                  CommissionTotal(thuDayPm,username) -
                  LossTotal(thuDayPm,username, currentThuday, 'PM')) >= 0? 'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(thuDayPm,username) -
                    CommissionTotal(thuDayPm,username) -
                    LossTotal(thuDayPm,username, currentThuday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(friDayAm,username) -
                  CommissionTotal(friDayAm,username) -
                  LossTotal(friDayAm,username, currentFriday, 'AM')) >= 0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(friDayAm,username) -
                    CommissionTotal(friDayAm,username) -
                    LossTotal(friDayAm,username, currentFriday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(calculateTotalWithAgent(friDayPm,username) -
                  CommissionTotal(friDayPm,username) -
                  LossTotal(friDayPm,username, currentFriday, 'PM'))>= 0?'black':'red', padding:'2px 10px'}}>{calculateTotalWithAgent(friDayPm,username) -
                    CommissionTotal(friDayPm,username) -
                    LossTotal(friDayPm,username, currentFriday, 'PM')}</td>
              </tr>
              <tr>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>စုစုပေါင်း</td>
                <td colSpan={3} style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'white', color:
                  ((calculateTotalWithAgent(mondayAm,username) -
                  CommissionTotal(mondayAm,username) -
                  LossTotal(mondayAm, username, currentMonday, 'AM'))+
                  (calculateTotalWithAgent(mondayPm,username) - 
                  CommissionTotal(mondayPm,username) -
                  LossTotal(mondayPm,username, currentMonday, 'PM'))+
                  (calculateTotalWithAgent(tueDayAm,username) -
                  CommissionTotal(tueDayAm,username) -
                  LossTotal(tueDayAm,username, currentTueday, 'AM'))+
                  (calculateTotalWithAgent(tueDayPm,username) -
                  CommissionTotal(tueDayPm,username) -
                  LossTotal(tueDayPm,username, currentTueday, 'PM'))+
                  (calculateTotalWithAgent(wedDayAm,username) - 
                  CommissionTotal(wedDayAm,username) -
                  LossTotal(wedDayAm,username, currentWedday, 'AM'))+
                  (calculateTotalWithAgent(wedDayPm,username) -
                  CommissionTotal(wedDayPm,username) -
                  LossTotal(wedDayPm,username, currentWedday, 'PM'))+
                  (calculateTotalWithAgent(thuDayAm,username) -
                  CommissionTotal(thuDayAm,username) -
                  LossTotal(thuDayAm,username, currentThuday, 'AM'))+
                  (calculateTotalWithAgent(thuDayPm,username) -
                  CommissionTotal(thuDayPm,username) -
                  LossTotal(thuDayPm,username, currentThuday, 'PM'))+
                  (calculateTotalWithAgent(friDayAm,username) -
                  CommissionTotal(friDayAm,username) -
                  LossTotal(friDayAm,username, currentFriday, 'AM'))+
                  (calculateTotalWithAgent(friDayPm,username) -
                  CommissionTotal(friDayPm,username) -
                  LossTotal(friDayPm,username, currentFriday, 'PM'))) >= 0? 
                  'black':'red', padding:'2px 10px', fontFamily:'mmfont', paddingTop:5, paddingBottom:0}}>
                  (တစ်ပတ်စာ) {` -> `}{
                    (calculateTotalWithAgent(mondayAm,username) -
                    CommissionTotal(mondayAm,username) -
                    LossTotal(mondayAm, username, currentMonday, 'AM'))+
                    (calculateTotalWithAgent(mondayPm,username) - 
                    CommissionTotal(mondayPm,username) -
                    LossTotal(mondayPm,username, currentMonday, 'PM'))+
                    (calculateTotalWithAgent(tueDayAm,username) -
                    CommissionTotal(tueDayAm,username) -
                    LossTotal(tueDayAm,username, currentTueday, 'AM'))+
                    (calculateTotalWithAgent(tueDayPm,username) -
                    CommissionTotal(tueDayPm,username) -
                    LossTotal(tueDayPm,username, currentTueday, 'PM'))+
                    (calculateTotalWithAgent(wedDayAm,username) - 
                    CommissionTotal(wedDayAm,username) -
                    LossTotal(wedDayAm,username, currentWedday, 'AM'))+
                    (calculateTotalWithAgent(wedDayPm,username) -
                    CommissionTotal(wedDayPm,username) -
                    LossTotal(wedDayPm,username, currentWedday, 'PM'))+
                    (calculateTotalWithAgent(thuDayAm,username) -
                    CommissionTotal(thuDayAm,username) -
                    LossTotal(thuDayAm,username, currentThuday, 'AM'))+
                    (calculateTotalWithAgent(thuDayPm,username) -
                    CommissionTotal(thuDayPm,username) -
                    LossTotal(thuDayPm,username, currentThuday, 'PM'))+
                    (calculateTotalWithAgent(friDayAm,username) -
                    CommissionTotal(friDayAm,username) -
                    LossTotal(friDayAm,username, currentFriday, 'AM'))+
                    (calculateTotalWithAgent(friDayPm,username) -
                    CommissionTotal(friDayPm,username) -
                    LossTotal(friDayPm,username, currentFriday, 'PM'))
                  }
                </td>
              </tr>
            </tbody>
          </table>
          
          <br/>
          <h4 style={{fontFamily:'mmfont', marginBottom:10}}>ပြန်ဝယ် စာရင်း (BUY)</h4>
          <Select
          native
          size='small'
          sx={{
            height:30,
            width:174,
            color: "black",
            '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
            border:'1px solid rgba(0,0,0,0.2)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,0.2)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,0.2)',
            },
            '.MuiSvgIcon-root ': {
            fill: "rgba(0,0,0,0.2) !important",
            }
          }}
          id="grouped-native-select"
          value={usernameBuy}
          onChange={handleChangeBuy}
          >
            <option
            value="allagent"
            selected>
            All agent
            </option>
            {
            users?.filter((user) => user?.type === 'user')?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
            <option key={index} value={user?.id}>{user?.username}</option>
            )
            }
          </Select>
          <table style={{marginTop:10}}>
            <tbody>
            <tr className='number'>
              <td style={{minWidth:'90px !important'}}></td>
              <td style={{border:'1px solid #6798c6',
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentMonday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentTueday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentWedday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentThuday}</td>
              <td style={{border:'1px solid #6798c6', 
                margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentFriday}</td>
            </tr>
            <tr className='number'>
                <td style={{minWidth:'90px !important'}}></td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အရောင်း</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(mondayAmBuy,usernameBuy)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(mondayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(tuedayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(tuedayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(weddayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(weddayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(thudayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(thudayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(fridayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithAgent(fridayPmBuy,usernameBuy)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ကော်မရှင်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(mondayAmBuy,usernameBuy)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(mondayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(tuedayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(tuedayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(weddayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(weddayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(thudayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(thudayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(fridayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{CommissionTotal(fridayPmBuy,usernameBuy)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ဒဲ့ပေါက်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(mondayAmBuy, usernameBuy, currentMonday, 'AM')}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(mondayPmBuy,usernameBuy, currentMonday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(tuedayAmBuy,usernameBuy, currentTueday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(tuedayPmBuy,usernameBuy, currentTueday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(weddayAmBuy,usernameBuy, currentWedday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(weddayPmBuy,usernameBuy, currentWedday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(thudayAmBuy,usernameBuy, currentThuday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(thudayPmBuy,usernameBuy, currentThuday, 'PM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(fridayAmBuy,usernameBuy, currentFriday, 'AM')}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLucky(fridayPmBuy,usernameBuy, currentFriday, 'PM')}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:'90px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အချုပ်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(mondayAmBuy, usernameBuy, currentMonday, 'AM') - calculateTotalWithAgent(mondayAmBuy,usernameBuy) - CommissionTotal(mondayAmBuy,usernameBuy)) >= 0?'black':'red', 
                  padding:'2px 10px'}}>{LossTotal(mondayAmBuy, usernameBuy, currentMonday, 'AM') +
                    CommissionTotal(mondayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(mondayAmBuy,usernameBuy)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(mondayPmBuy,usernameBuy, currentMonday, 'PM') + 
                  CommissionTotal(mondayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(mondayPmBuy,usernameBuy)) >= 0?'black':'red', padding:'2px 10px'}}>{LossTotal(mondayPmBuy,usernameBuy, currentMonday, 'PM') + 
                    CommissionTotal(mondayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(mondayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(tuedayAmBuy,usernameBuy, currentTueday, 'AM') +
                  CommissionTotal(tuedayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(tuedayAmBuy,usernameBuy)) >= 0?'black':'red', padding:'2px 10px'}}>{LossTotal(tuedayAmBuy,usernameBuy, currentTueday, 'AM') +
                    CommissionTotal(tuedayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(tuedayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(tuedayPmBuy,usernameBuy, currentTueday, 'PM') +
                  CommissionTotal(tuedayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(tuedayPmBuy,usernameBuy)) >= 0?'black':'red', padding:'2px 10px'}}>{LossTotal(tuedayPmBuy,usernameBuy, currentTueday, 'PM') +
                    CommissionTotal(tuedayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(tuedayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(weddayAmBuy,usernameBuy, currentWedday, 'AM') + 
                  CommissionTotal(weddayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(weddayAmBuy,usernameBuy)) >= 0?'black':'red', padding:'2px 10px'}}>{LossTotal(weddayAmBuy,usernameBuy, currentWedday, 'AM') +
                    CommissionTotal(weddayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(weddayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(weddayPmBuy,usernameBuy, currentWedday, 'PM') +
                  CommissionTotal(weddayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(weddayPmBuy,usernameBuy))>=0?'black':'red', padding:'2px 10px'}}>{LossTotal(weddayPmBuy,usernameBuy, currentWedday, 'PM') +
                    CommissionTotal(weddayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(weddayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(thudayAmBuy,usernameBuy, currentThuday, 'AM') +
                  CommissionTotal(thudayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(thudayAmBuy,usernameBuy)) >= 0?'black':'red', padding:'2px 10px'}}>{LossTotal(thudayAmBuy,usernameBuy, currentThuday, 'AM') +
                    CommissionTotal(thudayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(thudayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(thudayPmBuy,usernameBuy, currentThuday, 'PM') +
                  CommissionTotal(thudayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(thudayPmBuy,usernameBuy)) >= 0? 'black':'red', padding:'2px 10px'}}>{LossTotal(thudayPmBuy,usernameBuy, currentThuday, 'PM') +
                    CommissionTotal(thudayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(thudayPmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(fridayAmBuy,usernameBuy, currentFriday, 'AM') +
                  CommissionTotal(fridayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(fridayAmBuy,usernameBuy)) >= 0?'black':'red', padding:'2px 10px'}}>{LossTotal(fridayAmBuy,usernameBuy, currentFriday, 'AM') +
                    CommissionTotal(fridayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(fridayAmBuy,usernameBuy)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right', width:'90px',
                  margin:1, color:(LossTotal(fridayPmBuy,usernameBuy, currentFriday, 'PM') +
                  CommissionTotal(fridayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(fridayPmBuy,usernameBuy))>= 0?'black':'red', padding:'2px 10px'}}>{LossTotal(fridayPmBuy,usernameBuy, currentFriday, 'PM') +
                    CommissionTotal(fridayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(fridayPmBuy,usernameBuy)}</td>
              </tr>
              <tr>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>စုစုပေါင်း</td>
                <td colSpan={3} style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'white', color:
                  (
                    (LossTotal(mondayAmBuy, usernameBuy, currentMonday, 'AM') +
                    CommissionTotal(mondayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(mondayAmBuy,usernameBuy))+
                    (LossTotal(mondayPmBuy,usernameBuy, currentMonday, 'PM') + 
                    CommissionTotal(mondayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(mondayPmBuy,usernameBuy))+
                    (LossTotal(tuedayAmBuy,usernameBuy, currentTueday, 'AM') +
                    CommissionTotal(tuedayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(tuedayAmBuy,usernameBuy))+
                    (LossTotal(tuedayPmBuy,usernameBuy, currentTueday, 'PM') +
                    CommissionTotal(tuedayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(tuedayPmBuy,usernameBuy))+
                    (LossTotal(weddayAmBuy,usernameBuy, currentWedday, 'AM') + 
                    CommissionTotal(weddayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(weddayAmBuy,usernameBuy))+
                    (LossTotal(weddayPmBuy,usernameBuy, currentWedday, 'PM') +
                    CommissionTotal(weddayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(weddayPmBuy,usernameBuy))+
                    (LossTotal(thudayAmBuy,usernameBuy, currentThuday, 'AM') +
                    CommissionTotal(thudayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(thudayAmBuy,usernameBuy))+
                    (LossTotal(thudayPmBuy,usernameBuy, currentThuday, 'PM') +
                    CommissionTotal(thudayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(thudayPmBuy,usernameBuy))+
                    (LossTotal(fridayAmBuy,usernameBuy, currentFriday, 'AM') +
                    CommissionTotal(fridayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(fridayAmBuy,usernameBuy))+
                    (LossTotal(fridayPmBuy,usernameBuy, currentFriday, 'PM') +
                    CommissionTotal(fridayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(fridayPmBuy,usernameBuy))) >= 0?
                  'black':'red', padding:'2px 10px', fontFamily:'mmfont', paddingTop:5, paddingBottom:0}}>
                  (တစ်ပတ်စာ) {` -> `}{
                    (LossTotal(mondayAmBuy, usernameBuy, currentMonday, 'AM') +
                    CommissionTotal(mondayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(mondayAmBuy,usernameBuy))+
                    (LossTotal(mondayPmBuy,usernameBuy, currentMonday, 'PM') +
                    CommissionTotal(mondayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(mondayPmBuy,usernameBuy))+
                    (LossTotal(tuedayAmBuy,usernameBuy, currentTueday, 'AM') +
                    CommissionTotal(tuedayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(tuedayAmBuy,usernameBuy))+
                    (LossTotal(tuedayPmBuy,usernameBuy, currentTueday, 'PM') +
                    CommissionTotal(tuedayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(tuedayPmBuy,usernameBuy))+
                    (LossTotal(weddayAmBuy,usernameBuy, currentWedday, 'AM') +
                    CommissionTotal(weddayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(weddayAmBuy,usernameBuy))+
                    (LossTotal(weddayPmBuy,usernameBuy, currentWedday, 'PM') +
                    CommissionTotal(weddayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(weddayPmBuy,usernameBuy))+
                    (LossTotal(thudayAmBuy,usernameBuy, currentThuday, 'AM') +
                    CommissionTotal(thudayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(thudayAmBuy,usernameBuy))+
                    (LossTotal(thudayPmBuy,usernameBuy, currentThuday, 'PM') +
                    CommissionTotal(thudayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(thudayPmBuy,usernameBuy))+
                    (LossTotal(fridayAmBuy,usernameBuy, currentFriday, 'AM') +
                    CommissionTotal(fridayAmBuy,usernameBuy) -
                    calculateTotalWithAgent(fridayAmBuy,usernameBuy))+
                    (LossTotal(fridayPmBuy,usernameBuy, currentFriday, 'PM') +
                    CommissionTotal(fridayPmBuy,usernameBuy) -
                    calculateTotalWithAgent(fridayPmBuy,usernameBuy))
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{marginTop:1}}>
            <tbody>
              <tr>
                <td style={{backgroundColor:'#2b3944', paddingLeft:30, textAlign:'left', color:'white', lineHeight:2, paddingRight:15}}>Grand Total : {
                  (calculateTotalWithAgent(mondayAm,username) -
                  CommissionTotal(mondayAm,username) -
                  LossTotal(mondayAm, username, currentMonday, 'AM'))+
                  (calculateTotalWithAgent(mondayPm,username) - 
                  CommissionTotal(mondayPm,username) -
                  LossTotal(mondayPm,username, currentMonday, 'PM'))+
                  (calculateTotalWithAgent(tueDayAm,username) -
                  CommissionTotal(tueDayAm,username) -
                  LossTotal(tueDayAm,username, currentTueday, 'AM'))+
                  (calculateTotalWithAgent(tueDayPm,username) -
                  CommissionTotal(tueDayPm,username) -
                  LossTotal(tueDayPm,username, currentTueday, 'PM'))+
                  (calculateTotalWithAgent(wedDayAm,username) - 
                  CommissionTotal(wedDayAm,username) -
                  LossTotal(wedDayAm,username, currentWedday, 'AM'))+
                  (calculateTotalWithAgent(wedDayPm,username) -
                  CommissionTotal(wedDayPm,username) -
                  LossTotal(wedDayPm,username, currentWedday, 'PM'))+
                  (calculateTotalWithAgent(thuDayAm,username) -
                  CommissionTotal(thuDayAm,username) -
                  LossTotal(thuDayAm,username, currentThuday, 'AM'))+
                  (calculateTotalWithAgent(thuDayPm,username) -
                  CommissionTotal(thuDayPm,username) -
                  LossTotal(thuDayPm,username, currentThuday, 'PM'))+
                  (calculateTotalWithAgent(friDayAm,username) -
                  CommissionTotal(friDayAm,username) -
                  LossTotal(friDayAm,username, currentFriday, 'AM'))+
                  (calculateTotalWithAgent(friDayPm,username) -
                  CommissionTotal(friDayPm,username) -
                  LossTotal(friDayPm,username, currentFriday, 'PM')) 
                  + 
                  (LossTotal(mondayAmBuy, usernameBuy, currentMonday, 'AM') +
                  CommissionTotal(mondayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(mondayAmBuy,usernameBuy))+
                  (LossTotal(mondayPmBuy,usernameBuy, currentMonday, 'PM') +
                  CommissionTotal(mondayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(mondayPmBuy,usernameBuy))+
                  (LossTotal(tuedayAmBuy,usernameBuy, currentTueday, 'AM') +
                  CommissionTotal(tuedayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(tuedayAmBuy,usernameBuy))+
                  (LossTotal(tuedayPmBuy,usernameBuy, currentTueday, 'PM') +
                  CommissionTotal(tuedayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(tuedayPmBuy,usernameBuy))+
                  (LossTotal(weddayAmBuy,usernameBuy, currentWedday, 'AM') +
                  CommissionTotal(weddayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(weddayAmBuy,usernameBuy))+
                  (LossTotal(weddayPmBuy,usernameBuy, currentWedday, 'PM') +
                  CommissionTotal(weddayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(weddayPmBuy,usernameBuy))+
                  (LossTotal(thudayAmBuy,usernameBuy, currentThuday, 'AM') +
                  CommissionTotal(thudayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(thudayAmBuy,usernameBuy))+
                  (LossTotal(thudayPmBuy,usernameBuy, currentThuday, 'PM') +
                  CommissionTotal(thudayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(thudayPmBuy,usernameBuy))+
                  (LossTotal(fridayAmBuy,usernameBuy, currentFriday, 'AM') +
                  CommissionTotal(fridayAmBuy,usernameBuy) -
                  calculateTotalWithAgent(fridayAmBuy,usernameBuy))+
                  (LossTotal(fridayPmBuy,usernameBuy, currentFriday, 'PM') +
                  CommissionTotal(fridayPmBuy,usernameBuy) -
                  calculateTotalWithAgent(fridayPmBuy,usernameBuy))
                }</td>
              </tr>
            </tbody>
          </table><br/>
        </div>
      </div>
    </div>
  )
}

export default DailyScreen
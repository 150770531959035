import { Box, TextField, Button } from '@mui/material'
import React, {useEffect, useState} from 'react'
import { mainUrl } from './url'
import { useAuth } from '../Auth';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AdminLimitInput() {
    const {userType} = useAuth()
    const [limitLoad, setLimitLoad] = useState(0)
    const [limitAll, setLimitAll] = useState([])
    const [limitAmount, setLimitAmount] = useState('0')
    var inputDate = moment().tz('Asia/Yangon').format();
    const navigate = useNavigate();

    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimitAllDigit&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setLimitAll(response?.[0]?.['data']['data']['result'])
                        var data = response?.[0]?.['data']['data']['result']
                        setLimitAmount(data?.[0]?.limit_amount === undefined || data===null?'0':data?.[0]?.limit_amount)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [limitLoad, userType?.id])
    const saveLimit = async() => {
        let saveLimitServer = mainUrl+'api.php?op=saveLimitAllDigit';
        let updateUrl = mainUrl+'api.php?op=updateLimitAllDigit';
        if(limitAll?.length > 0){
            if(limitAmount !== limitAll?.[0]?.limit_amount){
                await fetch(updateUrl, {
                  method:'post',
                  headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                  },
                  body:"limit="+limitAmount+"&id="+limitAll?.[0]?.id
                }).then(() => {
                    setLimitLoad(limitLoad+1)
                })
            }
        } else {
            await fetch(saveLimitServer, {
            method:'post',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:"limit="+limitAmount+"&adminid="+userType?.id+"&date="+inputDate
            }).then(() => {
                setLimitLoad(limitLoad+1)
            })
        }
    }
    return (
        <div style={{padding:10}}>
            <h4 style={{fontFamily:'mmfont', marginBottom:10}}>အကွက်များကို အများဆုံးပမာဏ သတ်မှတ်ရန်</h4>
            <div style={{display:'flex', marginTop:5}}>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 0, width: '100%', padding:0},
                }}
                >
                <TextField
                InputProps={{
                    style: {
                    height:30,
                    padding: '0 0px !important',
                    fontSize:15,
                    fontFamily:'textfont'
                    },
                }}
                size='small'
                id="limit-amount"
                value={limitAmount}
                placeholder='ပမာဏ'
                onChange={(e) => setLimitAmount(e.target.value)}
                />
            </Box>
            <Button
            size='small'
            style={{
            textDecoration:'none',
            textTransform:'capitalize',
            backgroundColor:'#6798c6',
            color:'white',
            marginLeft:5,
            }}
            onClick={() => saveLimit()}
            >
            Save
            </Button>
            </div>
            <br/>
            <div>
            <h4 style={{fontFamily:'mmfont', marginBottom:10}}>ဒိုင်အရောင်းစာရင်းသွင်းရန်</h4>
            <Button
            size='small'
            style={{
            marginTop:5,
            fontSize:16,
            textDecoration:'none',
            textTransform:'capitalize',
            backgroundColor:'#6798c6',
            color:'white',
            fontFamily:'mmfont'
            }}
            onClick={() => navigate('/admininput')} 
            >
            စာရင်းသွင်းရန်နှိပ်ပါ
            </Button>
            </div>
        </div>
    )
}

export default AdminLimitInput
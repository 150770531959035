import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import OverAmountTable from './ThreeOverAmountTable';

function AdminBuyScreen() {
  const navigate = useNavigate();
  const location = useLocation()
  const {authTokens, selLotteryDate, userType, handleLogout} = useAuth()
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])
  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [historyAll, setHistoryAll] = useState(location?.state?.historyAll===null || undefined? []: location?.state?.historyAll)
  const [getLimit, setGetLimit] = useState(location?.state?.limit===null || undefined? []: location?.state?.limit)

  const numarray = Array(100).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '0'+String(n+i)
    } else {
      return String(n + i)
    }
  })

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 3
      } else if(data?.includes('A') ||
      data?.includes('N') ||
      data?.includes('W') ||
      data?.includes('X')
      ) {
        return 1
      } else if(data?.includes('Z')) {
        var splZ = data?.split('Z')
        var last = splZ?.[splZ?.length-1]?.length
        var finalLen = data?.length - last
        return finalLen
      } else if(data?.includes('S') ||
        data?.includes('M')) {
        var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
        return len
      } else {
        return 2
      }
    }
  }

  const DefineDigit = (digit, result, numarray, amount, first, second, uid) => {
      if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,2)
      var reverseDigit = firstFirst?.split("").reverse().join("")
      return [{'digit':firstFirst, 'amount':first, 'userid':uid},{'digit':reverseDigit, 'amount':second, 'userid':uid}]
      } else if(digit.includes('P')){
      var newPDigit = digit?.replace('P','')
      var digitinclude = numarray.filter(num => num.includes(newPDigit))
      return digitinclude.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('A')){
      return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'S'){
      var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
      return digitSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'M'){
      var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
      return digitOdd.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'SS'){
      var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
      return digitSSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'SM'){
      var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
      return digitSMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'MM'){
      var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
      return digitMMEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit === 'MS'){
      var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
      return digitMSEven.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('B')){
      var newBDigit = digit?.replace('B','')
      var digitFirst = numarray.filter(num => 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
          (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
          (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
          )
      return digitFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('W')){
      return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit.includes('N')){
      return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else if(digit?.includes('X')){
      return ['01','10','12','21','23','32','34','43','45','54',
      '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount, 'userid':uid}));
      } else if(digit?.includes('Z')){
      if(digit?.includes('ZZ')){
          var newZZDigit = digit?.replace('ZZ','')
          var newZZArr = newZZDigit?.split('')
          return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      } else {
          var newZDigit = digit?.replace('Z','')
          var newZArr = newZDigit?.split('')
          return combinations(newZArr).map(item => ({'digit':item,'amount':amount, 'userid':uid}));
      }
      }else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
              if(digit === digit?.split("").reverse().join("")){
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              } else {
              modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
              }
          })
        } else {
            newEDigit?.forEach((digit) => {
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid}))
      }
      return [{'digit':digit, 'amount':amount, 'userid':uid}]
  }

  function removeDuplicates(arr) {
      return arr.filter((item,index) => arr.indexOf(item) === index);
  }
  const combinationsTwo = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
      }     
      } 
      return removeDuplicates(digit)
  }

  const combinations = (arr) => {
      let set = new Set(arr);
      
      arr = [...set];
      var digit = []
      
      for(let i = 0; i < arr.length - 1; i++){       
      for(let j = i + 1; j < arr.length; j++){ 
          digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
      }     
      } 
      return digit
  }

  const useFilteredDetail = (detailAll,check) => {
  return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];

      return detailAll.filter((his) => {
      return his?.buy === check;
      });
  }, [detailAll, check]);
  };
  const newHistory = useFilteredDetail(historyAll, '1');
  const newBuyHistory = useFilteredDetail(historyAll, '2');
  const MakeDigitArr = (newHistory) => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.userid);
        digits?.push(digitArr)
    })
    return digits?.flat()
  }
  const newDetail = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)

  const buyDetailAmount = useMemo(() => {
    if (!newBuyDetail || newBuyDetail.length === 0) return [];
    
    const map = newBuyDetail.reduce((m, { digit, amount }) => {
      const parsedBuy = parseInt(amount, 10) || 0;
      if (!isNaN(parsedBuy)) {
        m.set(digit, (m.get(digit) || 0) + parsedBuy);
      }
      return m;
    }, new Map());
    return Array.from(map, ([digit, amount]) => ({ digit, amount }));
  }, [newBuyDetail]);

  const detailAmount = useMemo(() => {
    if (!newDetail || newDetail.length === 0) return [];
    
    const map = newDetail.reduce((m, { digit, amount }) => {
      const parsedBuy = parseInt(amount, 10) || 0;
      if (!isNaN(parsedBuy)) {
        m.set(digit, (m.get(digit) || 0) + parsedBuy);
      }
      return m;
    }, new Map());
    return Array.from(map, ([digit, amount]) => ({ digit, amount }));
  }, [newDetail]);

  const outAmountArr = detailAmount?.map(item=>({...item, 'buy':0}))
  const outBuyArr = buyDetailAmount?.map(item => ({
    'digit':item.digit,
    'amount':0,
    'buy':item.amount
  }))

  const [users, setUsers] = useState([])
  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimit&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        var data = response?.[0]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                        setGetLimit(newdata)
                        setUsers(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id, selDate, selDayPart])
  
	useEffect(() => {
		let isSubscribed = true;
      if(isSubscribed){
          (async function(){
              try{
                  await axios.all([
                    axios({
                      method:'get',
                      url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.id+'&date='+selDate+'&part='+selDayPart
                    }),
                  ]).then(response => {
                      setHistoryAll(response?.[0]?.['data']['data']['result'])
                  })
              } catch (error) {
                  
              }
          })()
      }
  return () => {isSubscribed = false}
  }, [currentDate, userType?.id, selDate, selDayPart])

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])

  var limitOverDigit = outAmountArr?.filter((amount) => amount?.amount > parseInt(getLimit?.[0]?.limit!==undefined?getLimit?.[0]?.limit:'0'))

  var finalCombineArr = limitOverDigit.concat(outBuyArr)

  const combineAmountBoth = Object.values((finalCombineArr || []).reduce((acc, { digit, amount, buy, ...r }) => {
    const key = Object.entries(digit).join('-');
    if (!acc[key]) {
      acc[key] = { digit, amount: 0, buy: 0, ...r };
    }
    acc[key].amount += parseInt(amount);
    acc[key].buy += parseInt(buy);
    return acc;
  }, {}));
  
  var sumTotal = []
  return (
    <div className='adminScreen'>
      
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/adminbuy')} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search')} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/profitloss', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/weekly', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/summary',{
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users: users
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Weekly Summary
        </Button>
        <Button
        onClick={() => navigate('/account', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users: users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='thirdBox'>
          <div>
          <h4 style={{fontFamily:'mmfont'}}>ဒိုင်အဝယ်စာရင်းသွင်းရန်</h4>
            <Button
            size='small'
            style={{
            marginTop:5,
            fontSize:16,
            textDecoration:'none',
            textTransform:'capitalize',
            backgroundColor:'#6798c6',
            color:'white',
            fontFamily:'mmfont'
            }}
            onClick={() => navigate('/buyinput')} 
            >
            စာရင်းသွင်းရန်နှိပ်ပါ
            </Button>
          </div>
          <div style={{height:0.5, width:'98%', backgroundColor:'rgba(0,0,0,0.09)', marginLeft:'auto', marginRight:'auto', marginTop:10, marginBottom:10}}/>
          <h4 style={{fontFamily:'mmfont'}}>ခေါင်းကျော်နေသော အကွက်များ</h4>
          <OverAmountTable
          combineAmountBoth={combineAmountBoth}
          getLimit={getLimit}
          sumTotal={sumTotal}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminBuyScreen

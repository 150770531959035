import React, {useMemo} from 'react'
import './OverAmountTable.css';

function OverAmountTable({ combineAmountBoth, getLimit, sumTotal }) {
    const limit = parseInt(getLimit?.[0]?.limit !== undefined ? getLimit?.[0]?.limit : '0');

    const sortedData = useMemo(() => {
        return combineAmountBoth?.sort((a, b) => parseInt(a.digit) - parseInt(b.digit));
    }, [combineAmountBoth]);

    const totalValues = useMemo(() => {
        return sortedData?.reduce(
        (acc, data) => {
            const amount = parseInt(data?.amount);
            const buy = parseInt(data?.buy);
            const over = amount > limit ? amount - limit : 0;
            const last = over - buy > 0 ? over - buy : 0;

            sumTotal.push({ last, over, buy });

            acc.totalLast += last;
            acc.totalOver += over;
            acc.totalBuy += buy;

            return acc;
        },
        { totalLast: 0, totalOver: 0, totalBuy: 0 }
        );
    }, [sortedData, limit, sumTotal]);
    return (
        <div>
        <table>
            <thead>
            <tr>
                <td className="header-cell">Num</td>
                <td className="header-cell">Last.Over</td>
                <td className="header-cell">Over</td>
                <td className="header-cell">Buy</td>
            </tr>
            </thead>
            <tbody>
            {sortedData?.map((data, index) => {
                const amount = parseInt(data?.amount);
                const buy = parseInt(data?.buy);
                const over = amount > limit ? amount - limit : 0;
                const last = over - buy > 0 ? over - buy : 0;

                return (
                <tr key={index}>
                    <td className="data-cell data-digit number">{data?.digit}</td>
                    <td className="data-cell number">{last}</td>
                    <td className="data-cell number">{over}</td>
                    <td className="data-cell number">{buy}</td>
                </tr>
                );
            })}
            {sortedData?.length ? (
                <tr>
                <td className="total-cell number">Total</td>
                <td className="data-cell number" style={{backgroundColor:'rgba(117, 172, 212, 0.3)'}}>{totalValues.totalLast}</td>
                <td className="data-cell number" style={{backgroundColor:'rgba(117, 172, 212, 0.3)'}}>{totalValues.totalOver}</td>
                <td className="data-cell number" style={{backgroundColor:'rgba(117, 172, 212, 0.3)'}}>{totalValues.totalBuy}</td>
                </tr>
            ) : null}
            </tbody>
        </table>
        </div>
    )
}

export default OverAmountTable
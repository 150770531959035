import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './SelectDateScreen.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { mainUrl } from './url';
import axios from 'axios';

function ThreeAgentSelectDateScreen() {
    const {setThreeDDate, userType} = useAuth()
    const navigate = useNavigate();
    const [openAll, setOpenAll] = useState([])
    const month = parseInt(moment().format('D')) < 18 && parseInt(moment().format('D')) > 3? dayjs(moment().format('YYYY-MM')) : 
    parseInt(moment().format('D')) >= 18 && parseInt(moment().format('D')) <= 31? dayjs(moment().format('YYYY-MM')):
    parseInt(moment().format('D')) <=3?
    dayjs(moment().subtract(1,'months').format('YYYY-MM')) : dayjs(moment().format('YYYY-MM'));
    const dayPart = parseInt(moment().format('D')) < 18 && parseInt(moment().format('D')) > 3? 'first' : 
    parseInt(moment().format('D')) >= 18 && parseInt(moment().format('D')) <= 31? 'second':
    parseInt(moment().format('D')) <=3?
    'second' : 'first';
    const [start, setStart] = useState(dayjs(dayPart==='first'?month?.format('YYYY-MM')+'-04':month?.format('YYYY-MM')+'-18'))
    const [close, setClose] = useState(dayjs(dayPart==='first'?month?.format('YYYY-MM')+'-16':moment().add(1,'months').format('YYYY-MM')+'-01'))
    const [inputMonth, setInputMonth] = useState('')
    const [inputPart, setInputPart] = useState('')
    const SetDate = () => {
        setThreeDDate({dateFrom:start?.format('YYYY-MM-DD'), dateTo: close?.format('YYYY-MM-DD'), part: inputPart===undefined?'first':inputPart, month: inputMonth?.format('YYYY-MM')})
        navigate('/home')
    }
    
    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getThreeOpen&adminid='+userType?.adminid+'&part='+dayPart+'&month='+month?.format('YYYY-MM')
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getThreeOpenAll&adminid='+userType?.adminid
                      })
                    ]).then(response => {
                        var openDate = response?.[0]?.['data']['data']['result']
                        var openAll = response?.[1]?.['data']['data']['result']
                        setStart(openDate?.[0]?.date_from === undefined?dayjs(openAll?.[0].date_from === undefined?
                            dayPart==='first'?month?.format('YYYY-MM')+'-04':month?.format('YYYY-MM')+'-18' : openAll?.[0].date_from
                        )
                        : dayjs(openDate?.[0].date_from))
                        setClose(openDate?.[0]?.date_to === undefined?dayjs(openAll?.[0].date_to === undefined?
                            dayPart==='first'?month?.format('YYYY-MM')+'-16':moment().add(1,'months').format('YYYY-MM')+'-01' : openAll?.[0].date_to
                        ): dayjs(openDate?.[0].date_to))
                        setInputMonth(openDate?.[0]?.month === undefined?dayjs(openAll?.[0].month):dayjs(openDate?.[0]?.month))
                        setInputPart(openDate?.[0]?.part === undefined?openAll?.[0]?.part:openDate?.[0]?.part)
                        setOpenAll(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [userType?.adminid, dayPart, month])
    
    return (
        <div style={{display:'flex', 
        justifyContent:'center', 
        height:'100vh',
        alignItems:'start'}}>
        <div className='calendar'>
            <h3 className='calendarTitle'>စာရင်းသွင်းလိုသည့် ရက်ကို ရွေးပါ</h3>
            <h3 style={{marginTop:10, padding:15, paddingBottom:0, fontFamily:'mmfont'}}>စာရင်းစဖွင့်ထားသည့်လ</h3>
            <div className='datebox' style={{display:'flex', flexDirection:'row'}}>
            <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DemoContainer
            components={['DatePicker', 'DatePicker']}>
                <DatePicker
                sx={{width:'100%',
                marginTop:'-7.5px !important',
                '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                border:'1px solid rgba(0,0,0,0.2)',
                padding:0,
                },
                '.MuiOutlinedInput-input': {
                padding:'10px 10px',
                }
                }}
                size="small"
                readOnly
                format='YYYY-MM'
                //label="စာရင်းစဖွင့်လ"
                value={inputMonth}
                //onChange={(newValue) => setMonth(newValue)}
                />
            </DemoContainer>
            </LocalizationProvider>
            <Select
            size='small'
            sx={{
                height:43.5,
                width:150,
                color: "black",
                '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                border:'1px solid rgba(0,0,0,0.2)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,0.2)',
                },
                '.MuiSvgIcon-root ': {
                fill: "rgba(0,0,0,0.2) !important",
                }
            }}
            readOnly
            labelId="select-user"
            id="select-user"
            style={{marginLeft:3}}
            value={inputPart===undefined?'first':inputPart}
            //label="Day Part"
            //onChange={handleChange}
            >
            <MenuItem value='first' style={{fontFamily:'mmfont'}}>ပထမအကြိမ်</MenuItem>
            <MenuItem value='second' style={{fontFamily:'mmfont'}}>ဒုတိယအကြိမ်</MenuItem>
            </Select>
            </div>
            <h4 style={{padding:15, paddingTop:5}}>{openAll?.length < 1 || openAll===null? 'စာရင်းအသစ်မဖွင့်ရသေးပါ':`${start.format('YYYY-MM-DD')} မှ ${close.format('YYYY-MM-DD')}`}</h4>
            <div className='datebox'>
            <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
                <Button variant="contained" disableElevation
                style={{backgroundColor:'#6798c6'}}
                onClick={() => SetDate()}
                disabled={openAll?.length < 1 || openAll===null }
                >
                    Next
                </Button>
            </FormControl>
            </Box>
            </div>
        </div>
        </div>
    )
}

export default ThreeAgentSelectDateScreen
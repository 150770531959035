import React, { useEffect, useState } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { ModeEditTwoTone } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem, Select } from '@mui/material';

function ThreeMasterScreen() {
  const navigate = useNavigate();
  const {authTokens, handleLogout} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [open, setOpen] = React.useState(false);
  const [defineId, setDefineId] = useState('')

  const handleClickOpen = (id) => {
    setOpen(true);
    setDefineId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateUser, setUpdateUser] = useState(0)
  const [name, setName] = useState('')
  const [pass, setPass] = useState('')
  const [accStatus, setAccStatus] = useState('Open')
  const [userLimit, setUserLimit] = useState('30')
  const [customLimit, setCustomLimit] = useState('')
  var inputDate = moment().tz('Asia/Yangon').format();

  const [users, setUsers] = useState([])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeadminall&type=admin'
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [updateUser])

  const [fetchData, setFetchData] = useState([])
  useEffect(() => {
  let isSubscribed = true;
      if(isSubscribed){
          (async function(){
              try{
                  await axios.all([
                    axios({
                      method:'get',
                      url: mainUrl+'api.php?op=threeuserdetail&username='+name
                    })
                  ]).then(response => {
                      setFetchData(response?.[0]?.['data']['data']['result'])
                  })
              } catch (error) {
                  
              }
          })()
      }
  return () => {isSubscribed = false}
  }, [name])
  const [mess, setMess] = useState('')
  const [existErr, setExistErr] = useState('')
  const handleCreate = async() => {
    const  limit = userLimit==='Custom'?customLimit:userLimit
    let createUrl = mainUrl+'api.php?op=threecreateUser';
    if(fetchData?.length === 0){
        setExistErr('လိုင်းမကောင်းပါ')
        setMess('')
    }else {
        if(fetchData?.length > 0) {
            setExistErr('အခြား Username နှင့် အမည်တူနေပါသည်. ပြန်ပြောင်းပေးပါ')
            setMess('')
        } else {
            if(name!=='' & pass !== '' & limit !== '' & accStatus !== ''){
                await fetch(createUrl, {
                method:'post',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:"username="+name+"&password="+pass+"&type=admin&limit="+limit+"&status="+accStatus+"&date="+inputDate
                }).then(() => {
                setName('')
                setPass('')
                setMess('ဒိုင် အကောင့် ပြုလုပ်ပီးပါပီ!')
                setExistErr('')
                setUpdateUser(updateUser+1)
                })
            } else {
                setExistErr('အချက်အလက် ပြည့်စုံစွာဖြည့်ပေးပါ')
                setMess('')
            }
        }
    }
  }
  const [delMes, setDelMes] = useState('')
  const DeleteUser = async(id) => {
    if(id !== ''){
      let delUserUrl = mainUrl+'api.php?op=threedeleteUser&id='+id
      await axios({
        method: 'get',
        url: delUserUrl
      }).then(() => {
        setDelMes('Agent အကောင့်ဖျက်ပီးပါပီ')
        setUpdateUser(updateUser+1)
        setOpen(false)
      })
    }
  }

  const [edit, setEdit] = useState(false)
  const [upPass, setUpPass] = useState('')
  const [upUserLimit, setUpUserLimit] = useState('')
  const [upCustomLimit, setUpCustomLimit] = useState('')
  const [upAccStatus, setUpAccStatus] = useState('')
  const [upId, setupId] = useState('')
  const handleClickEdit = (id) => {
    var findUser = users?.filter((user) => user?.id === id)
    setupId(findUser?.[0]?.id)
    setName(findUser?.[0]?.username)
    setPass(findUser?.[0]?.password)
    setUpPass(findUser?.[0]?.password)
    setUserLimit(findUser?.[0]?.limit)
    setUpUserLimit(findUser?.[0]?.limit)
    setCustomLimit(findUser?.[0]?.limit)
    setUpCustomLimit(findUser?.[0]?.limit)
    setAccStatus(findUser?.[0]?.status)
    setUpAccStatus(findUser?.[0]?.status)
  }
  
  const checkChange = () => {
    var checkLimit = userLimit === 'Custom'? (upCustomLimit === customLimit) : (userLimit === upUserLimit)
    if(pass === upPass & accStatus === upAccStatus & checkLimit){
      return false
    } else {
      return true
    }
  }
  const handleClear = () => {
    setName('')
    setPass('')
    setUpUserLimit('')
    setAccStatus('')
    setEdit(false)
  }
  const handleUpdate = async() => {
    let createUrl = mainUrl+'api.php?op=threeeditUser';
    const  limit = userLimit==='Custom'?customLimit:userLimit
    if(pass !== ''){
      await fetch(createUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"password="+pass+"&limit="+limit+"&status="+accStatus+'&id='+upId
      }).then(() => {
        setName('')
        setPass('')
        setMess('Agent အကောင့် ပြင်ဆင်ပီးပါပီ!')
        setExistErr('')
        setUpdateUser(updateUser+1)
        setEdit(false)
      })
    } else {
      setExistErr('အချက်အလက် ပြည့်စုံစွာဖြည့်ပေးပါ')
      setMess('')
    }
  }
  
  return (
    <div className='adminScreen'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"ဒိုင် အကောင့်ဖျက်မည်လား?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ဒိုင် နှင့်ပတ်သက်သည့် အချက်အလက်များ ပြန်မရနိုင်ပါ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>မဖျက်ပါ</Button>
          <Button onClick={() => DeleteUser(defineId)} autoFocus>
            ဖျက်မည်
          </Button>
        </DialogActions>
      </Dialog>
      <div className='adminTop'>
        <Button
        onClick={() => navigate('/')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div style={{display:'flex', overflow:'auto', flexWrap: 'nowrap'}}>
        <div style={{padding:10, flex:'0 0 auto', width:250}}>
          <h4>ဒိုင် Account ပြုလုပ်ရန်</h4>
          <p style={{fontSize:15, color:'green'}}>{mess}</p>
          <p style={{fontSize:15, color:'red'}}>{existErr}</p>
          <TextField 
          disabled={edit}
          autoComplete={false}
          size='small'
          id="create-account-agent"
          value={name}
          placeholder='Username'
          onChange={(e) => setName(e.target.value)}
          /><br/>
          <TextField 
          style={{marginTop:5, marginBottom:5}}
          autoComplete={false}
          size='small'
          id="create-password" variant="outlined" 
          value={pass}
          placeholder='Password'
          onChange={(e) => setPass(e.target.value)}
          type="password"
          /><br/>
          <label style={{fontFamily:'textfont', marginLeft:1}}>Account Status</label>
          <Select
          size='small'
          sx={{
            height:40,
            width:210,
            marginTop:'5px',
            marginBottom:'5px',
            color: "black",
            fontFamily:'textfont',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
              border:'1px solid rgba(0,0,0,0.3)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '.MuiSvgIcon-root ': {
              fill: "black !important",
            }
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={accStatus}
          //label="Day Part"
          onChange={(event) => setAccStatus(event.target.value)}
          >
            <MenuItem value='Open'>Open</MenuItem>
            <MenuItem value='Close'>Close</MenuItem>
          </Select><br/>
          <label style={{fontFamily:'textfont', marginLeft:1}}>Agent Limit</label>
          <Select
          size='small'
          sx={{
            marginTop:'5px',
            marginBottom:'5px',
            height:40,
            width:210,
            color: "black",
            fontFamily:'textfont',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
              border:'1px solid rgba(0,0,0,0.3)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '.MuiSvgIcon-root ': {
              fill: "black !important",
            }
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={userLimit}
          //label="Day Part"
          onChange={(event) => setUserLimit(event.target.value)}
          >
            <MenuItem value='10'>10</MenuItem>
            <MenuItem value='30'>30</MenuItem>
            <MenuItem value='50'>50</MenuItem>
            <MenuItem value='100'>100</MenuItem>
            <MenuItem value='150'>150</MenuItem>
            <MenuItem value='Unlimited'>Unlimited</MenuItem>
            <MenuItem value='Custom'>Custom</MenuItem>
          </Select><br/>
          {userLimit === 'Custom'? <TextField 
            style={{marginTop:5, marginBottom:5}}
            autoComplete={false}
            size='small'
            type='number'
            id="create-password" variant="outlined" 
            value={customLimit}
            placeholder='Custom'
            onChange={(e) => setCustomLimit(e.target.value)}
            />: null}<br/>
          <Button variant="contained" disableElevation
          style={{backgroundColor:'#6798c6', textTransform:'capitalize'}}
          onClick={!checkChange()? () => handleClear() : edit? () => handleUpdate():() => handleCreate()}
          >
              {!checkChange()?'Clear': edit?'Update':'Create'}
          </Button>
        </div>
        <div style={{padding:10, flex:'0 0 auto'}}>
          <h4>ဒိုင် အကောင့်များ</h4>
          <p style={{fontSize:15, color:'green'}}>{delMes}</p>
          <table>
            <tbody>
              <tr style={{backgroundColor:'#6798c6', color:'white'}}>
              <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>SrN</td>
                <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Username</td>
                <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Password</td>
                <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Agent Limit</td>
                <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Status</td>
                <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Edit</td>
                <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>Del</td>
              </tr>
              {
                users?.map((user, index) => 
                <tr>
                  <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                    {index+1}
                  </td>
                  <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                    {user?.username}
                  </td>
                  <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                    {user?.password}
                  </td>
                  <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                    {user?.limit}
                  </td>
                  <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                    {user?.status}
                  </td>
                  <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}>
                    <ModeEditTwoTone 
                    onClick={() => {
                      handleClickEdit(user?.id);
                      setEdit(true)
                    }}
                    size={17}/>
                  </td>
                  <td style={{margin:3, border:'1px solid #6798c6', padding:'0px 8px', textAlign:'center'}}
                  onClick={() => handleClickOpen(user?.id)}
                  >
                    <DeleteTwoToneIcon />
                  </td>
                </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ThreeMasterScreen

import React, {useState, useEffect, useMemo} from 'react'
import './HistoryScreen.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { mainUrl } from './url';
import { useAuth } from '../Auth';
import axios from 'axios';

function ThreeDetailScreen() {
    const location = useLocation()
    const navigate = useNavigate();
    const [history, setHistory] = useState(location?.state?.history === undefined?[]:location?.state?.history)
    const {userType, threeDate} = useAuth()

    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+userType?.id+'&adminid='+userType?.adminid+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                    ]).then(response => {
                        setHistory(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [userType?.id, userType?.adminid, threeDate?.dateFrom, threeDate?.dateTo])

    const numarray = Array(1000).fill(0).map((n, i) => { 
        if((n + i) < 10){
            return '00'+String(n+i)
        }else if((n + i) >= 10 && (n + i) < 100) {
            return '0'+String(n+i)
        } 
        else {
            return String(n + i)
        }
    })

    const defineSplitlens = (data) => {
        if(data === ''){
          return 0
        } else {
          if(data?.includes('R')){
            return 4
          } else if(data?.includes('A') ||
          data?.includes('H')
          ) {
            return 1
          } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
            return 2
          } 
          else {
            return 3
          }
        }
    }
    
    const mixingDigit = (digit, first, second) => {
    var arr = digit?.split('');
    const makeDigit = [
        arr[0]+''+arr[1]+''+arr[2],
        arr[0]+''+arr[2]+''+arr[1],
        arr[1]+''+arr[0]+''+arr[2],
        arr[1]+''+arr[2]+''+arr[0],
        arr[2]+''+arr[0]+''+arr[1],
        arr[2]+''+arr[1]+''+arr[0],
    ]
    const digitArr = [...new Set(makeDigit)];
    const outputArray = digitArr.map((item, index) => {
        if(index === 0){
        return ({ 'digit': item, 'amount': first })
        } else {
        return ({ 'digit':item, 'amount': second })
        }
    });
    return outputArray
    }
    
    const DefineDigit = (digit, result, numarray, amount, first, second) => {
    if(result?.includes('R') && !result?.includes('E')){
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var firstFirst = firstSplit?.slice(0,3)
        return mixingDigit(firstFirst, first, second)
    } else if(digit.includes('A')){
        return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('H')){
        return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('F')){
        var newFDigit = digit?.replace('F','')
        var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
        return digitFFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('M')){
        var newMDigit = digit?.replace('M','')
        var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
        return digitMFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('L')){
        var newLDigit = digit?.replace('L','')
        var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
        return digitLFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(result?.includes('S')){
        var splitSDigit = result?.split('S')
        var amountS = splitSDigit?.[0]?.length === 0? 
        splitSDigit?.[1]?.slice(2) :
        splitSDigit?.[0]?.length === 1 ?
        splitSDigit?.[1]?.slice(1) :
        splitSDigit?.[1]
        var firstDigit = ''
        var secondDigit = ''
        var thirdDigit = ''
        var sDigitArr = []
        if(splitSDigit?.[0]?.length === 0){
            secondDigit = splitSDigit?.[1]?.[0]
            thirdDigit = splitSDigit?.[1]?.[1]
            sDigitArr = ['0'+secondDigit+thirdDigit, 
            '1'+secondDigit+thirdDigit,
            '2'+secondDigit+thirdDigit,
            '3'+secondDigit+thirdDigit,
            '4'+secondDigit+thirdDigit,
            '5'+secondDigit+thirdDigit,
            '6'+secondDigit+thirdDigit,
            '7'+secondDigit+thirdDigit,
            '8'+secondDigit+thirdDigit,
            '9'+secondDigit+thirdDigit,
            ]
        }else if(splitSDigit?.[0]?.length === 1){
            firstDigit = splitSDigit?.[0]
            thirdDigit = splitSDigit?.[1]?.[0]
            sDigitArr = [firstDigit+'0'+thirdDigit, 
            firstDigit+'1'+thirdDigit,
            firstDigit+'2'+thirdDigit,
            firstDigit+'3'+thirdDigit,
            firstDigit+'4'+thirdDigit,
            firstDigit+'5'+thirdDigit,
            firstDigit+'6'+thirdDigit,
            firstDigit+'7'+thirdDigit,
            firstDigit+'8'+thirdDigit,
            firstDigit+'9'+thirdDigit,
            ]
        }else {
            firstDigit = splitSDigit?.[0]?.[0]
            secondDigit = splitSDigit?.[0]?.[1]
            sDigitArr = [firstDigit+secondDigit+'0', 
            firstDigit+secondDigit+'1',
            firstDigit+secondDigit+'2',
            firstDigit+secondDigit+'3',
            firstDigit+secondDigit+'4',
            firstDigit+secondDigit+'5',
            firstDigit+secondDigit+'6',
            firstDigit+secondDigit+'7',
            firstDigit+secondDigit+'8',
            firstDigit+secondDigit+'9',
            ]
        }
        return sDigitArr.map(item => ({'digit':item,'amount':amountS}))
    } else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
            newEDigit?.forEach((digit) => {
            modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
            })
        } else {
            newEDigit?.forEach((digit) => {
            modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount}))
    }
    return [{'digit':digit, 'amount':amount}]
    }

    const useFilteredDetail = (detailAll) => {
        return useMemo(() => {
            if (!detailAll || detailAll.length === 0) return [];
        
            return detailAll.filter((his) => {
            return his?.buy === '1';
            });
        }, [detailAll]);
    };

    const newHistory = useFilteredDetail(history);

    const MakeDigitArr = () => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
        digits?.push(digitArr)
    })
    return digits?.flat()
    }

    const newDetail = MakeDigitArr()
    
    var details = Array.from(newDetail?.reduce(
        (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
      ), ([digit, amount]) => ({digit, amount}))
      
    return (
        <div
        style={{display:'flex', 
        justifyContent:'center', paddingBottom:10,
        alignItems:'start'}}
        >
        <div className='historyScreen'>
            <div className='historytitle'>
                <Button className='historylink' onClick= {() => navigate('/home')}>
                    <ArrowBackIosIcon color='black' 
                    style={{color:'black', 
                    }}/>
                </Button>
                <p style={{fontFamily:'mmfont'}}>အကွက်ဇယား</p>
            </div>
            <div className='tablehead'>
                <p style={{width:"20%", lineHeight:2.2}} className='tablebox'>SN</p>
                <p style={{width:'30%', lineHeight:2.2}} className='tablebox'>Digit</p>
                <p style={{width:'50%', lineHeight:2.2}} className='tablebox'>Amount</p>
                {/*<p style={{width:50}} className='tablebox'>Del</p>*/}
            </div>
            {
                details?.sort((a, b) => parseInt(a.digit) > parseInt(b.digit) ? 1 : -1)?.map((his, index) => (
                    <div className='tabledata'>
                        <p style={{width:"20%"}} className='tablebox'>{index+1}</p>
                        <p style={{width:'30%', backgroundColor:'rgba(117, 172, 212, 0.3)'}} className='tablebox'>{his?.digit}</p>
                        <p style={{width:'50%'}} className='tablebox'>{his?.amount}</p>
                        {/*<div className='deltablebox'>
                            <DeleteTwoToneIcon />
                        </div>*/}
                    </div>
                ))
            }
            <div className='tabledata'>
                <p style={{width:'50%', backgroundColor:'#6798c6', color:'white'}} className='tablebox'>Total</p>
                <p style={{width:'50%'}} className='tablebox'>{details?.reduce((n, {amount}) => n + parseInt(amount), 0)}</p>
            </div>
        </div>
        </div>
    )
}

export default ThreeDetailScreen
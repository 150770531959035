import React, { useEffect, useState } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Auth';
import { Box } from '@mui/material';

function ThreeSearchInput() {
  let wonAudio = new Audio('./songs/win.wav')
  let loseAudio = new Audio('./songs/lose.wav')
  const { state } = useLocation();
  const navigate = useNavigate();
  const {authTokens, userType} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const numarray = Array(1000).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '00'+String(n+i)
    }else if((n + i) >= 10 && (n + i) < 100) {
      return '0'+String(n+i)
    } 
    else {
      return String(n + i)
    }
  })

  const [updateUser, setUpdateUser] = useState(0)
  

  const deleteHistory = async(id) => {
        if(id !== ''){
            let delUrl = mainUrl+'api.php?op=threedeleteHistory&id='+id
            await axios({
            method: 'get',
            url: delUrl
            }).then(async() => {
                setUpdateUser(updateUser+1)
            })
        }
    }
  const [takeLoad, setTakeLoad] = useState(0)
    
    const [result, setResult] = useState('')
    const [errorMes, setErrorMes] = useState(state?.['message'])
    const [success, setSuccess] = useState(state?.success)

    const defineSplitlens = (data) => {
      if(data === ''){
        return 0
      } else {
        if(data?.includes('R')){
          return 4
        } else if(data?.includes('A') ||
        data?.includes('H')
        ) {
          return 1
        } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
          return 2
        } 
        else {
          return 3
        }
      }
    }
  
    var splitLen = defineSplitlens(result);

    const mixingDigit = (digit, first, second) => {
      var arr = digit?.split('');
      const makeDigit = [
        arr[0]+''+arr[1]+''+arr[2],
        arr[0]+''+arr[2]+''+arr[1],
        arr[1]+''+arr[0]+''+arr[2],
        arr[1]+''+arr[2]+''+arr[0],
        arr[2]+''+arr[0]+''+arr[1],
        arr[2]+''+arr[1]+''+arr[0],
      ]
      const digitArr = [...new Set(makeDigit)];
      const outputArray = digitArr.map((item, index) => {
        if(index === 0){
          return ({ 'digit': item, 'amount': first })
        } else {
          return ({ 'digit':item, 'amount': second })
        }
      });
      return outputArray
    }
    
    const DefineDigit = (digit, result, numarray, amount, first, second) => {
      if(result?.includes('R') && !result?.includes('E')){
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var firstFirst = firstSplit?.slice(0,3)
        return mixingDigit(firstFirst, first, second)
      } else if(digit.includes('A')){
        return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount}))
      } else if(digit.includes('H')){
        return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount}))
      } else if(digit.includes('F')){
        var newFDigit = digit?.replace('F','')
        var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
        return digitFFirst.map(item => ({'digit':item,'amount':amount}))
      } else if(digit.includes('M')){
        var newMDigit = digit?.replace('M','')
        var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
        return digitMFirst.map(item => ({'digit':item,'amount':amount}))
      } else if(digit.includes('L')){
        var newLDigit = digit?.replace('L','')
        var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
        return digitLFirst.map(item => ({'digit':item,'amount':amount}))
      } else if(result?.includes('S')){
        var splitSDigit = result?.split('S')
        var amountS = splitSDigit?.[0]?.length === 0? 
        splitSDigit?.[1]?.slice(2) :
        splitSDigit?.[0]?.length === 1 ?
        splitSDigit?.[1]?.slice(1) :
        splitSDigit?.[1]
        var firstDigit = ''
        var secondDigit = ''
        var thirdDigit = ''
        var sDigitArr = []
        if(splitSDigit?.[0]?.length === 0){
          secondDigit = splitSDigit?.[1]?.[0]
          thirdDigit = splitSDigit?.[1]?.[1]
          sDigitArr = ['0'+secondDigit+thirdDigit, 
            '1'+secondDigit+thirdDigit,
            '2'+secondDigit+thirdDigit,
            '3'+secondDigit+thirdDigit,
            '4'+secondDigit+thirdDigit,
            '5'+secondDigit+thirdDigit,
            '6'+secondDigit+thirdDigit,
            '7'+secondDigit+thirdDigit,
            '8'+secondDigit+thirdDigit,
            '9'+secondDigit+thirdDigit,
          ]
        }else if(splitSDigit?.[0]?.length === 1){
          firstDigit = splitSDigit?.[0]
          thirdDigit = splitSDigit?.[1]?.[0]
          sDigitArr = [firstDigit+'0'+thirdDigit, 
            firstDigit+'1'+thirdDigit,
            firstDigit+'2'+thirdDigit,
            firstDigit+'3'+thirdDigit,
            firstDigit+'4'+thirdDigit,
            firstDigit+'5'+thirdDigit,
            firstDigit+'6'+thirdDigit,
            firstDigit+'7'+thirdDigit,
            firstDigit+'8'+thirdDigit,
            firstDigit+'9'+thirdDigit,
          ]
        }else {
          firstDigit = splitSDigit?.[0]?.[0]
          secondDigit = splitSDigit?.[0]?.[1]
          sDigitArr = [firstDigit+secondDigit+'0', 
            firstDigit+secondDigit+'1',
            firstDigit+secondDigit+'2',
            firstDigit+secondDigit+'3',
            firstDigit+secondDigit+'4',
            firstDigit+secondDigit+'5',
            firstDigit+secondDigit+'6',
            firstDigit+secondDigit+'7',
            firstDigit+secondDigit+'8',
            firstDigit+secondDigit+'9',
          ]
        }
        return sDigitArr.map(item => ({'digit':item,'amount':amountS}))
      } else if(result?.includes('E')){
        var newEDigit = result?.split('E')?.[1]?.split(',')
        var amountE = result?.split('E')?.[2]
        var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
        var modEArr = []
        if(amountE?.includes('R')){
          newEDigit?.forEach((digit) => {
            modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
          })
        } else {
          newEDigit?.forEach((digit) => {
            modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
          })
        }
        return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount}))
      }
      return [{'digit':digit, 'amount':amount}]
    }

    useEffect(() => {
      var spl = result?.split('R')
      if(spl?.length > 2){
        reset()
      }else if(result?.includes('H') && result?.includes('R')) {
        reset()
      }else if(result?.includes('A') && result?.includes('R')) {
        reset()
      }else if(result?.includes('L') && result?.includes('R')) {
        reset()
      }else if(result?.includes('F') && result?.includes('R')) {
        reset()
      }else if(result?.includes('M') && result?.includes('R')) {
        reset()
      }else if(result?.includes('R') && result?.length < 4){
        reset()
      }else if(result?.includes('R')){
        if(result?.[0] === result?.[1] 
          & result?.[0] === result?.[2]
        ){
          reset()
        }
      }else if(result?.includes('S')){
        if((result?.indexOf('S') === 0 || result?.indexOf('S') === 1 || result?.indexOf('S') === 2) && (result?.match(/S/g) || []).length === 1) {
          //continue
        }else {
          reset()
        }
      }
    },[result, splitLen])
    const onClick = (button) => {
      if(button === "Save") {
        SaveDigit();
      }
      else if(button === "C") {
        reset();
      }
      else {
        if(button === 'A' ||
        button === 'H'
        ) {
          setResult(button)
        } else if (button === "F" ||
          button === "L" ||
          button === "M"
        ) {
          if(result?.length > 0) {
            if(result?.[0] === '0' ||
            result?.[0] === '1' ||
            result?.[0] === '2' ||
            result?.[0] === '3' ||
            result?.[0] === '4' ||
            result?.[0] === '5' ||
            result?.[0] === '6' ||
            result?.[0] === '7' ||
            result?.[0] === '8' ||
            result?.[0] === '9'
            ) {
              setResult(result?.[0]+button)
            } else {
              reset()
            }
          } else {
            reset()
          }
        } else if(button === 'R') {
          setResult(result+button)
        }
        else {
          setResult(result+button)
        }
      }
    }
    const reset = () => {
      setResult('')
    }

    const SaveDigit = async() => {
      if(state?.id === '' || state?.id === undefined){
        loseAudio.play()
        setErrorMes('ပြန်ပြင်မည့် ထိုးဂဏန်းကို ရွေးရန်')
        setSuccess(false)
      }else {
        if(state?.username !==''){
          let messageUrl = mainUrl+'api.php?op=threeupdateDigit';
          setErrorMes('')
          deleteHistory(state?.id).then(async() => {
            await fetch(messageUrl, {
                method:'post',
                headers:{
                'Content-Type':'application/x-www-form-urlencoded'
                },
                body:"result="+result+"&userid="+state?.username+"&date="+state?.date+"&buy=1&adminid="+userType?.id+'&id='+state?.id
            })
          }).then(async() => {
            wonAudio.play()
            setResult('')
            setTakeLoad(takeLoad+1)
            setErrorMes(state?.message+' -> '+ result+' ပြင်ပြီး!')
            setSuccess(true)
          })
        } else {
          setErrorMes('Agent အရင်ရွေးပါ')
          setSuccess(false)
          loseAudio.play()
        }
      }
    }

    //var digitStatus = DigitStatus()
    var digitStatus = 'Open'

    var newDigit = result?.split('R')
    var firstSplit = newDigit?.[0]
    const digitArrForResult = DefineDigit(result?.slice(0,splitLen), result, numarray, result?.slice(defineSplitlens(result)), result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');

    // edit screen 

    return (
        <div className='adminScreen'>
        
        <div className='adminTop'>
            <Button
            onClick={() => navigate('/search')} 
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Back
            </Button>
        </div>
        
        <div style={{display:'flex', overflow:'auto'}}>
            <div style={{padding:10, flex:'0 0 auto', width:410}}>
            
            {/** edit digit screen */}
          
            <div className='adminHomeScreen' style={{marginLeft:0}}>
            
            <div style={{padding:'3px 10px 0px 10px', display:'flex'}}>
              <p>Result: </p> <p style={{color: success?'#1bbc12':'#d8005d', lineHeight:'20px'}}>&nbsp; {errorMes}</p>
            </div>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: "95%"},
              }}
              className='White'
              style={{textAlign:'center'}}
              noValidate
              autoComplete="off"
            >
            <div>
              <TextField
                size='small'
                disabled
                value={result}
                id="outlined-disabled"
                placeholder='Press any digit'
                sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                        "& > fieldset": {
                            borderColor: "rgba(0,0,0,0.1)",
                            WebkitTextFillColor: "#000000",
                        },
                        WebkitTextFillColor: "#000000",
                        fontSize:27
                    }
                }}
                InputProps={{
                  sx: {
                    "&.MuiInputBase-root.Mui-disabled": { color: "unset" },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "unset",
                    },
                  },
                  autoComplete: 'off'
                }}
              />
            </div>
            </Box>
            <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value="C"
            >
            ဖျက်မည်
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "A"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
            Tri
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "R"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              R
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "F"
            disabled={digitStatus==="Closed"}
            >
            ထိပ်
            </Button>
          </div>
          <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "7"
            disabled={digitStatus==="Closed"}
            >
            7
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "8"
            disabled={digitStatus==="Closed"}
            >
            8
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "9"
            disabled={digitStatus==="Closed"}
            >
            9
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "M"
            disabled={digitStatus==="Closed"}
            >
            လယ်
            </Button>
          </div>
          <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "4"
            disabled={digitStatus==="Closed"}
            >
            4
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "5"
            disabled={digitStatus==="Closed"}
            >
            5
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "6"
            disabled={digitStatus==="Closed"}
            >
            6
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "L"
            disabled={digitStatus==="Closed"}
            >
            နောက်
            </Button>
          </div>
          <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "1"
            disabled={digitStatus==="Closed"}
            >
            1
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'203%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "0"
            disabled={digitStatus==="Closed"}
            >
            0
            </Button>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            style={{width:'100%'}}
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "2"
            disabled={digitStatus==="Closed"}
            >
            2
            </Button>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "3"
            disabled={digitStatus==="Closed"}
            >
            3
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            style={{width:'100%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "00"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              00
            </Button>
            </div>
            <Button
            variant="contained" disableElevation
            className='buttonBox fourthBox'
            disableRipple
            style={{backgroundColor:'#6798c6 !important'}}
            onClick={(e) => onClick(e.target.value)}
            value = "Save"
            disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
            >
            သိမ်းမည်
            </Button>
          </div>
          </div>
            
          </div>
        </div>
        </div>
    )
}

export default ThreeSearchInput
